/* skin#1 */
.skin-1 {
 background-color: #4A4F56;
}
.skin-1 .navbar  {
 background:#2C6AA0;
}

.skin-1  .sidebar , .skin-1  .sidebar:before {
 background-color:#222A2D;
 border-right:none;
}



.skin-1  .nav-list > li {
 border-color:#3F4E54; 
 border-top-width:0;
}
.skin-1 .nav-list > li > a  , .skin-1 .menu-min .nav-list > li.open > a{
 background-color:#222A2D;
 color:#B1BAC1;
}
.skin-1 .nav-list > li:hover > a {
 background-color:#414B51;
 color:#E1EAF1;
}
.skin-1 .nav-list > li > a > .arrow  {
  color:#B1BAC1;
}
.skin-1 .nav-list > li.open > a > .arrow {
  color:#85C0EC;
}


.skin-1 .nav-list > li .submenu > li > a {
	border-top-color:#AFBDC9;
}
.skin-1 .nav-list > li.active .submenu > li > a {
	border-top-color:#70818E;
}

.skin-1 .nav-list  li.active > a:after {
 border-right-color:#FFF;
 border-width:16px 10px;
 top:2px;
}


.skin-1  .nav-list > li.active.open > ul.submenu > li.active > a:after {
 top:1px;
}
.skin-1 .nav-list > li.active:after {
 display:none;
}
.skin-1 .menu-min .nav-list > li.active > a:after {
 border-width:10px 6px;
 top:8px;
}
.skin-1 .menu-min .nav-list > li.active:hover > a:after {
 border-right-color:#242A2B;
}


.skin-1 .nav-list > li.open > a , .skin-1 .nav-list > li.open > a:hover
{
 color:#85C0EC;
 background-color:#222A2D;
}
.skin-1 .nav-list > li.active > a , .skin-1 .nav-list > li.active > a:hover,
.skin-1 .menu-min .nav-list > li.active > a , .skin-1 .menu-min .nav-list > li.active > a:hover
 {
 background-color:#141A1B;
 color:#55A0DC;
}


.skin-1 .nav-list > li > ul.submenu {
 background-color:#3A4344;
 border-top-color:#5A6364;
}
.skin-1 .nav-list > li.active > ul.submenu {
 background-color:#141A1B;
 border-top-color:#2F3E44;
}

.skin-1 .nav-list > li > .submenu > li > a {
 color:#D1DAE1;
 border-bottom-color:#5A6364;
}
.skin-1 .nav-list > li > .submenu > li:first-child > a {
 border-top-color:transparent;
}
.skin-1 .nav-list > li > .submenu > li > a:hover {
 color:#8AB4DE;
}
.skin-1 .nav-list > li > .submenu > li.active > a {
 color:#55A0DC;
 border-bottom-color:#2F3E44;
}



.skin-1 .nav-list > li > .submenu > li > a > [class*="icon-"]:first-child {
 display:none;
}

.skin-1 .menu-min .nav-list > li:hover > a{
 color:#E1EAF1;
}
.skin-1 .menu-min .nav-list > li > a > .menu-text{
 background-color:#414B51;
}
.skin-1 .menu-min .nav-list > li.active > a > .menu-text{
 background-color:#242A2B;
}

.skin-1 .menu-min .nav-list > li.active:hover > a,
.skin-1 .menu-min .nav-list > li.active > a > .menu-text{
 color:#55A0DC;
}
.skin-1 .menu-min .nav-list > li > a.active ,
.skin-1 .menu-min .nav-list > li.open.active > a
{
 background-color:#141A1B;
}

.skin-1 .menu-min .nav-list > li > a > .menu-text,
.skin-1 .menu-min .nav-list > li > ul.submenu {
 border-color:#3F4E54;
 margin-top:1px;
 border-left-color:#242A2B;
}

.skin-1 .nav-list > li > .submenu > li:before, .skin-1 .nav-list > li > .submenu:before {
 border-color:#929BA3;
}
.skin-1 .nav-list > li.active > .submenu > li:before, .skin-1 .nav-list > li.active > .submenu:before {
 border-color:#3F4E54;
}
.skin-1 .menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
 border-bottom-color:#5A606A;
}


.skin-1 .sidebar-collapse {
 background-color:#141A1B;
 border-color:#3F4E54; 
 border-top-width:1px;
}
.skin-1 .sidebar-collapse:before {
 border-color:#3F4E54;
}
.skin-1 .sidebar-collapse > [class*="icon-"] {
 background-color:#222A2D;
}


.skin-1 .sidebar-shortcuts , .skin-1 .sidebar-shortcuts-mini {
 background-color:#141A1B;
 border-color:#3F4E54;
}
.skin-1 .sidebar > .nav-search {
 background-color:#141A1B;
 border-color:#3F4E54;
}

.skin-1  .menu-min .sidebar-shortcuts-large {
 background-color:#141A1B;
 box-shadow:none;
 border:none;
 top:0;
}

.skin-1 .breadcrumbs {
 border:none;
 background-color:#F0F0F0;
}



@media only screen and (max-width: 991px) {
.skin-1  .sidebar {
 border:none;
 box-shadow:none;
}
.skin-1 .nav-list li.active > a:after {
 display:none;
}
.skin-1 .menu-min .nav-list > li.active.open > a:after {
 display:none;
}
.skin-1 .menu-min .nav-list > li.active:hover > a:after {
 display:block;
}
}


/* 3rd & 4th level menu */
.skin-1 .nav-list > li  > .submenu > li > .submenu  a {
	border-top-color:#7B858C;
}
.skin-1 .nav-list > li.active  > .submenu > li > .submenu  a {
	border-top-color:#545B60;
}

.skin-1 .nav-list > li .submenu li > .submenu > li a {
 color: #D1DAE1;
 font-size: 13px;
}
.skin-1 .nav-list > li .submenu li > .submenu > li a > [class*="icon-"]:first-child {
 color: inherit;
}
.skin-1 .nav-list > li .submenu li > .submenu > li a:hover {
  color: #8AB4DE;
  text-decoration:none;
}
.skin-1 .nav-list > li .submenu li > .submenu > li a:hover > [class*="icon-"]:first-child  {
  color: #55A0DC;
}


.skin-1 .nav-list > li .submenu li.open > a  ,
.skin-1 .nav-list > li .submenu li > .submenu > li.open > a
{
 color: #85C0EC;
}
.skin-1 .nav-list > li .submenu li.open > a > [class*="icon-"]:first-child ,
.skin-1 .nav-list > li .submenu li > .submenu > li.open > a > [class*="icon-"]:first-child
{
 color: inherit;
}


.skin-1 .nav-list > li .submenu li > .submenu li.active > a {
  color: #61A8DD;
}
.skin-1 .nav-list > li .submenu li > .submenu li.active > a > [class*="icon-"]:first-child{
  color: inherit;
}
.skin-1 .nav-list > li .submenu li > .submenu li.active > a:hover {
  color: #85C0EC;
}


.skin-1 .nav-list > li .submenu li > a > .arrow {
  color: #BBB;
}
.skin-1 .nav-list > li > .submenu > li.open > a > .arrow,
.skin-1 .nav-list > li > .submenu > li > a:hover > .arrow,
.skin-1 .nav-list > li > .submenu > li > .submenu > li.open > a > .arrow,
.skin-1 .nav-list > li > .submenu > li > .submenu > li > a:hover > .arrow
{
	color:inherit;
}













/* skin#2 */
.skin-2 {
 background-color: #5C5C5C;
}
.skin-2 .navbar {
 background:#C6487E;
}
.skin-2  .sidebar , .skin-2  .sidebar:before {
 background-color:#505050;
 border-right:none;
}


.skin-2  .nav-list > li {
 border-color:#444; 
 border-top-width:0;
}
.skin-2  .nav-list > li:last-child {
 border-bottom-width:0;
}

.skin-2 .nav-list > li > a , .skin-2 .menu-min .nav-list > li.open > a,
.skin-2 .nav-list .open > a, .skin-2 .nav-list .open > a:hover, .skin-2 .nav-list .open > a:focus
 {
 background-color:#393939;
}
.skin-2 .nav-list > li.active > a {
 color:#FFF;
}
.skin-2  .nav-list > li > a:hover:before{
 display:none;
}



.skin-2 .nav-list  li.active > a:after {
 border-right-color:#FFF;
}

.skin-2 .nav-list  > li.active > a:after , .skin-2 .nav-list  > li.active > .submenu > li.active > a:after{
 top:2px;
 right:0;
 border-width:16px 10px;
}


.skin-2 .menu-min .nav-list > li.active > a:after {
 border-width:10px 6px;
 top:8px;
}

.skin-2 .menu-min .nav-list > li.active:hover > a:after {
 border-right-color:#292929;
}


.skin-2 .nav-list > li.active:after {
 display:none;
}
.skin-2 .nav-list > li.active.open > .submenu > li.active > a:after {
 border-right-color:#FFF;
 right:0;
}



.skin-2 .nav-list > li > .submenu {
 background-color:#303030;
 border-top-color:#666;
}
.skin-2 .nav-list > li.active > .submenu {
 background-color:#222;
}
.skin-2 .nav-list > li > .submenu > li:first-child > a {
 border-top:none;
}
.skin-2 .nav-list > li > .submenu > li > a {
 color:#CCC;
 border-bottom-color:#555;
}
.skin-2 .nav-list > li > .submenu > li > a > [class*="icon-"]:first-child {
 display:none;
}



.skin-2  .nav-list > li > a > .menu-text {
 color:#DDD;
}
.skin-2  .nav-list > li > a:hover > .menu-text {
 color:rgba(0,0,0,0.5);
 font-weight:bold;
}
.skin-2  .nav-list > li.active > a:hover > .menu-text {
 color:#FFF;
}



.skin-2  .nav-list > li > a  > .arrow
{ color:#AAA;}
.skin-2  .nav-list > li > a:hover > .arrow
{ color:#FFF;}



.skin-2  .menu-min .nav-list > li > a > .menu-text {
 color:#FFF;
 font-weight:bold;
 opacity:1;
 filter:alpha(opacity=100);
 background-color:#292929;
 border:none;
 box-shadow:none;
 text-shadow:1px 1px 0 rgba(0,0,0,0.5);
 
 margin-top:2px;
}
.skin-2  .menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
 margin-top:0;
 background-color:#292929;
}

.skin-2  .menu-min .nav-list > li > ul.submenu {
 box-shadow:none;
 border:none;
 background-color:#303030;
 
 margin-left:1px;
 margin-top:1px;
 border-left:1px solid #FFF;
}

.skin-2  .nav-list > li > ul.submenu > li > a:hover{
 color:#FFF;
}
.skin-2  .nav-list > li > ul.submenu > li:before , .skin-2  .nav-list > li > ul.submenu > li:after{/* hide tree like menu */
 display:none;
}
.skin-2  .nav-list > li > ul.submenu > li > a {
 padding-left:28px;
}



/* default */
.skin-2  .nav-list > li > a,
.skin-2  .menu-min .nav-list > li > a > .menu-text
{
 color:#CCC;
}

.skin-2  .nav-list > li:nth-child(1) > a,
.skin-2  .menu-min .nav-list > li:nth-child(1) > a > .menu-text
{
 color:#00BE67;
}
.skin-2  .nav-list > li:nth-child(2) > a,
.skin-2  .menu-min .nav-list > li:nth-child(2) > a > .menu-text
{
 color:#5ED02B;
}
.skin-2  .nav-list > li:nth-child(3) > a,
.skin-2  .menu-min .nav-list > li:nth-child(3) > a > .menu-text
{
 color:#A7D700;
}
.skin-2  .nav-list > li:nth-child(4) > a,
.skin-2  .menu-min .nav-list > li:nth-child(4) > a > .menu-text
{
 color:#CEE100;
}
.skin-2  .nav-list > li:nth-child(5) > a,
.skin-2  .menu-min .nav-list > li:nth-child(5) > a > .menu-text
{
 color:#F8C600;
}
.skin-2  .nav-list > li:nth-child(6) > a,
.skin-2  .menu-min .nav-list > li:nth-child(6) > a > .menu-text
{
 color:#FFAB00;
}
.skin-2  .nav-list > li:nth-child(7) > a,
.skin-2  .menu-min .nav-list > li:nth-child(7) > a > .menu-text
{
 color:#FF6E00;
}
.skin-2  .nav-list > li:nth-child(8) > a,
.skin-2  .menu-min .nav-list > li:nth-child(8) > a > .menu-text
{
 color:#F21C30;
}
.skin-2  .nav-list > li:nth-child(9) > a,
.skin-2  .menu-min .nav-list > li:nth-child(9) > a > .menu-text
{
 color:#EE218B;
}
.skin-2  .nav-list > li:nth-child(10) > a,
.skin-2  .menu-min .nav-list > li:nth-child(10) > a > .menu-text
{
 color:#C55EE7;
}
.skin-2  .nav-list > li:nth-child(11) > a,
.skin-2  .menu-min .nav-list > li:nth-child(11) > a > .menu-text
{
 color:#218BEE;
}
.skin-2  .nav-list > li:nth-child(12) > a,
.skin-2  .menu-min .nav-list > li:nth-child(12) > a > .menu-text
{
 color:#2ECEE7;
}


.skin-2 .menu-min .nav-list > li.active > a > [class*="icon-"],
.skin-2 .nav-list > li:not(.active):hover > a > [class*="icon-"] {
 color:#FFF;
}



.skin-2  .nav-list > li.active > a > .menu-text
{
 color:#FFF;
}

.skin-2 .nav-list > li.active > ul.submenu {
 background-color:#222;
}
.skin-2 .nav-list > li > ul.submenu:before {
 display:block; content:"";
 position:absolute; z-index:auto;
 left:0; top:0; right:0; bottom:0;
 background-color:#393939;
 opacity:0.12;
}

.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .badge:not(.badge-transparent) ,
.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .label:not(.label-transparent) {
 background-color:rgba(0,0,0,0.5) !important;
}
.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .badge.badge-transparent ,
.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .label.label-transparent{
  color:rgba(255,255,255,0.75) !important;
}
.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .badge.badge-transparent [class*="icon-"],
.skin-2 .sidebar:not(.menu-min) .nav-list > li:not(.active) > a:hover .label.label-transparent [class*="icon-"]{
  color:rgba(255,255,255,0.75) !important;
}


/*default*/
.skin-2  .nav-list > li > a:hover,
.skin-2  .menu-min .nav-list > li:hover > a,
.skin-2  .menu-min .nav-list > li.active > a
{
 background-color:#C6487E;
}
.skin-2  .nav-list > li > ul.submenu:before {
 background-color:#35262D;
}

.skin-2  .nav-list > li:nth-child(1) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(1):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(1) > a,
.skin-2  .nav-list > li:nth-child(1) > ul.submenu:before
{
 background-color:#00BE67;
}
.skin-2  .nav-list > li:nth-child(2) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(2):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(2) > a,
.skin-2  .nav-list > li:nth-child(2) > ul.submenu:before
{
 background-color:#5ED02B;
}
.skin-2  .nav-list > li:nth-child(3) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(3):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(3) > a,
.skin-2  .nav-list > li:nth-child(3) > ul.submenu:before
{
 background-color:#A7D700;
}
.skin-2  .nav-list > li:nth-child(4) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(4):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(4) > a,
.skin-2  .nav-list > li:nth-child(4) > ul.submenu:before
{
 background-color:#CEE100;
}
.skin-2  .nav-list > li:nth-child(5) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(5):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(5) > a,
.skin-2  .nav-list > li:nth-child(5) > ul.submenu:before
{
 background-color:#F8C600;
}
.skin-2  .nav-list > li:nth-child(6) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(6):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(6) > a,
.skin-2  .nav-list > li:nth-child(6) > ul.submenu:before
{
 background-color:#FFAB00;
}
.skin-2  .nav-list > li:nth-child(7) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(7):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(7) > a,
.skin-2  .nav-list > li:nth-child(7) > ul.submenu:before
{
 background-color:#FF6E00;
}
.skin-2  .nav-list > li:nth-child(8) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(8):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(8) > a,
.skin-2  .nav-list > li:nth-child(8) > ul.submenu:before
{
 background-color:#F21C30;
}
.skin-2  .nav-list > li:nth-child(9) > a:hover,
.skin-2  .menu-min .nav-listli:nth-child(9):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(9) > a,
.skin-2  .nav-list > li:nth-child(9) > ul.submenu:before
{
 background-color:#EE218B;
}
.skin-2  .nav-list > li:nth-child(10) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(10):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(10) > a,
.skin-2  .nav-list > li:nth-child(10) > ul.submenu:before
{
 background-color:#C55EE7;
}
.skin-2  .nav-list > li:nth-child(11) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(11):hover > a,
.skin-2  .menu-min .nav-list li.active:nth-child(11) > a,
.skin-2  .nav-list > li:nth-child(11) > ul.submenu:before
{
 background-color:#218BEE;
}
.skin-2  .nav-list > li:nth-child(12) > a:hover,
.skin-2  .menu-min .nav-list li:nth-child(12):hover > a,
.skin-2  .menu-min .nav-listli.active:nth-child(12) > a,
.skin-2  .nav-list > li:nth-child(12) > ul.submenu:before
{
 background-color:#2ECEE7;
}




.skin-2 .nav-list > li > ul.submenu { border-top-style:inset;}
.skin-2  .menu-min .nav-list > li > ul.submenu         {border-top-color:#666 !important;}

.skin-2  .nav-list > li > ul.submenu  {border-top-color:#C6487E;}
.skin-2  .nav-list > li:nth-child(1) > ul.submenu  {border-top-color:#00BE67;}
.skin-2  .nav-list > li:nth-child(2) > ul.submenu  {border-top-color:#5ED02B;}
.skin-2  .nav-list > li:nth-child(3) > ul.submenu  {border-top-color:#A7D700;}
.skin-2  .nav-list > li:nth-child(4) > ul.submenu  {border-top-color:#CEE100;}
.skin-2  .nav-list > li:nth-child(5) > ul.submenu  {border-top-color:#F8C600;}
.skin-2  .nav-list > li:nth-child(6) > ul.submenu  {border-top-color:#FFAB00;}
.skin-2  .nav-list > li:nth-child(7) > ul.submenu  {border-top-color:#FF6E00;}
.skin-2  .nav-list > li:nth-child(8) > ul.submenu  {border-top-color:#F21C30;}
.skin-2  .nav-list > li:nth-child(9) > ul.submenu  {border-top-color:#EE218B;}
.skin-2  .nav-list > li:nth-child(10) > ul.submenu{border-top-color:#C55EE7;}
.skin-2  .nav-list > li:nth-child(11) > ul.submenu{border-top-color:#218BEE;}
.skin-2  .nav-list > li:nth-child(12) > ul.submenu{border-top-color:#2ECEE7;}

.skin-2  .nav-list > li > ul.submenu li.active > a {color:#C6487E;}
.skin-2  .nav-list > li:nth-child(1) > ul.submenu  li.active > a {color:#00BE67;}
.skin-2  .nav-list > li:nth-child(2) > ul.submenu  li.active > a {color:#5ED02B;}
.skin-2  .nav-list > li:nth-child(3) > ul.submenu  li.active > a {color:#A7D700;}
.skin-2  .nav-list > li:nth-child(4) > ul.submenu  li.active > a {color:#CEE100;}
.skin-2  .nav-list > li:nth-child(5) > ul.submenu  li.active > a {color:#F8C600;}
.skin-2  .nav-list > li:nth-child(6) > ul.submenu  li.active > a {color:#FFAB00;}
.skin-2  .nav-list > li:nth-child(7) > ul.submenu  li.active > a {color:#FF6E00;}
.skin-2  .nav-list > li:nth-child(8) > ul.submenu  li.active > a {color:#F21C30;}
.skin-2  .nav-list > li:nth-child(9) > ul.submenu  li.active > a {color:#EE218B;}
.skin-2  .nav-list > li:nth-child(10) > ul.submenu li.active > a {color:#C55EE7;}
.skin-2  .nav-list > li:nth-child(11) > ul.submenu li.active > a {color:#218BEE;}
.skin-2  .nav-list > li:nth-child(12) > ul.submenu li.active > a {color:#2ECEE7;}


.skin-2  .nav-list > li.active > a,
.skin-2  .nav-list > li.active > a:hover
 {
 background-color:#242424;
}




.skin-2 .sidebar-collapse {
 background-color:#2C2C2C;
 border-top:1px solid #666;
 border-bottom:1px solid #777;
}
.skin-2 .sidebar-collapse:before {
 border-color:#666;
}
.skin-2 .sidebar-collapse > [class*="icon-"] {
 background-color:#333;
}



.skin-2 .sidebar-shortcuts {
 background-color:#393939;
 border-color:#666;
 min-height:40px;
}
.skin-2 .sidebar > .nav-search {
 background-color:#393939;
 border-color:#666;
}

.skin-2 .sidebar-shortcuts .btn {
 border-radius:32px !important;
 border-width:2px;
 background-color:#555 !important;
}
.skin-2 .sidebar-shortcuts .btn:hover {
 background-color:#222 !important;
}
.skin-2 .menu-min .sidebar-shortcuts-large {
 border:none;
 box-shadow:none;
 background-color:#393939;
 top:0px;
}
.skin-2 .sidebar-shortcuts-mini {
 background-color:#141A1B;
 border-color:#3F4E54;
}
.skin-2  .sidebar-shortcuts-mini .btn {
 border-width:2px !important;
 border-radius:32px !important;
 padding:6px !important;
 opacity:1;
 background-color:inherit;
}


body.skin-2 .main-content{
 padding-top:8px;
}
.skin-2 .breadcrumbs {
 border:none;
 background-color:#E7F2F8;
 margin:0 8px 0;
 border-radius:4px;
}






@media only screen and (max-width: 991px) {
.skin-2  .sidebar {
 border:none;
 box-shadow:none;
}
.skin-2 .nav-list li.active > a:after {
 display:none;
}

body.skin-2 .main-content{
 padding-top: 0;
}
.skin-2 .breadcrumbs {
 margin:0;
 border-radius:0;
}

.skin-2 .menu-min .nav-list > li.active > a:after {
 display:none;
}
.skin-2 .menu-min .nav-list > li.active:hover > a:after {
 display:block;
}
}




/*3rd & 4th level*/
.skin-2 .nav-list > li  .submenu   a {
	background-color:transparent !important;
}
.skin-2 .nav-list > li  > .submenu > li > .submenu  a {
	border-top-color:#CCC;
}
.skin-2 .nav-list > li.active  > .submenu > li > .submenu  a {
	border-top-color:#999;
}

.skin-2 .nav-list > li > .submenu li > .submenu > li a {
 color:#D5D5D5;
}
.skin-2 .nav-list > li > .submenu li > .submenu > li a > [class*="icon-"]:first-child {
 color:#D5D5D5;
}
.skin-2 .nav-list > li > .submenu li > .submenu li.active > a > [class*="icon-"]:first-child {
 color:inherit;
}

.skin-2 .nav-list > li  .submenu   a > .arrow {
	color:#CCC;
}
.skin-2 .nav-list > li > .submenu > li > a:hover > .arrow ,
.skin-2 .nav-list > li > .submenu > li > .submenu > li > a:hover > .arrow {
	color:#EEE;
}


.skin-2 .nav-list > li > .submenu > li > .submenu > li > a {
	margin-left:0;
	padding-left:38px;
}
.skin-2 .nav-list > li > .submenu > li > .submenu > li > .submenu > li > a {
	margin-left:0;
	padding-left:54px;
}




/* the 3rd and 4th level open link colors */
.skin-2  .nav-list > li  ul.submenu li.open > a {color:#C6487E;}
.skin-2  .nav-list > li:nth-child(1)  ul.submenu  li.open > a {color:#00BE67;}
.skin-2  .nav-list > li:nth-child(2)  ul.submenu  li.open > a {color:#5ED02B;}
.skin-2  .nav-list > li:nth-child(3)  ul.submenu  li.open > a {color:#A7D700;}
.skin-2  .nav-list > li:nth-child(4)  ul.submenu  li.open > a {color:#CEE100;}
.skin-2  .nav-list > li:nth-child(5)  ul.submenu  li.open > a {color:#F8C600;}
.skin-2  .nav-list > li:nth-child(6)  ul.submenu  li.open > a {color:#FFAB00;}
.skin-2  .nav-list > li:nth-child(7)  ul.submenu  li.open > a {color:#FF6E00;}
.skin-2  .nav-list > li:nth-child(8)  ul.submenu  li.open > a {color:#F21C30;}
.skin-2  .nav-list > li:nth-child(9)  ul.submenu  li.open > a {color:#EE218B;}
.skin-2  .nav-list > li:nth-child(10) ul.submenu li.open > a {color:#C55EE7;}
.skin-2  .nav-list > li:nth-child(11)  ul.submenu li.open > a {color:#218BEE;}
.skin-2  .nav-list > li:nth-child(12)  ul.submenu li.open > a {color:#2ECEE7;}

.skin-2  .nav-list > li  ul.submenu li > a:hover {
	color:#FFF;
}


.skin-2 .nav-list > li > .submenu > li.open > a > [class*="icon-"]:first-child ,
.skin-2 .nav-list > li > .submenu > li > .submenu > li.open > a > [class*="icon-"]:first-child
{
	color:inherit;
}
.skin-2 .nav-list > li > .submenu > li.open > a > .arrow,
.skin-2 .nav-list > li > .submenu > li > .submenu > li.open > a > .arrow
{
	color:inherit;
}










/* skin#3 */
.skin-3 {
 background-color: #BBB;
}
.skin-3 .main-container:after
{
 background: #FFF;
 
 background: -moz-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
 background: -webkit-gradient(linear, 0 0, 0 100%, from(#EEF5FA), color-stop(4%, #FFF)) 0 4px;
 background: -webkit-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
 background: -o-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
 background: -ms-linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;
 background: linear-gradient(top, #EEF5FA 0%, #FFF 8%) 0 4px;

 -moz-background-size: 100% 26px;
 -webkit-background-size: 100% 26px;
 -o-background-size: 100% 26px;
 -ms-background-size: 100% 26px;
 background-size: 100% 26px;
}


.skin-3 .navbar  {
 background:#393939;
}

.skin-3  .sidebar , .skin-3  .sidebar:before {
 background-color:#D6D6D6;
 border-right:1px solid #A4C6DD;
}


.skin-3 .page-content {
 background-color:transparent;/* so that .main-content's background can be seen */
}
.skin-3 .infobox:not(.infobox-dark) {
 border-style:solid;
 background-color:transparent;
}



.skin-3  .nav-list > li {
 border-color:#F2F2F2;
 border-bottom-width:0;
 position:relative;
}
.skin-3  .nav-list > li:before {
 display:inline-block; content:"";
 position:absolute; z-index:1;
 left:0; top:0; bottom:0; max-width:0; overflow:hidden;
 border-left:3px solid #444;
}

.skin-3 .nav-list > li.active:before {
 border-left-color:#4D96CB;
}

.skin-3  .nav-list > li:first-child {
 border-top:1px solid #F2F2F2;
}
.skin-3  .nav-list > li:last-child {
 border-bottom-width:1px;
}



.skin-3  .nav-list > li:hover {
 border-left-color:#1963AA;
 border-top-color:#EEE;
}

.skin-3  .nav-list > li:hover + li {
 border-top-color:#EEE;
}
.skin-3  .nav-list > li:last-child:hover {
 border-bottom-color:#EEE;
}
.skin-3  .nav-list > li.active {
 border-left-color:#1A67BD;
 border-top-color:#A4C6DD !important;
}
.skin-3  .nav-list > li.active + li {
 border-top-color:#A4C6DD;
}
.skin-3  .nav-list > li.active:last-child {
 border-bottom-color:#A4C6DD;
}


.skin-3  .nav-list > li > a:hover:before{
 display:none;
}
.skin-3 .nav-list > li > a {
 background-color:#E0E0E0;
 color:#5A5A5A;
}
.skin-3 .nav-list > li:hover > a {
 background-color:#EEE;
 color:#1963AA;
}
.skin-3 .nav-list > li > a:focus{
 color:#1963AA;
}

.skin-3 .nav-list > li.open > a  {
 background-color:#F3F3F3;
 color:#1963AA;
}
.skin-3 .menu-min .nav-list > li.open > a  {
 background-color:#E0E0E0;
 color:#5A5A5A;
}
.skin-3 .menu-min .nav-list > li.open > a > .menu-text {
 color:#1963AA;
}
.skin-3 .menu-min .nav-list > li.active > a > .menu-text {
 border-left-color:#EEE;
}

.skin-3 .nav-list > li.active > a {
 background-color:#EEF8FF;
 color:#4D96CB;
}


.skin-3 .nav-list li.active > a:after{
 border-right-color:#FFF;
 right:-1px; top:0; z-index:12;
 border-width:18px 12px;
}


.skin-3 .nav-list  li.active > a:before {/*the blue one under the white one!*/
 content:""; display:block;
 position:absolute;
 right:0; top:0;  z-index:11;   /**/ left:auto; bottom:auto; width:auto; max-width:auto; background-color:transparent;
 border-style:solid;
 border-width:18px 12px;
 border-color:transparent;
 border-right-color:#A4C6DD;
}

.skin-3 .nav-list > li.active.open > .submenu  li.active > a:before,
.skin-3 .nav-list > li.active.open > .submenu  li.active > a:after {
 border-width:14px 10px;
 top:2px;
}
.skin-3 .nav-list > li.active.open > .submenu .submenu li.active > a:before,
.skin-3 .nav-list > li.active.open > .submenu  .submenu li.active > a:after {
 top:0;
}

.skin-3 .nav-list li.active.open > a:after,
.skin-3 .nav-list li.active.open > a:before  {
 display:none;
}
.skin-3 .nav-list > li.active:after {/* the white border on right */
 display:none;
}

/* extra a:after rules applied to a:before as well for skin-3 */
.skin-3 .nav-list li.active.open > .submenu > li.active.open > a.dropdown-toggle:before {
  display: none;
}
.skin-3 .nav-list li.active > .submenu > li.active > a:before {
  display: none;
}
.skin-3 .nav-list li.active.open > .submenu > li.active > a:before {
  display: block;
}
.skin-3 .menu-min .nav-list li.active.open > .submenu > li.active > a:before {
  display: none;
}



.skin-3 .menu-min .nav-list > li.active > a {
 background-color:#4D96CB;
 color:#FFF;
}
.skin-3 .menu-min .nav-list > li.active > a > .menu-text {
 color:#4D96CB;
}

.skin-3 .menu-min .nav-list  li.active > a:after {
 display:none;
}
.skin-3 .menu-min .nav-list  li.active > a:before {
 display:none;
}

.skin-3 .menu-min .nav-list > li.active > a:after {
 display:block;
 border-width:12px 7px;
 top:5px;
}

.skin-3 .menu-min .sidebar-shortcuts {
 border-left:none;
}
.skin-3 .sidebar.menu-min  > .nav-search {
 border-left:none;
}

.skin-3 .menu-min .nav-list > li > .submenu,
.skin-3 .menu-min .nav-list > li.active > .submenu {
 border-left-color:#CCC;
}


.skin-3 .nav-list > li > a  > .arrow {
 right:10px;
}




.skin-3 .sidebar-collapse {
 background-color:#D0D0D0;
 border-color:#FFF; 
}
.skin-3 .sidebar-collapse:before {
 border-color:#FFF;
}
.skin-3 .sidebar-collapse > [class*="icon-"] {
 background-color:#FFF;
 border-color:#999;
 color:#999;
}


.skin-3 .sidebar-shortcuts {
 background-color:#E0E0E0;
 border-bottom:none;
 border-color:#FFF;
 min-height:40px;
 border-left:3px solid #444;
}
.skin-3 .sidebar > .nav-search {
 background-color:#E0E0E0;
 border-bottom:none;
 border-color:#FFF;
 border-left:3px solid #444;
}

.skin-3  .sidebar-shortcuts-mini {
  background-color:#E0E0E0;
}
.skin-3  .sidebar-shortcuts-mini > .btn {
 opacity:1;
}
.skin-3  .menu-min .sidebar-shortcuts-large {
 background-color:#F5F5F5;
 top:-1px;
}


body.skin-3 .main-content {
 padding-top: 8px;
}
.skin-3 .breadcrumbs  {
 border:none;
 border-radius:4px;
 background-color:#E7F2F8;

 margin:0 8px 0;
}
.skin-3 .nav-search-input {
 border-radius:4px;
}
.skin-3 .page-content > .page-header:first-child {
 border-bottom-color:#C9C9C9;
}
.skin-3 .hr {
 border-color:#D5D5D5;
}


@media only screen and (max-width: 991px) {

.skin-3  .sidebar,
.skin-3  .nav-list > li ,
.skin-3 .sidebar-shortcuts {
 border-left:none;
}
.skin-3 .sidebar {
 border-color:#A4C6DD;
}

body.skin-3 .main-content  {
 padding-top: 0;
}
.skin-3 .breadcrumbs  {
 border-radius:0;
 margin:0;
}

.skin-3 .menu-toggler {
 background-color:#62A8D1;
 color:#FFF;
}
.skin-3 .menu-toggler:after , .skin-3 .menu-toggler:before {
 border-color:#FFF;
}
.skin-3 .menu-toggler > .menu-text {
 border-top-color:#62A8D1;
}
.skin-3 .menu-toggler > .menu-text:after {
 color:#FFF;
}



.skin-3 .nav-list  li.active > a:before,
.skin-3  .nav-list  li.active > a:after {
 display:none;
}
.skin-3 .menu-min .nav-list  li.active > a:before,
.skin-3 .menu-min .nav-list  li.active > a:after {
 display:none;
}

.skin-3 .nav-list li.active.open > .submenu > li.active > a:before {
 display:none;
}

.skin-3 .menu-min .nav-list > li.active.open:hover > a:after {
 display:block !important;
}

}






/* RTL */
.rtl.skin-1  .sidebar , .rtl.skin-1  .sidebar:before {
 border-left:none;
}
.rtl.skin-1 .nav-list  li.active > a:after {
 border-right-color:transparent;
 border-left-color:#FFF;
}
.rtl.skin-1 .menu-min .nav-list > li.active:hover > a:after {
 border-left-color:#242A2B;
 border-right-color:transparent;
}

.rtl.skin-1 .menu-min .nav-list > li > a > .menu-text,
.rtl.skin-1 .menu-min .nav-list > li > ul.submenu {
 border-left-color:#3F4E54;
 border-right-color:#242A2B;
}



.rtl.skin-2  .sidebar , .rtl.skin-2 .sidebar:before {
 border-left:none;
}
.rtl.skin-2 .nav-list  li.active > a:after {
 border-right-color:transparent;
 border-left-color:#FFF;
}
.rtl.skin-2 .nav-list  > li.active > a:after ,
.rtl.skin-2 .nav-list  > li.active > .submenu > li.active > a:after {
 right:auto;
 left:0;
}
.rtl.skin-2 .menu-min .nav-list > li.active:hover > a:after {
 border-right-color:transparent;
 border-left-color:#292929;
}
.rtl.skin-2 .nav-list > li.active.open > .submenu > li.active > a:after {
 border-right-color:transparent;
 border-left-color:#FFF;
 right:auto;
 left:0;
}
.rtl.skin-2  .menu-min .nav-list > li > a > .menu-text {
 text-shadow:-1px 1px 0 rgba(0,0,0,0.5);
}
.rtl.skin-2  .menu-min .nav-list > li > ul.submenu {
 margin-left:auto;
 margin-right:1px;
 
 border-left:none;
 border-right:1px solid #FFF;
}
.rtl.skin-2  .nav-list > li > ul.submenu > li > a {
 padding-left:0;
 padding-right:28px;
}
.rtl.skin-2 .nav-list > li > .submenu > li > .submenu > li > a {
	margin-left:auto;
	padding-left:0;

	margin-right:0;
	padding-right:38px;
}
.rtl.skin-2 .nav-list > li > .submenu > li > .submenu > li > .submenu > li > a {
	margin-left:auto;
	padding-left:0;

	margin-right:0;
	padding-right:54px;
}



.rtl.skin-3  .sidebar , .rtl.skin-3 .sidebar:before {
 border-right:none;
 border-left:1px solid #A4C6DD;
}
.rtl.skin-3  .nav-list > li:before {
 left:auto;
 right:0;

 border-left:none;
 border-right:3px solid #444;
}
.rtl.skin-3 .nav-list > li.active:before {
 border-left:none;
 border-right-color:#4D96CB;
}
.rtl.skin-3  .nav-list > li:hover {
 border-left-color:#F2F2F2;
 border-right-color:#1963AA;
}
.rtl.skin-3  .nav-list > li.active {
 border-left-color:#F2F2F2;
 border-right-color:#1A67BD;
}
.rtl.skin-3 .menu-min .nav-list > li.active > a > .menu-text {
 border-left-color:#CCC;
 border-right-color:#EEE;
}
.rtl.skin-3 .nav-list li.active > a:after{
 border-right-color:transparent;
 border-left-color:#FFF;
 left:-1px;
 right:auto;
}
.rtl.skin-3 .nav-list  li.active > a:before {
 right:auto;
 left:0;
 border-right-color:transparent;
 border-left-color:#A4C6DD;
}

.rtl.skin-3 .menu-min .sidebar-shortcuts {
 border-right:none;
}
.rtl.skin-3 .sidebar.menu-min  > .nav-search {
 border-right:none;
}

.rtl.skin-3 .menu-min .nav-list > li > .submenu,
.rtl.skin-3 .menu-min .nav-list > li.active > .submenu {
 border-right-color:#CCC;
}

.rtl.skin-3 .nav-list > li > a  > .arrow {
 right:auto;
 left:10px;
}

.rtl.skin-3 .sidebar-shortcuts {
 border-left:none;
 border-right:3px solid #444;
}
.rtl.skin-3 .sidebar > .nav-search {
 border-left:none;
 border-right:3px solid #444;
}

@media only screen and (max-width: 991px) {
.rtl.skin-3  .sidebar,
.rtl.skin-3  .nav-list > li ,
.rtl.skin-3 .sidebar-shortcuts {
 border-right:none;
}
}

