html {
  min-height: 100%;
  position: relative;
}
body {
  padding-bottom: 0;
  background-color: #e4e6e9;
  min-height: 100%;
  font-family: 'Open Sans';
  font-size: 13px;
  color: #393939;
  line-height: 1.5;
}
body.navbar-fixed {
  padding-top: 45px;
}
body.breadcrumbs-fixed {
  padding-top: 86px;
}
/* ace default theme layout sections */
.main-container {
  position: static;
  padding: 0;
}
.main-container:after {
  background-color: #FFF;
  bottom: 0;
  content: "";
  display: block;
  max-width: inherit;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: -2;
}
@media (min-width: 768px) {
  .container.main-container:after {
    -webkit-box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
    box-shadow: 0 0 0 1px rgba(0,0,0,0.1);
  }
}
.main-container > .main-container-inner {
  position: relative;
}
.container.main-container .sidebar.sidebar-fixed,
.container.main-container .sidebar.sidebar-fixed:before {
  left: auto;
}
.main-content {
  margin-left: 190px;
  margin-right: 0;
  margin-top: 0;
  min-height: 100%;
  padding: 0;
}
.page-content {
  background: #ffffff;
  margin: 0;
  padding: 8px 20px 24px;
}
.page-header {
  margin: 0 0 12px;
  border-bottom: 1px dotted #e2e2e2;
  padding-bottom: 16px;
  padding-top: 7px;
}
.page-header h1 {
  padding: 0;
  margin: 0 8px;
  font-size: 24px;
  font-weight: lighter;
  color: #2679b5;
}
.page-header h1 small {
  margin: 0 6px;
  font-size: 14px;
  font-weight: normal;
  color: #8089a0;
}
/* elements */
[class*=" icon-"],
[class^="icon-"] {
  display: inline-block;
  text-align: center;
}
a:focus,
a:active {
  text-decoration: none;
}
/* header sizes */
h1 {
  font-size: 32px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h1.smaller {
  font-size: 31px;
}
h1.bigger {
  font-size: 33px;
}
h1.block {
  margin-bottom: 16px;
}
h2 {
  font-size: 26px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h2.smaller {
  font-size: 25px;
}
h2.bigger {
  font-size: 27px;
}
h2.block {
  margin-bottom: 16px;
}
h3 {
  font-size: 22px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h3.smaller {
  font-size: 21px;
}
h3.bigger {
  font-size: 23px;
}
h3.block {
  margin-bottom: 16px;
}
h4 {
  font-size: 18px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h4.smaller {
  font-size: 17px;
}
h4.bigger {
  font-size: 19px;
}
h4.block {
  margin-bottom: 16px;
}
h5 {
  font-size: 15px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h5.smaller {
  font-size: 14px;
}
h5.bigger {
  font-size: 16px;
}
h5.block {
  margin-bottom: 16px;
}
h6 {
  font-size: 13px;
  font-weight: normal;
  font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}
h6.smaller {
  font-size: 12px;
}
h6.bigger {
  font-size: 14px;
}
h6.block {
  margin-bottom: 16px;
}
/* some list styling */
ul,
ol {
  margin: 0 0 10px 25px;
  padding: 0;
}
ul.margin-5,
ol.margin-5 {
  margin-left: 5px;
}
ul.margin-10,
ol.margin-10 {
  margin-left: 10px;
}
ul.margin-15,
ol.margin-15 {
  margin-left: 15px;
}
ul.margin-20,
ol.margin-20 {
  margin-left: 20px;
}
li > ul,
li > ol {
  margin-left: 18px;
}
.list-unstyled,
.list-inline {
  margin-left: 0;
}
.list-unstyled > li > [class*="icon-"]:first-child,
.list-inline > li > [class*="icon-"]:first-child {
  width: 18px;
  text-align: center;
}
.spaced > li {
  margin-top: 9px;
  margin-bottom: 9px;
}
.spaced2 > li {
  margin-top: 15px;
  margin-bottom: 15px;
}
li.divider {
  margin-top: 3px;
  margin-bottom: 3px;
  height: 0;
  font-size: 0;
}
.spaced > li.divider {
  margin-top: 5px;
  margin-bottom: 5px;
}
.spaced2 > li.divider {
  margin-top: 8px;
  margin-bottom: 8px;
}
li.divider:before {
  content: "";
  display: inline-block;
}
/* little elements */
blockquote,
blockquote.pull-right {
  border-color: #e5eaf1;
}
/* modals */
.modal-content {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.modal-footer {
  padding-top: 12px;
  padding-bottom: 14px;
  border-top-color: #e4e9ee;
  -webkit-box-shadow: none;
  box-shadow: none;
  background-color: #eff3f8;
}
.modal-header .close {
  font-size: 32px;
}
/* wells */
.well {
  border-radius: 0;
}
.well h1,
.well h2,
.well h3,
.well h4,
.well h5,
.well h6 {
  margin-top: 0;
}
.well h1,
.well h2,
.well h3 {
  line-height: 36px;
}
/* alerts */
.alert {
  font-size: 14px;
  border-radius: 0;
}
.alert .close {
  font-size: 16px;
}
.alert-block p + p {
  margin-top: 10px;
}
/* pagination */
.pagination > li > a,
.pager > li > a,
.pagination > li > span,
.pager > li > span {
  border-width: 1px;
  border-radius: 0 !important;
}
.pagination > li > a,
.pager > li > a {
  color: #2283c5;
  background-color: #fafafa;
  margin: 0 -1px 0 0;
  border-color: #e0e8eb;
}
.pagination > li > a:hover,
.pager > li > a:hover {
  background-color: #eaeff2;
}
.pagination > li > a:focus,
.pager > li > a:focus {
  background-color: #eaeef2;
}
.pagination > li.disabled > a,
.pagination > li.disabled > a:hover,
.pager > li.disabled > a,
.pager > li.disabled > a:hover {
  background-color: #f9f9f9;
  border-color: #d9d9d9;
}
.pagination > li.active > a,
.pagination > li.active > a:hover {
  background-color: #6faed9;
  border-color: #6faed9;
  color: #ffffff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}
.list-group-item {
  border-radius: 0 !important;
}
.lighter {
  font-weight: lighter;
}
.bolder {
  font-weight: bolder;
}
.inline {
  display: inline-block !important;
}
.block {
  display: block !important;
}
.center,
.align-center {
  text-align: center !important;
}
.align-left {
  text-align: left !important;
}
.align-right {
  text-align: right !important;
}
.middle {
  vertical-align: middle;
}
.align-middle {
  vertical-align: middle !important;
}
.align-top {
  vertical-align: top !important;
}
.align-bottom {
  vertical-align: bottom !important;
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.line-height-normal {
  line-height: normal !important;
}
.line-height-1 {
  line-height: 1 !important;
}
.dark {
  color: #333333 !important;
}
.white {
  color: #ffffff !important;
}
.red {
  color: #dd5a43 !important;
}
.light-red {
  color: #ff7777 !important;
}
.blue {
  color: #478fca !important;
}
.light-blue {
  color: #93cbf9 !important;
}
.green {
  color: #69aa46 !important;
}
.light-green {
  color: #b0d877 !important;
}
.orange {
  color: #ff892a !important;
}
.light-orange {
  color: #fcac6f !important;
}
.orange2 {
  color: #feb902 !important;
}
.purple {
  color: #a069c3 !important;
}
.pink {
  color: #c6699f !important;
}
.pink2 {
  color: #d6487e !important;
}
.brown {
  color: #a52a2a !important;
}
.grey {
  color: #777777 !important;
}
.light-grey {
  color: #bbbbbb !important;
}
.bigger-110 {
  font-size: 110%;
}
.bigger-120 {
  font-size: 120%;
}
.bigger-130 {
  font-size: 130%;
}
.bigger-140 {
  font-size: 140%;
}
.bigger-150 {
  font-size: 150%;
}
.bigger-160 {
  font-size: 160%;
}
.bigger-170 {
  font-size: 170%;
}
.bigger-180 {
  font-size: 180%;
}
.bigger-190 {
  font-size: 190%;
}
.bigger-200 {
  font-size: 200%;
}
.bigger-210 {
  font-size: 210%;
}
.bigger-220 {
  font-size: 220%;
}
.bigger-230 {
  font-size: 230%;
}
.bigger-240 {
  font-size: 240%;
}
.bigger-250 {
  font-size: 250%;
}
.bigger-260 {
  font-size: 260%;
}
.bigger-270 {
  font-size: 270%;
}
.bigger-280 {
  font-size: 280%;
}
.bigger-290 {
  font-size: 290%;
}
.bigger-300 {
  font-size: 300%;
}
.bigger-125 {
  font-size: 125%;
}
.bigger-175 {
  font-size: 175%;
}
.bigger-225 {
  font-size: 225%;
}
.bigger-275 {
  font-size: 275%;
}
.smaller-90 {
  font-size: 90%;
}
.smaller-80 {
  font-size: 80%;
}
.smaller-70 {
  font-size: 70%;
}
.smaller-60 {
  font-size: 60%;
}
.smaller-50 {
  font-size: 50%;
}
.smaller-40 {
  font-size: 40%;
}
.smaller-30 {
  font-size: 30%;
}
.smaller-20 {
  font-size: 20%;
}
.smaller-75 {
  font-size: 75%;
}
.width-20 {
  width: 20% !important;
}
.width-25 {
  width: 25% !important;
}
.width-30 {
  width: 30% !important;
}
.width-35 {
  width: 35% !important;
}
.width-40 {
  width: 40% !important;
}
.width-45 {
  width: 45% !important;
}
.width-50 {
  width: 50% !important;
}
.width-55 {
  width: 55% !important;
}
.width-60 {
  width: 60% !important;
}
.width-65 {
  width: 65% !important;
}
.width-70 {
  width: 70% !important;
}
.width-75 {
  width: 75% !important;
}
.width-80 {
  width: 80% !important;
}
.width-85 {
  width: 85% !important;
}
.width-90 {
  width: 90% !important;
}
.width-95 {
  width: 95% !important;
}
.width-100 {
  width: 100% !important;
}
.width-48 {
  width: 48% !important;
}
.width-auto {
  width: auto !important;
}
.height-auto {
  height: auto !important;
}
.no-padding {
  padding: 0 !important;
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
.no-padding-top {
  padding-top: 0 !important;
}
.no-padding-left {
  padding-left: 0 !important;
}
.no-padding-right {
  padding-right: 0 !important;
}
.no-margin {
  margin: 0 !important;
}
.no-margin-bottom {
  margin-bottom: 0 !important;
}
.no-margin-top {
  margin-top: 0 !important;
}
.no-margin-left {
  margin-left: 0 !important;
}
.no-margin-right {
  margin-right: 0 !important;
}
.no-border {
  border: none;
}
.no-border-bottom {
  border-bottom: none;
}
.no-border-top {
  border-top: none;
}
.no-border-left {
  border-left: none;
}
.no-border-right {
  border-right: none;
}
.no-underline {
  text-decoration: none !important;
}
.no-hover-underline:hover {
  text-decoration: none !important;
}
.no-shadow,
.no-box-shadow {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
.no-text-shadow {
  text-shadow: none !important;
}
.overflow-hidden {
  overflow: hidden !important;
}
.overflow-auto {
  overflow: auto !important;
}
.overflow-scroll {
  overflow: scroll !important;
}
.overflow-visible {
  overflow: visible !important;
}
/* <hr /> like spacing by using div, e.g <div class="hr hr-32"></div> */
.hr {
  display: block;
  height: 0px;
  overflow: hidden;
  font-size: 0;
  border-top: 1px solid #E3E3E3;
  margin: 12px 0;
}
.hr-double {
  height: 3px;
  border-top: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
}
.hr.dotted,
.hr-dotted {
  border-top-style: dotted;
}
.hr-double.dotted {
  border-bottom-style: dotted;
}
.hr-32,
.hr32 {
  margin: 32px 0;
}
.hr-30,
.hr30 {
  margin: 30px 0;
}
.hr-28,
.hr28 {
  margin: 28px 0;
}
.hr-26,
.hr26 {
  margin: 26px 0;
}
.hr-24,
.hr24 {
  margin: 24px 0;
}
.hr-22,
.hr22 {
  margin: 22px 0;
}
.hr-20,
.hr20 {
  margin: 20px 0;
}
.hr-18,
.hr18 {
  margin: 18px 0;
}
.hr-16,
.hr16 {
  margin: 16px 0;
}
.hr-14,
.hr14 {
  margin: 14px 0;
}
.hr-12,
.hr12 {
  margin: 12px 0;
}
.hr-10,
.hr10 {
  margin: 10px 0;
}
.hr-8,
.hr8 {
  margin: 8px 0;
}
.hr-6,
.hr6 {
  margin: 6px 0;
}
.hr-4,
.hr4 {
  margin: 4px 0;
}
.hr-2,
.hr2 {
  margin: 2px 0;
}
/* some spacing classes, use like <div class="space-24"></div> */
.space {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
}
.vspace-xs,
.vspace-sm,
.vspace-md,
.vspace-lg {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  display: none;
  margin: 12px 0;
}
/* visible only on smaller devices where grid columns are stacked on top of each other */
.space-32 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 32px 0 31px;
}
.vspace-32,
.vspace-xs-32,
.vspace-sm-32,
.vspace-md-32,
.vspace-lg-32 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 32px 0 31px 0;
}
.space-30 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 30px 0 29px;
}
.vspace-30,
.vspace-xs-30,
.vspace-sm-30,
.vspace-md-30,
.vspace-lg-30 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 30px 0 29px 0;
}
.space-28 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 28px 0 27px;
}
.vspace-28,
.vspace-xs-28,
.vspace-sm-28,
.vspace-md-28,
.vspace-lg-28 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 28px 0 27px 0;
}
.space-26 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 26px 0 25px;
}
.vspace-26,
.vspace-xs-26,
.vspace-sm-26,
.vspace-md-26,
.vspace-lg-26 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 26px 0 25px 0;
}
.space-24 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 24px 0 23px;
}
.vspace-24,
.vspace-xs-24,
.vspace-sm-24,
.vspace-md-24,
.vspace-lg-24 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 24px 0 23px 0;
}
.space-22 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 22px 0 21px;
}
.vspace-22,
.vspace-xs-22,
.vspace-sm-22,
.vspace-md-22,
.vspace-lg-22 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 22px 0 21px 0;
}
.space-20 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 20px 0 19px;
}
.vspace-20,
.vspace-xs-20,
.vspace-sm-20,
.vspace-md-20,
.vspace-lg-20 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 20px 0 19px 0;
}
.space-18 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 18px 0 17px;
}
.vspace-18,
.vspace-xs-18,
.vspace-sm-18,
.vspace-md-18,
.vspace-lg-18 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 18px 0 17px 0;
}
.space-16 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 16px 0 15px;
}
.vspace-16,
.vspace-xs-16,
.vspace-sm-16,
.vspace-md-16,
.vspace-lg-16 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 16px 0 15px 0;
}
.space-14 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 14px 0 13px;
}
.vspace-14,
.vspace-xs-14,
.vspace-sm-14,
.vspace-md-14,
.vspace-lg-14 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 14px 0 13px 0;
}
.space-12 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 12px 0 11px;
}
.vspace-12,
.vspace-xs-12,
.vspace-sm-12,
.vspace-md-12,
.vspace-lg-12 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 12px 0 11px 0;
}
.space-10 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 10px 0 9px;
}
.vspace-10,
.vspace-xs-10,
.vspace-sm-10,
.vspace-md-10,
.vspace-lg-10 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 10px 0 9px 0;
}
.space-8 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 8px 0 7px;
}
.vspace-8,
.vspace-xs-8,
.vspace-sm-8,
.vspace-md-8,
.vspace-lg-8 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 8px 0 7px 0;
}
.space-6 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 6px 0 5px;
}
.vspace-6,
.vspace-xs-6,
.vspace-sm-6,
.vspace-md-6,
.vspace-lg-6 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 6px 0 5px 0;
}
.space-4 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 4px 0 3px;
}
.vspace-4,
.vspace-xs-4,
.vspace-sm-4,
.vspace-md-4,
.vspace-lg-4 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 4px 0 3px 0;
}
.space-2 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 2px 0 1px;
}
.vspace-2,
.vspace-xs-2,
.vspace-sm-2,
.vspace-md-2,
.vspace-lg-2 {
  max-height: 1px;
  min-height: 1px;
  overflow: hidden;
  margin: 12px 0;
  margin: 2px 0 1px 0;
}
/* headers */
.header {
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 18px;
  padding-bottom: 4px;
  border-bottom: 1px solid #CCC;
}
.header.blue {
  border-bottom-color: #d5e3ef;
}
.header.green {
  border-bottom-color: #cce2c1;
}
.header.purple {
  border-bottom-color: #e0d1ea;
}
.header.orange {
  border-bottom-color: #fdd0ac;
}
.header.orange2 {
  border-bottom-color: #fbd36b;
}
.header.red {
  border-bottom-color: #f3cdc6;
}
.header.grey {
  border-bottom-color: #c3c3c3;
}
.header.pink {
  border-bottom-color: #f2e0eb;
}
.header.pink2 {
  border-bottom-color: #e69eb9;
}
.header.light-blue {
  border-bottom-color: #dceefc;
}
.header.light-red {
  border-bottom-color: #fec5c5;
}
.header.light-green {
  border-bottom-color: #d2e7b5;
}
.header.brown {
  border-bottom-color: #df9393;
}
.header > [class*="icon-"] {
  margin-right: 2px;
}
.no-radius {
  border-radius: 0 !important;
}
.action-buttons a {
  margin: 0 3px;
  display: inline-block;
  opacity: 0.85;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.action-buttons a:hover {
  text-decoration: none;
  -moz-transform: scale(1.2);
  -webkit-transform: scale(1.2);
  -o-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  opacity: 1;
}
.navbar {
  margin-bottom: 0;
}
.navbar {
  margin-left: 0;
  margin-right: 0;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  padding-left: 0;
  padding-right: 0;
  min-height: 45px;
  position: relative;
  background: #438eb9;
}
.navbar > .container {
  padding-left: 0;
  padding-right: 0;
}
.navbar .navbar-text,
.navbar .navbar-link {
  color: #ffffff;
}
.navbar .navbar-brand {
  color: #ffffff;
  font-size: 24px;
  text-shadow: none;
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: #ffffff;
}
.navbar .navbar-header {
  margin: 0 !important;
}
.navbar .navbar-nav > li > a,
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  font-size: 13px;
  text-shadow: none;
  color: #ffffff;
}
.navbar-fixed-top,
.navbar-fixed-bottom {
  position: fixed;
}
.navbar-container {
  padding-left: 10px;
  padding-right: 20px;
}
.navbar-container.container {
  padding-left: 0;
  padding-right: 0;
}
/* ace-nav */
.ace-nav {
  height: 100%;
  margin: 0 !important;
}
.ace-nav > li {
  line-height: 45px;
  height: 45px;
  border-left: 1px solid #DDD;
  padding: 0;
  position: relative;
  float: left !important;
  /* different colors */

}
.ace-nav > li:first-child {
  border-left: none;
}
.ace-nav > li > a {
  background-color: #2e6589;
  color: #FFF;
  display: block;
  line-height: inherit;
  text-align: center;
  height: 100%;
  width: auto;
  min-width: 50px;
  padding: 0 8px;
  position: relative;
}
.ace-nav > li > a > [class*="icon-"] {
  font-size: 16px;
  color: #FFF;
  display: inline-block;
  width: 20px;
  text-align: center;
}
.ace-nav > li > a > .badge {
  position: relative;
  top: -4px;
  left: 2px;
  padding-right: 5px;
  padding-left: 5px;
}
.ace-nav > li > a:hover,
.ace-nav > li > a:focus {
  background-color: #2c5976;
}
.ace-nav > li.open > a {
  background-color: #2c5976 !important;
}
.ace-nav > li.open > a {
  color: #FFF !important;
}
.ace-nav > li.grey > a {
  background-color: #555555;
}
.ace-nav > li.grey > a:hover,
.ace-nav > li.grey > a:focus {
  background-color: #4b4b4b;
}
.ace-nav > li.open.grey > a {
  background-color: #4b4b4b !important;
}
.ace-nav > li.purple > a {
  background-color: #892e65;
}
.ace-nav > li.purple > a:hover,
.ace-nav > li.purple > a:focus {
  background-color: #762c59;
}
.ace-nav > li.open.purple > a {
  background-color: #762c59 !important;
}
.ace-nav > li.green > a {
  background-color: #2e8965;
}
.ace-nav > li.green > a:hover,
.ace-nav > li.green > a:focus {
  background-color: #2c7659;
}
.ace-nav > li.open.green > a {
  background-color: #2c7659 !important;
}
.ace-nav > li.light-blue > a {
  background-color: #62a8d1;
}
.ace-nav > li.light-blue > a:hover,
.ace-nav > li.light-blue > a:focus {
  background-color: #579ec8;
}
.ace-nav > li.open.light-blue > a {
  background-color: #579ec8 !important;
}
.ace-nav > li.light-blue2 > a {
  background-color: #42a8e1;
}
.ace-nav > li.light-blue2 > a:hover,
.ace-nav > li.light-blue2 > a:focus {
  background-color: #359fd9;
}
.ace-nav > li.open.light-blue2 > a {
  background-color: #359fd9 !important;
}
.ace-nav > li.red > a {
  background-color: #b74635;
}
.ace-nav > li.red > a:hover,
.ace-nav > li.red > a:focus {
  background-color: #a34335;
}
.ace-nav > li.open.red > a {
  background-color: #a34335 !important;
}
.ace-nav > li.light-green > a {
  background-color: #9abc32;
}
.ace-nav > li.light-green > a:hover,
.ace-nav > li.light-green > a:focus {
  background-color: #8ba832;
}
.ace-nav > li.open.light-green > a {
  background-color: #8ba832 !important;
}
.ace-nav > li.light-purple > a {
  background-color: #cb6fd7;
}
.ace-nav > li.light-purple > a:hover,
.ace-nav > li.light-purple > a:focus {
  background-color: #c263ce;
}
.ace-nav > li.open.light-purple > a {
  background-color: #c263ce !important;
}
.ace-nav > li.light-orange > a {
  background-color: #f79263;
}
.ace-nav > li.light-orange > a:hover,
.ace-nav > li.light-orange > a:focus {
  background-color: #f28653;
}
.ace-nav > li.open.light-orange > a {
  background-color: #f28653 !important;
}
.ace-nav > li.light-pink > a {
  background-color: #f4dae5;
}
.ace-nav > li.light-pink > a:hover,
.ace-nav > li.light-pink > a:focus {
  background-color: #eeccda;
}
.ace-nav > li.open.light-pink > a {
  background-color: #eeccda !important;
}
.ace-nav > li.dark > a {
  background-color: #404040;
}
.ace-nav > li.dark > a:hover,
.ace-nav > li.dark > a:focus {
  background-color: #363636;
}
.ace-nav > li.open.dark > a {
  background-color: #363636 !important;
}
.ace-nav > li.white-opaque > a {
  background-color: rgba(255, 255, 255, 0.8);
}
.ace-nav > li.white-opaque > a:hover,
.ace-nav > li.white-opaque > a:focus {
  background-color: rgba(245, 245, 245, 0.8);
}
.ace-nav > li.open.white-opaque > a {
  background-color: rgba(245, 245, 245, 0.8) !important;
}
.ace-nav > li.dark-opaque > a {
  background-color: rgba(0, 0, 0, 0.2);
}
.ace-nav > li.dark-opaque > a:hover,
.ace-nav > li.dark-opaque > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}
.ace-nav > li.open.dark-opaque > a {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.ace-nav > li.margin-4 {
  margin-left: 4px;
}
.ace-nav > li.margin-3 {
  margin-left: 3px;
}
.ace-nav > li.margin-2 {
  margin-left: 2px;
}
.ace-nav > li.margin-1 {
  margin-left: 1px;
}
.ace-nav > li.no-border {
  border: none !important;
}
.ace-nav > li .dropdown-menu {
  z-index: 1031;
}
.ace-nav .nav-user-photo {
  margin: -4px 8px 0 0;
  border-radius: 100%;
  border: 2px solid #FFF;
  max-width: 40px;
}
.ace-nav li:last-child a [class^="icon-"] {
  /* the arrow indicating "See more" on each dropdown , and the icons of user menu */

  display: inline-block;
  width: 1.25em;
  text-align: center;
}
/* ace-nav responsive */
@media only screen and (max-width: 767px) {
  .navbar-container {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right {
    right: -80px;
  }
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:after {
    right: 100px;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right {
    right: -40px;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:after {
    right: 60px;
  }
  .user-menu.dropdown-close.pull-right {
    right: 0 !important;
  }
}
@media only screen and (max-width: 480px) {
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right {
    right: -120px;
  }
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:after {
    right: 140px;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right {
    right: -80px;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:after {
    right: 100px;
  }
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right {
    right: -50px;
  }
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right:after {
    right: 70px;
  }
}
@media only screen and (max-width: 460px) {
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right {
    left: -5px;
    right: auto;
  }
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(4) > .dropdown-menu.pull-right:after {
    right: auto;
    left: 25px;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right {
    left: -60px;
    right: auto;
  }
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(3) > .dropdown-menu.pull-right:after {
    left: 80px;
    right: auto;
  }
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right {
    left: -110px;
    right: auto;
  }
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right:before,
  .ace-nav > li:nth-last-child(2) > .dropdown-menu.pull-right:after {
    left: 130px;
    right: auto;
  }
}
/* move the icons to the line below */
@media only screen and (max-width: 460px) {
  .navbar .navbar-header.pull-left {
    display: block;
    float: none !important;
  }
  .navbar .navbar-header.pull-right {
    display: block;
    float: none !important;
  }
  /*.navbar .navbar-inner {
    border-bottom-width:0;
   }*/
  .ace-nav {
    display: block;
    float: none !important;
    text-align: center;
    background-color: #404040;
    border: 1px solid #DDD;
    border-width: 1px 0;
    letter-spacing: -3px;
  }
  .ace-nav > li {
    display: inline-block;
    float: none !important;
    text-align: left;
    letter-spacing: normal;
  }
  .ace-nav > li:first-child {
    border-left: 1px solid #DDD;
  }
  .ace-nav > li:last-child {
    border-right: 1px solid #DDD;
  }
}
@media only screen and (max-width: 320px) {
  .ace-nav > li > a {
    padding: 0 5px !important;
  }
}
.user-menu > li > a {
  padding: 4px 12px;
}
.user-menu > li > a > [class*="icon-"] {
  margin-right: 6px;
  font-size: 120%;
}
.user-info {
  max-width: 100px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  vertical-align: top;
  line-height: 15px;
  position: relative;
  top: 6px;
}
.user-info small {
  display: block;
}
@media (min-width: 461px) and (max-width: 480px), (max-width: 360px) {
  .user-menu {
    padding-top: 42px !important;
  }
  .ace-nav .nav-user-photo {
    margin-right: 0;
  }
  .user-info {
    position: absolute !important;
    margin-top: 40px;
    margin-left: 1px;
    right: 2px;
    z-index: 1032;
    color: #777;
    font-size: 14px;
    width: 156px;
    max-width: 156px;
    padding-left: 8px;
    padding-right: 8px;
    height: 32px;
    line-height: 26px !important;
    display: none;
    border-bottom: 1px solid #E5E5E5;
    text-align: center;
    vertical-align: none;
    line-height: normal;
  }
  .user-info > small {
    display: inline;
    opacity: 1;
  }
  li.open .user-info {
    display: inline-block;
  }
}
/* breadcrumbs and searchbox */
.breadcrumbs {
  position: relative;
  border-bottom: 1px solid #e5e5e5;
  background-color: #f5f5f5;
  min-height: 41px;
  line-height: 40px;
  padding: 0 12px 0 0;
  display: block;
}
.breadcrumbs.fixed,
.breadcrumbs.breadcrumbs-fixed {
  position: fixed;
  right: 0;
  left: 190px;
  top: 45px;
  z-index: 1028;
}
.breadcrumb {
  background-color: transparent;
  display: inline-block;
  line-height: 24px;
  margin: 0 22px 0 12px;
  padding: 0;
  font-size: 13px;
  color: #333;
  border-radius: 0;
}
.breadcrumb > li,
.breadcrumb > li.active {
  color: #555555;
}
.breadcrumb > li > a {
  display: inline-block;
  padding: 0 4px;
  color: #4c8fbd;
}
.breadcrumb > li + li:before {
  font-family: FontAwesome;
  font-size: 14px;
  content: "\f105";
  color: #b2b6bf;
  margin-right: 2px;
  padding: 0 5px 0 2px;
  position: relative;
  top: 1px;
}
.breadcrumb .home-icon {
  margin-left: 4px;
  margin-right: 2px;
  font-size: 20px;
  position: relative;
  top: 2px;
}
@media only screen and (max-width: 991px) {
  .breadcrumb {
    margin-left: 90px;
  }
  .breadcrumbs.fixed,
  .breadcrumbs.breadcrumbs-fixed {
    position: relative !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    z-index: auto !important;
  }
  body.breadcrumbs-fixed .ace-settings-container {
    top: 50px;
  }
}
@media only screen and (max-width: 480px) {
  .breadcrumb > li > a {
    padding: 0 1px;
  }
}
@media only screen and (max-width: 320px) {
  .breadcrumb {
    margin-left: 36px;
  }
}
.container.main-container .breadcrumbs.breadcrumbs-fixed {
  left: auto !important;
  right: auto !important;
}
@media (min-width: 768px) {
  .container.main-container .breadcrumbs.breadcrumbs-fixed {
    width: 554px;
  }
  .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
    width: 701px;
  }
}
@media (min-width: 992px) {
  .container.main-container .breadcrumbs.breadcrumbs-fixed {
    width: 774px;
  }
  .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
    width: 921px;
  }
}
@media (min-width: 1200px) {
  .container.main-container .breadcrumbs.breadcrumbs-fixed {
    width: 974px;
  }
  .container.main-container .menu-min + .main-content .breadcrumbs.breadcrumbs-fixed {
    width: 1121px;
  }
}
/* searchbox */
.nav-search {
  position: absolute;
  top: 6px;
  right: 22px;
  line-height: 24px;
}
.nav-search .form-search {
  margin-bottom: 0;
}
.nav-search .nav-search-input {
  border: 1px solid #6fb3e0;
  width: 152px;
  height: 28px !important;
  border-radius: 4px !important;
  font-size: 13px;
  color: #666666 !important;
  z-index: 11;
  -webkit-transition: width ease .15s;
  transition: width ease .15s;
}
.nav-search .nav-search-input + .dropdown-menu {
  /* the typeahead menu*/

  min-width: 0;
  left: 0;
  right: 0;
}
.nav-search .nav-search-input:focus,
.nav-search .nav-search-input:hover {
  border-color: #6fb3e0;
}
.nav-search .nav-search-icon {
  color: #6fb3e0 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}
.nav-search.minimized .nav-search-input {
  width: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  max-width: 0;
}
.nav-search.minimized:hover .nav-search-input,
.nav-search.minimized .nav-search-btn:active + .nav-search-input,
.nav-search.minimized .nav-search-input:focus,
.nav-search.minimized .nav-search-input:hover,
.nav-search.minimized .nav-search-input:active {
  opacity: 1;
  filter: alpha(opacity=100);
  width: 152px;
  max-width: 152px;
}
.nav-search.minimized .nav-search-icon {
  border: 1px solid;
  border-radius: 100%;
  background-color: #FFF;
  padding: 0 5px !important;
}
.nav-search.minimized:hover .nav-search-icon,
.nav-search.minimized .nav-search-input:focus ~ .nav-search-icon,
.nav-search.minimized .nav-search-input:hover ~ .nav-search-icon,
.nav-search.minimized .nav-search-input:active ~ .nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
/* nav-search inside sidebar */
.sidebar > .nav-search {
  position: static;
  background-color: #FAFAFA;
  border-bottom: 1px solid #DDD;
  text-align: center;
  height: 35px;
  padding-top: 6px;
}
.sidebar > .nav-search .nav-search-input {
  width: 162px !important;
  border-radius: 0 !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar > .nav-search .nav-search-input + .dropdown-menu {
  text-align: left;
}
/* sidebar when minimized */
.sidebar.menu-min .nav-search .form-search {
  position: absolute;
  left: 5px;
  z-index: 14;
}
.sidebar.menu-min .nav-search .nav-search-input {
  width: 0 !important;
  max-width: 0 !important;
  opacity: 0 !important;
  filter: alpha(opacity=0) !important;
}
.sidebar.menu-min .nav-search .nav-search-input:hover,
.sidebar.menu-min .nav-search .nav-search-input:focus,
.sidebar.menu-min .nav-search .nav-search-input:active {
  width: 162px !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar.menu-min .nav-search .nav-search-input:hover ~ #nav-search-icon,
.sidebar.menu-min .nav-search .nav-search-input:focus ~ #nav-search-icon,
.sidebar.menu-min .nav-search .nav-search-input:active ~ #nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.sidebar.menu-min .nav-search:hover .nav-search-input {
  width: 162px !important;
  max-width: 162px !important;
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.sidebar.menu-min .nav-search:hover .nav-search-input ~ .nav-search-icon {
  border: none;
  border-radius: 0;
  padding: 0 3px !important;
}
.sidebar.menu-min .nav-search .nav-search-icon {
  /* inside minimized sidebar */

  border: 1px solid;
  border-radius: 32px;
  background-color: #FFF;
  padding: 0 5px !important;
}
/**
@media only screen and (max-width: 979px) {
  .sidebar.responsive-min {
	.searchbox_inside_min_menu();
  }
}
*/
/* responsive searchbox */
@media only screen and (max-width: 767px) {
  .nav-search {
    right: 5px;
  }
  .nav-search .nav-search-input {
    width: 105px;
  }
  .nav-search:hover .nav-search-input,
  .nav-search .nav-search-btn:active + .nav-search-input,
  .nav-search .nav-search-input:focus,
  .nav-search .nav-search-input:hover,
  .nav-search .nav-search-input:active {
    width: 145px;
  }
}
@media only screen and (max-width: 480px) {
  .nav-search .nav-search-input {
    width: 0;
    opacity: 0;
    filter: alpha(opacity=0);
    max-width: 0;
  }
  .nav-search:hover .nav-search-input,
  .nav-search .nav-search-btn:active + .nav-search-input,
  .nav-search .nav-search-input:focus,
  .nav-search .nav-search-input:hover,
  .nav-search .nav-search-input:active {
    opacity: 1;
    filter: alpha(opacity=100);
    width: 152px;
    max-width: 152px;
  }
  .nav-search .nav-search-icon {
    border: 1px solid;
    border-radius: 100%;
    background-color: #FFF;
    padding: 0 5px !important;
  }
  .nav-search:hover .nav-search-icon,
  .nav-search .nav-search-input:focus ~ .nav-search-icon,
  .nav-search .nav-search-input:hover ~ .nav-search-icon,
  .nav-search .nav-search-input:active ~ .nav-search-icon {
    border: none;
    border-radius: 0;
    padding: 0 3px !important;
  }
}
.sidebar {
  width: 190px;
  float: left;
  position: relative;
  border: 1px solid #cccccc;
  border-width: 0 1px 0 0;
  background-color: #f2f2f2;
}
.sidebar:before {
  /* the grey background of sidebar */

  content: "";
  display: block;
  width: 190px;
  position: fixed;
  bottom: 0;
  top: 0;
  z-index: -1;
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
  border-width: 0 1px 0 0;
}
.sidebar.fixed,
.sidebar.sidebar-fixed {
  position: fixed;
  z-index: 1029;
  top: 45px;
  left: 0;
}
.sidebar.fixed:before,
.sidebar.sidebar-fixed:before {
  left: 0;
  right: auto;
}
/* side navigation */
li [class^="icon-"],
li [class*=" icon-"],
.nav-list li [class^="icon-"],
.nav-list li [class*=" icon-"] {
  width: auto;
}
.nav-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-list .open > a,
.nav-list .open > a:hover,
.nav-list .open > a:focus {
  background-color: #fafafa;
}
.nav-list > li > a,
.nav-list .nav-header {
  margin: 0;
}
.nav-list > li {
  display: block;
  padding: 0;
  margin: 0;
  border: none;
  border-top: 1px solid #FCFCFC;
  border-bottom: 1px solid #E5E5E5;
  position: relative;
}
.nav-list > li:first-child {
  border-top: none;
}
.nav-list li > a:focus {
  outline: none;
}
.nav-list > li {
  /* the submenu indicator arrow */

  /* menu active/open states */

  /* submenu */

}
.nav-list > li > a {
  display: block;
  height: 38px;
  line-height: 36px;
  padding: 0 16px 0 7px;
  background-color: #f9f9f9;
  color: #585858;
  text-shadow: none !important;
  font-size: 13px;
  text-decoration: none;
}
.nav-list > li > a > [class*="icon-"]:first-child {
  display: inline-block;
  vertical-align: middle;
  min-width: 30px;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
  margin-right: 2px;
}
.nav-list > li > a:focus {
  background-color: #f9f9f9;
  color: #1963aa;
}
.nav-list > li > a:hover {
  background-color: #FFF;
  color: #1963aa;
}
.nav-list > li > a:hover:before {
  display: block;
  content: "";
  position: absolute;
  top: -1px;
  bottom: 0;
  left: 0;
  width: 3px;
  max-width: 3px;
  overflow: hidden;
  background-color: #3382af;
}
.nav-list > li a > .arrow {
  display: inline-block;
  width: 14px !important;
  height: 14px;
  line-height: 14px;
  text-shadow: none;
  font-size: 18px;
  position: absolute;
  right: 11px;
  top: 11px;
  padding: 0;
  color: #666666;
}
.nav-list > li a:hover > .arrow,
.nav-list > li.active > a > .arrow,
.nav-list > li.open > a > .arrow {
  color: #1963aa;
}
.nav-list > li.separator {
  height: 3px;
  background-color: transparent;
  position: static;
  margin: 1px 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.nav-list > li.open > a {
  background-color: #fafafa;
  color: #1963aa;
}
.nav-list > li.active {
  background-color: #ffffff;
}
.nav-list > li.active > a,
.nav-list > li.active > a:hover,
.nav-list > li.active > a:focus,
.nav-list > li.active > a:active {
  background-color: #ffffff;
  color: #2b7dbc;
  font-weight: bold;
  font-size: 13px;
}
.nav-list > li.active > a > [class*="icon-"] {
  font-weight: normal;
}
.nav-list > li.active > a:hover:before {
  /* no left side menu item border on active state */

  display: none;
}
.nav-list > li.active:after {
  /* the border on right of active item */

  display: inline-block;
  content: "";
  position: absolute;
  right: -2px;
  top: -1px;
  bottom: 0;
  z-index: 1;
  border: 2px solid #2b7dbc;
  border-width: 0 2px 0 0;
}
.nav-list > li.open {
  border-bottom-color: #e5e5e5;
}
.nav-list > li.active .submenu {
  display: block;
}
.nav-list > li .submenu {
  display: none;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #ffffff;
  border-top: 1px solid #e5e5e5;
}
.nav-list > li .submenu > li {
  margin-left: 0;
  position: relative;
  /* optional icon before each item */

}
.nav-list > li .submenu > li > a {
  display: block;
  position: relative;
  color: #616161;
  padding: 7px 0 9px 37px;
  margin: 0;
  border-top: 1px dotted #e4e4e4;
}
.nav-list > li .submenu > li > a:focus {
  text-decoration: none;
}
.nav-list > li .submenu > li > a:hover {
  text-decoration: none;
  color: #4b88b7;
}
.nav-list > li .submenu > li.active > a {
  color: #2b7dbc;
}
.nav-list > li .submenu > li a > [class*="icon-"]:first-child {
  display: none;
  font-size: 12px;
  font-weight: normal;
  width: 18px;
  height: auto;
  line-height: 12px;
  text-align: center;
  position: absolute;
  left: 10px;
  top: 11px;
  z-index: 1;
  background-color: #FFF;
}
.nav-list > li .submenu > li.active > a > [class*="icon-"]:first-child,
.nav-list > li .submenu > li:hover > a > [class*="icon-"]:first-child {
  display: inline-block;
}
.nav-list > li .submenu > li.active > a > [class*="icon-"]:first-child {
  color: #c86139;
}
.nav-list > li > .submenu > li:before {
  /* the horizontal line */

  content: "";
  display: inline-block;
  position: absolute;
  width: 7px;
  left: 20px;
  top: 17px;
  border-top: 1px dotted #9dbdd6;
}
.nav-list > li > .submenu > li:first-child > a {
  border-top: 1px solid #FAFAFA;
}
.nav-list > li > .submenu:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  left: 18px;
  top: 0;
  bottom: 0;
  border: 1px dotted #9dbdd6;
  border-width: 0 0 0 1px;
}
.nav-list > li.active > .submenu > li:before {
  border-top-color: #8eb3d0;
}
.nav-list > li.active > .submenu:before {
  border-left-color: #8eb3d0;
}
.nav-list li .submenu {
  /* needed for webkit based browsers to slideToggle without problem */

  overflow: hidden;
}
.nav-list li.active > a:after {
  display: block;
  content: "";
  position: absolute !important;
  right: 0;
  top: 4px;
  border: 8px solid transparent;
  border-width: 14px 10px;
  border-right-color: #2b7dbc;
}
.nav-list li.open > a:after {
  /* no caret for open menu item */
  display: none;
}
.nav-list li.active.open > .submenu > li.active.open > a.dropdown-toggle:after {
  /* don't display caret on active open item who is open and has children */

  display: none;
}
.nav-list li.active > .submenu > li.active > a:after {
  /** don't display caret on active item whose parent is not open
	useful for hiding the caret when submenu is sliding up */

  display: none;
}
.nav-list li.active.open > .submenu > li.active > a:after {
  /* display caret on active item whose parent is open */

  display: block;
}
.nav-list li.active.no-active-child > a:after {
  /* show caret for active menu item with childs which is not open(i.e. no submenu item is active) */

  display: inline-block !important;
}
.nav-list a .badge,
.nav-list a .label {
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  position: absolute;
  top: 9px;
  right: 11px;
  opacity: 0.88;
}
.nav-list a .badge [class*="icon-"],
.nav-list a .label [class*="icon-"] {
  vertical-align: middle;
  margin: 0;
}
.nav-list a.dropdown-toggle .badge,
.nav-list a.dropdown-toggle .label {
  right: 28px;
}
.nav-list a:hover .badge,
.nav-list a:hover .label {
  opacity: 1;
}
.nav-list .submenu .submenu a .badge,
.nav-list .submenu .submenu a .label {
  top: 6px;
}
/* side menu minimizer icon */
.sidebar-collapse {
  border-bottom: 1px solid #e0e0e0;
  background-color: #f3f3f3;
  text-align: center;
  padding: 3px 0;
  position: relative;
}
.sidebar-collapse > [class*="icon-"] {
  display: inline-block;
  cursor: pointer;
  font-size: 14px;
  color: #aaaaaa;
  border: 1px solid #bbbbbb;
  padding: 0 5px;
  line-height: 18px;
  border-radius: 16px;
  background-color: #ffffff;
  position: relative;
}
.sidebar-collapse:before {
  content: "";
  display: inline-block;
  height: 0;
  border-top: 1px solid #e0e0e0;
  position: absolute;
  left: 15px;
  right: 15px;
  top: 13px;
}
/* sidebar shortcuts icon */
.sidebar-shortcuts {
  background-color: #fafafa;
  border-bottom: 1px solid #dddddd;
  text-align: center;
  line-height: 39px;
  max-height: 41px;
  margin-bottom: 0;
}
.sidebar-shortcuts-large {
  padding-bottom: 4px;
}
.sidebar-shortcuts-large > .btn {
  width: 41px;
  line-height: 24px;
  margin-top: -2px;
  padding: 0;
  border-width: 4px;
  text-align: center;
}
.sidebar-shortcuts-large > .btn > [class*="icon-"] {
  margin: 0;
}
.sidebar-shortcuts-mini {
  display: none;
  font-size: 0;
  width: 42px;
  line-height: 18px;
  padding-top: 2px;
  padding-bottom: 2px;
  background-color: #ffffff;
}
.sidebar-shortcuts-mini > .btn {
  border-width: 0 !important;
  font-size: 0;
  line-height: 0;
  padding: 8px !important;
  margin: 0 1px;
  border-radius: 0 !important;
  opacity: 0.85;
  filter: alpha(opacity=85);
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::safari-only,
  .sidebar-shortcuts-mini > .btn {
    margin: 0;
  }
}
.nav-list > li > .submenu li > .submenu {
  border-top: none;
  background-color: transparent;
  display: none;
}
.nav-list > li > .submenu li.active > .submenu {
  display: block;
}
.nav-list > li > .submenu a > .arrow {
  right: 11px;
  top: 10px;
  font-size: 16px;
  color: #6B828E;
}
.nav-list > li > .submenu .open > a,
.nav-list > li > .submenu .open > a:hover,
.nav-list > li > .submenu .open > a:focus {
  background-color: transparent;
  border-color: #e4e4e4;
}
.nav-list > li > .submenu li > .submenu > li > a > .arrow {
  right: 12px;
  top: 9px;
}
.nav-list > li > .submenu li > .submenu > li {
  line-height: 16px;
}
.nav-list > li > .submenu li > .submenu > li:before {
  display: none;
}
.nav-list > li > .submenu li > .submenu > li > a {
  /*3rd level*/

  margin-left: 20px;
  padding-left: 22px;
}
.nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
  /*4th level*/

  margin-left: 20px;
  padding-left: 38px;
}
.nav-list > li > .submenu li > .submenu > li a > [class*="icon-"]:first-child {
  display: inline-block;
  color: inherit;
  font-size: 14px;
  position: static;
  background-color: transparent;
  margin-right: 1px;
}
.nav-list > li > .submenu li > .submenu > li a {
  font-size: 13px;
  color: #777;
}
.nav-list > li > .submenu li > .submenu > li a:hover {
  color: #316292;
  text-decoration: underline;
}
.nav-list > li > .submenu li > .submenu > li a:hover [class*="icon-"] {
  text-decoration: none;
  color: #316292;
}
.nav-list > li > .submenu li.open > a {
  color: #25639e;
}
.nav-list > li > .submenu li.open > a > [class*="icon-"]:first-child {
  display: inline-block;
}
.nav-list > li > .submenu li.open > a .arrow {
  color: #25639e;
}
.nav-list > li > .submenu li > .submenu li.open > a {
  color: #25639e;
}
.nav-list > li > .submenu li > .submenu li.open > a > [class*="icon-"]:first-child {
  display: inline-block;
  color: #1963aa;
}
.nav-list > li > .submenu li > .submenu li.open > a .arrow {
  color: #25639e;
}
.nav-list > li > .submenu li > .submenu li.active > a {
  color: #347cb3;
}
.nav-list > li > .submenu li > .submenu li.active > a > [class*="icon-"]:first-child {
  display: inline-block;
  color: #347cb3;
}
.nav-list > li.active.open li.active > a:after {
  top: 2px;
  border-width: 14px 8px;
}
.nav-list > li.active.open li.active.open li.active > a:after {
  top: 0;
}
.menu-min.sidebar {
  width: 43px;
}
.menu-min.sidebar:before {
  width: 43px;
}
.menu-min.sidebar + .main-content {
  margin-left: 43px;
}
.menu-min.sidebar + .main-content .breadcrumbs.fixed,
.menu-min.sidebar + .main-content .breadcrumbs.breadcrumbs-fixed {
  left: 43px;
}
.menu-min .nav-list a .badge,
.menu-min .nav-list a .label {
  position: relative;
  top: -1px;
  right: auto;
  left: 4px;
}
.menu-min .nav-list .submenu .submenu a .badge,
.menu-min .nav-list .submenu .submenu a .label {
  top: 0;
}
.menu-min .nav-list > li > a {
  position: relative;
}
.menu-min .nav-list > li > a > .menu-text {
  display: none;
  position: absolute;
  left: 42px;
  top: -2px;
  width: 174px;
  height: 40px;
  line-height: 38px;
  background-color: #f5f5f5;
  z-index: 121;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  border: 1px solid #cccccc;
  padding-left: 12px;
}
.menu-min .nav-list > li > a.dropdown-toggle > .menu-text {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: none;
  top: -1px;
  left: 43px;
  width: 174px;
  border-bottom: 1px solid #dddddd;
}
.menu-min .nav-list > li > a .arrow {
  display: none;
}
.menu-min .nav-list > li > a:hover:before {
  /* the right side border on hover */

  width: 2px;
}
.menu-min .nav-list > li:hover > a {
  color: #1963aa;
}
.menu-min .nav-list > li:hover > a > .menu-text {
  display: block;
}
.menu-min .nav-list > li.active > a > .menu-text {
  border-left-color: #1963aa;
}
.menu-min .nav-list > li.open > a {
  background-color: #fafafa;
  color: #585858;
}
.menu-min .nav-list > li.open.active > a {
  background-color: #ffffff;
}
.menu-min .nav-list > li.open:hover > a {
  color: #1963aa;
}
.menu-min .nav-list > li.active > a {
  color: #2b7dbc;
}
.menu-min .nav-list > li.active > a:after {
  /* the caret */

  border-width: 10px 6px;
  top: 8px;
}
.menu-min .nav-list > li.active.open > a:after {
  display: block;
}
.menu-min .nav-list > li.active.open li.active > a:after {
  display: none;
}
.menu-min .nav-list > li > .submenu {
  background: #ffffff;
  position: absolute;
  z-index: 120;
  left: 42px;
  top: -2px;
  width: 176px;
  border: 1px solid #cccccc;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  padding-top: 40px;
  padding-bottom: 2px;
  display: none !important;
}
.menu-min .nav-list > li > .submenu:before {
  /* hide the tree like submenu in minimized mode */

  display: none;
}
.menu-min .nav-list > li > .submenu li:before {
  display: none;
}
.menu-min .nav-list > li > .submenu li > a {
  margin-left: 0;
  padding-left: 24px;
}
.menu-min .nav-list > li > .submenu li > a > [class*="icon-"]:first-child {
  left: 4px;
}
.menu-min .nav-list > li:hover > .submenu {
  display: block !important;
}
.menu-min .nav-list > li.active > .submenu {
  border-left-color: #2b7dbc;
}
.menu-min .sidebar-shortcuts {
  position: relative;
}
.menu-min .sidebar-shortcuts-mini {
  display: block;
}
.menu-min .sidebar-shortcuts-large {
  display: none;
  position: absolute;
  z-index: 20;
  top: -1px;
  left: 42px;
  width: 182px;
  padding: 0 2px 1px;
  background-color: #ffffff;
  -webkit-box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  box-shadow: 2px 1px 2px 0 rgba(0,0,0,0.2);
  border: 1px solid #cccccc;
}
.menu-min .sidebar-shortcuts:hover .sidebar-shortcuts-large {
  display: block;
}
.menu-min .sidebar-collapse {
  /* minimized collapse button */

}
.menu-min .sidebar-collapse:before {
  left: 5px;
  right: 5px;
}
.menu-min .sidebar-collapse > [class*="icon-"] {
  font-size: 13px;
  padding: 0 4px;
  line-height: 15px;
  border-width: 1px;
  border-color: #aeaeae;
}
.menu-min .nav-list > li > .submenu li > .submenu > li > a {
  /*3rd level*/

  margin-left: 0px;
  padding-left: 30px;
}
.menu-min .nav-list > li > .submenu li > .submenu > li > .submenu > li > a {
  /*4th level*/

  margin-left: 0px;
  padding-left: 45px;
}
.menu-min .nav-list > li > .submenu li.active > a:after {
  display: none;
}
.menu-min .nav-list li.active.open > .submenu > li.active > a:after {
  display: none;
}
/* side menu toggler in mobile view */
.menu-toggler {
  display: none;
}
/* responsive sidebar */
@media only screen and (max-width: 991px) {
  .sidebar:before {
    display: none;
  }
  .sidebar {
    display: none;
    float: none;
    position: absolute;
    z-index: 999;
    left: 0;
    bottom: auto;
    top: auto !important;
    /*so that it's applied even if it's .sidebar-fixed */
  
    margin-top: 40px;
    border: 1px solid #CCC;
    border-left-width: 0;
    box-shadow: 2px 1px 2px 0 rgba(0, 0, 0, 0.2);
  }
  .sidebar.display {
    display: block;
  }
  .menu-toggler {
    display: inline-block;
    position: absolute;
    left: 0;
    z-index: 998;
    width: 52px;
    height: 32px;
    margin-right: 2px;
    line-height: normal;
    padding-left: 33px;
    padding-top: 7px;
    padding-bottom: 1px;
    font-size: 13px;
    font-weight: bold;
    text-transform: uppercase;
    background-color: #444;
    color: #F3F3F3;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .menu-toggler:hover {
    text-decoration: none;
  }
  .menu-toggler:before {
    border-top: 1px solid #87B87F;
    border-bottom: 1px solid #6FB3E0;
    height: 2px;
    width: 24px;
    content: "";
    position: absolute;
    z-index: 11;
    top: 13px;
    left: 4px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .menu-toggler:after {
    border-top: 1px solid #FFA24D;
    border-bottom: 1px solid #D15B47;
    content: "";
    height: 2px;
    width: 24px;
    position: absolute;
    top: 19px;
    left: 4px;
    -webkit-transition: all 0.1s ease;
    transition: all 0.1s ease;
    -o-transition: none;
    -webkit-box-sizing: content-box;
    -moz-box-sizing: content-box;
    box-sizing: content-box;
  }
  .menu-toggler.display:before {
    height: 4px;
    top: 8px;
    border-width: 2px;
  }
  .menu-toggler.display:after {
    height: 4px;
    top: 20px;
    border-width: 2px;
  }
  .menu-toggler > .menu-text {
    display: block;
    position: absolute;
    bottom: -18px;
    left: 0;
    border: 1px solid transparent;
    border-width: 9px 42px;
    border-top-color: #444;
  }
  .menu-toggler > .menu-text:after {
    display: block;
    color: #FFF;
    content: "MENU";
    position: absolute;
    left: -8px;
    top: -41px;
  }
  .nav-list > li.active:after {
    display: none;
  }
  .nav-list li.active > a:after {
    display: none;
  }
  .nav-list li.active.open > .submenu > li.active > a:after {
    display: none;
  }
  .menu-min .nav-list > li.active:after {
    display: block;
  }
  .menu-min .nav-list > li.active > a:after {
    display: block;
  }
}
@media only screen and (max-width: 320px) {
  .menu-toggler {
    width: 0;
  }
  .menu-toggler > .menu-text {
    border-width: 7px 16px;
    bottom: -14px;
  }
  .menu-toggler > .menu-text:after {
    font-size: 9px;
    font-weight: normal;
    color: #FFF;
    position: absolute;
    left: -13px;
    top: -42px;
  }
  .menu-toggler:before,
  .menu-toggler:after {
    margin-top: 8px;
  }
  .menu-toggler.display:before,
  .menu-toggler.display:after {
    height: 2px;
    border-width: 1px;
  }
  .menu-toggler.display:before {
    top: 13px;
  }
  .menu-toggler.display:after {
    top: 19px;
  }
}
/* not used yet */
/**
@media only screen and (max-width: 979px) {
 .sidebar.responsive-min {
   display:block;
   float:none;
   position:absolute;
   border-width:0 1px 1px 0;
  
   border-left:none;
   box-shadow:none;
   margin-top:0;
   z-index:14;
 }

 .sidebar.responsive-min.menu-min {
	border-bottom:none;
 }
 .sidebar.responsive-min.menu-min:before {
   display:block;
 }

.sidebar.responsive-min + .main-content {
	margin-left:43px !important;
 }
 .sidebar.responsive-min + .main-content .breadcrumbs.breadcrumbs-fixed { left:43px; }
 .sidebar.responsive-min + .main-content .breadcrumbs .breadcrumb {margin-left:10px;}

 .menu-toggler.responsive-min {
	display:none !important;
 }
}
*/
/** buttons */
.btn {
  display: inline-block;
  color: #FFF !important;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25) !important;
  background-image: none !important;
  border: 5px solid #FFF;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-transition: all ease .15s;
  transition: all ease .15s;
  cursor: pointer;
  vertical-align: middle;
  margin: 0;
  position: relative;
}
.btn-lg {
  border-width: 5px;
  line-height: 1.35;
  padding: 7px 16px;
}
.btn-sm {
  border-width: 4px;
  font-size: 13px;
  padding: 4px 9px;
  line-height: 1.39;
}
.btn-xs {
  border-width: 3px;
}
.btn-minier {
  padding: 0 4px;
  line-height: 18px;
  border-width: 2px;
  font-size: 12px;
}
button.btn:active {
  top: 1px;
  left: 1px;
}
.btn,
.btn-default,
.btn:focus,
.btn-default:focus {
  background-color: #abbac3 !important;
  border-color: #abbac3;
}
.btn:hover,
.btn-default:hover,
.open .btn.dropdown-toggle,
.open .btn-default.dropdown-toggle {
  background-color: #8b9aa3 !important;
  border-color: #abbac3;
}
.btn.no-border:hover,
.btn-default.no-border:hover {
  border-color: #8b9aa3;
}
.btn.no-hover:hover,
.btn-default.no-hover:hover {
  background-color: #abbac3 !important;
}
.btn.active,
.btn-default.active {
  background-color: #9baab3 !important;
  border-color: #8799a4;
}
.btn.no-border.active,
.btn-default.no-border.active {
  background-color: #92a3ac !important;
  border-color: #92a3ac;
}
.btn.disabled,
.btn-default.disabled,
.btn[disabled],
.btn-default[disabled],
fieldset[disabled] .btn,
fieldset[disabled] .btn-default,
.btn.disabled:hover,
.btn-default.disabled:hover,
.btn[disabled]:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn:hover,
fieldset[disabled] .btn-default:hover,
.btn.disabled:focus,
.btn-default.disabled:focus,
.btn[disabled]:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn:focus,
fieldset[disabled] .btn-default:focus,
.btn.disabled:active,
.btn-default.disabled:active,
.btn[disabled]:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn:active,
fieldset[disabled] .btn-default:active,
.btn.disabled.active,
.btn-default.disabled.active,
.btn[disabled].active,
.btn-default[disabled].active,
fieldset[disabled] .btn.active,
fieldset[disabled] .btn-default.active {
  background-color: #abbac3 !important;
  border-color: #abbac3;
}
.btn-primary,
.btn-primary:focus {
  background-color: #428bca !important;
  border-color: #428bca;
}
.btn-primary:hover,
.open .btn-primary.dropdown-toggle {
  background-color: #1b6aaa !important;
  border-color: #428bca;
}
.btn-primary.no-border:hover {
  border-color: #1b6aaa;
}
.btn-primary.no-hover:hover {
  background-color: #428bca !important;
}
.btn-primary.active {
  background-color: #2f7bba !important;
  border-color: #27689d;
}
.btn-primary.no-border.active {
  background-color: #2b72ae !important;
  border-color: #2b72ae;
}
.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  background-color: #428bca !important;
  border-color: #428bca;
}
.btn-info,
.btn-info:focus {
  background-color: #6fb3e0 !important;
  border-color: #6fb3e0;
}
.btn-info:hover,
.open .btn-info.dropdown-toggle {
  background-color: #4f99c6 !important;
  border-color: #6fb3e0;
}
.btn-info.no-border:hover {
  border-color: #4f99c6;
}
.btn-info.no-hover:hover {
  background-color: #6fb3e0 !important;
}
.btn-info.active {
  background-color: #5fa6d3 !important;
  border-color: #4396cb;
}
.btn-info.no-border.active {
  background-color: #539fd0 !important;
  border-color: #539fd0;
}
.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #6fb3e0 !important;
  border-color: #6fb3e0;
}
.btn-success,
.btn-success:focus {
  background-color: #87b87f !important;
  border-color: #87b87f;
}
.btn-success:hover,
.open .btn-success.dropdown-toggle {
  background-color: #629b58 !important;
  border-color: #87b87f;
}
.btn-success.no-border:hover {
  border-color: #629b58;
}
.btn-success.no-hover:hover {
  background-color: #87b87f !important;
}
.btn-success.active {
  background-color: #75aa6c !important;
  border-color: #629959;
}
.btn-success.no-border.active {
  background-color: #6ba462 !important;
  border-color: #6ba462;
}
.btn-success.disabled,
.btn-success[disabled],
fieldset[disabled] .btn-success,
.btn-success.disabled:hover,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success:hover,
.btn-success.disabled:focus,
.btn-success[disabled]:focus,
fieldset[disabled] .btn-success:focus,
.btn-success.disabled:active,
.btn-success[disabled]:active,
fieldset[disabled] .btn-success:active,
.btn-success.disabled.active,
.btn-success[disabled].active,
fieldset[disabled] .btn-success.active {
  background-color: #87b87f !important;
  border-color: #87b87f;
}
.btn-warning,
.btn-warning:focus {
  background-color: #ffb752 !important;
  border-color: #ffb752;
}
.btn-warning:hover,
.open .btn-warning.dropdown-toggle {
  background-color: #e59729 !important;
  border-color: #ffb752;
}
.btn-warning.no-border:hover {
  border-color: #e59729;
}
.btn-warning.no-hover:hover {
  background-color: #ffb752 !important;
}
.btn-warning.active {
  background-color: #f2a73e !important;
  border-color: #f0981c;
}
.btn-warning.no-border.active {
  background-color: #f1a02f !important;
  border-color: #f1a02f;
}
.btn-warning.disabled,
.btn-warning[disabled],
fieldset[disabled] .btn-warning,
.btn-warning.disabled:hover,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning:hover,
.btn-warning.disabled:focus,
.btn-warning[disabled]:focus,
fieldset[disabled] .btn-warning:focus,
.btn-warning.disabled:active,
.btn-warning[disabled]:active,
fieldset[disabled] .btn-warning:active,
.btn-warning.disabled.active,
.btn-warning[disabled].active,
fieldset[disabled] .btn-warning.active {
  background-color: #ffb752 !important;
  border-color: #ffb752;
}
.btn-danger,
.btn-danger:focus {
  background-color: #d15b47 !important;
  border-color: #d15b47;
}
.btn-danger:hover,
.open .btn-danger.dropdown-toggle {
  background-color: #b74635 !important;
  border-color: #d15b47;
}
.btn-danger.no-border:hover {
  border-color: #b74635;
}
.btn-danger.no-hover:hover {
  background-color: #d15b47 !important;
}
.btn-danger.active {
  background-color: #c4513e !important;
  border-color: #aa4434;
}
.btn-danger.no-border.active {
  background-color: #ba4b39 !important;
  border-color: #ba4b39;
}
.btn-danger.disabled,
.btn-danger[disabled],
fieldset[disabled] .btn-danger,
.btn-danger.disabled:hover,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger:hover,
.btn-danger.disabled:focus,
.btn-danger[disabled]:focus,
fieldset[disabled] .btn-danger:focus,
.btn-danger.disabled:active,
.btn-danger[disabled]:active,
fieldset[disabled] .btn-danger:active,
.btn-danger.disabled.active,
.btn-danger[disabled].active,
fieldset[disabled] .btn-danger.active {
  background-color: #d15b47 !important;
  border-color: #d15b47;
}
.btn-inverse,
.btn-inverse:focus {
  background-color: #555555 !important;
  border-color: #555555;
}
.btn-inverse:hover,
.open .btn-inverse.dropdown-toggle {
  background-color: #303030 !important;
  border-color: #555555;
}
.btn-inverse.no-border:hover {
  border-color: #303030;
}
.btn-inverse.no-hover:hover {
  background-color: #555555 !important;
}
.btn-inverse.active {
  background-color: #434343 !important;
  border-color: #313131;
}
.btn-inverse.no-border.active {
  background-color: #3b3b3b !important;
  border-color: #3b3b3b;
}
.btn-inverse.disabled,
.btn-inverse[disabled],
fieldset[disabled] .btn-inverse,
.btn-inverse.disabled:hover,
.btn-inverse[disabled]:hover,
fieldset[disabled] .btn-inverse:hover,
.btn-inverse.disabled:focus,
.btn-inverse[disabled]:focus,
fieldset[disabled] .btn-inverse:focus,
.btn-inverse.disabled:active,
.btn-inverse[disabled]:active,
fieldset[disabled] .btn-inverse:active,
.btn-inverse.disabled.active,
.btn-inverse[disabled].active,
fieldset[disabled] .btn-inverse.active {
  background-color: #555555 !important;
  border-color: #555555;
}
.btn-pink,
.btn-pink:focus {
  background-color: #d6487e !important;
  border-color: #d6487e;
}
.btn-pink:hover,
.open .btn-pink.dropdown-toggle {
  background-color: #b73766 !important;
  border-color: #d6487e;
}
.btn-pink.no-border:hover {
  border-color: #b73766;
}
.btn-pink.no-hover:hover {
  background-color: #d6487e !important;
}
.btn-pink.active {
  background-color: #c74072 !important;
  border-color: #af3462;
}
.btn-pink.no-border.active {
  background-color: #be386a !important;
  border-color: #be386a;
}
.btn-pink.disabled,
.btn-pink[disabled],
fieldset[disabled] .btn-pink,
.btn-pink.disabled:hover,
.btn-pink[disabled]:hover,
fieldset[disabled] .btn-pink:hover,
.btn-pink.disabled:focus,
.btn-pink[disabled]:focus,
fieldset[disabled] .btn-pink:focus,
.btn-pink.disabled:active,
.btn-pink[disabled]:active,
fieldset[disabled] .btn-pink:active,
.btn-pink.disabled.active,
.btn-pink[disabled].active,
fieldset[disabled] .btn-pink.active {
  background-color: #d6487e !important;
  border-color: #d6487e;
}
.btn-purple,
.btn-purple:focus {
  background-color: #9585bf !important;
  border-color: #9585bf;
}
.btn-purple:hover,
.open .btn-purple.dropdown-toggle {
  background-color: #7461aa !important;
  border-color: #9585bf;
}
.btn-purple.no-border:hover {
  border-color: #7461aa;
}
.btn-purple.no-hover:hover {
  background-color: #9585bf !important;
}
.btn-purple.active {
  background-color: #8573b5 !important;
  border-color: #705ca8;
}
.btn-purple.no-border.active {
  background-color: #7c69af !important;
  border-color: #7c69af;
}
.btn-purple.disabled,
.btn-purple[disabled],
fieldset[disabled] .btn-purple,
.btn-purple.disabled:hover,
.btn-purple[disabled]:hover,
fieldset[disabled] .btn-purple:hover,
.btn-purple.disabled:focus,
.btn-purple[disabled]:focus,
fieldset[disabled] .btn-purple:focus,
.btn-purple.disabled:active,
.btn-purple[disabled]:active,
fieldset[disabled] .btn-purple:active,
.btn-purple.disabled.active,
.btn-purple[disabled].active,
fieldset[disabled] .btn-purple.active {
  background-color: #9585bf !important;
  border-color: #9585bf;
}
.btn-grey,
.btn-grey:focus {
  background-color: #a0a0a0 !important;
  border-color: #a0a0a0;
}
.btn-grey:hover,
.open .btn-grey.dropdown-toggle {
  background-color: #888888 !important;
  border-color: #a0a0a0;
}
.btn-grey.no-border:hover {
  border-color: #888888;
}
.btn-grey.no-hover:hover {
  background-color: #a0a0a0 !important;
}
.btn-grey.active {
  background-color: #949494 !important;
  border-color: #828282;
}
.btn-grey.no-border.active {
  background-color: #8c8c8c !important;
  border-color: #8c8c8c;
}
.btn-grey.disabled,
.btn-grey[disabled],
fieldset[disabled] .btn-grey,
.btn-grey.disabled:hover,
.btn-grey[disabled]:hover,
fieldset[disabled] .btn-grey:hover,
.btn-grey.disabled:focus,
.btn-grey[disabled]:focus,
fieldset[disabled] .btn-grey:focus,
.btn-grey.disabled:active,
.btn-grey[disabled]:active,
fieldset[disabled] .btn-grey:active,
.btn-grey.disabled.active,
.btn-grey[disabled].active,
fieldset[disabled] .btn-grey.active {
  background-color: #a0a0a0 !important;
  border-color: #a0a0a0;
}
.btn-yellow {
  color: #996633 !important;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
}
.btn-yellow,
.btn-yellow:focus {
  background-color: #fee188 !important;
  border-color: #fee188;
}
.btn-yellow:hover,
.open .btn-yellow.dropdown-toggle {
  background-color: #f7d05b !important;
  border-color: #fee188;
}
.btn-yellow.no-border:hover {
  border-color: #f7d05b;
}
.btn-yellow.no-hover:hover {
  background-color: #fee188 !important;
}
.btn-yellow.active {
  background-color: #fbd972 !important;
  border-color: #f9cf4f;
}
.btn-yellow.no-border.active {
  background-color: #fad463 !important;
  border-color: #fad463;
}
.btn-yellow.disabled,
.btn-yellow[disabled],
fieldset[disabled] .btn-yellow,
.btn-yellow.disabled:hover,
.btn-yellow[disabled]:hover,
fieldset[disabled] .btn-yellow:hover,
.btn-yellow.disabled:focus,
.btn-yellow[disabled]:focus,
fieldset[disabled] .btn-yellow:focus,
.btn-yellow.disabled:active,
.btn-yellow[disabled]:active,
fieldset[disabled] .btn-yellow:active,
.btn-yellow.disabled.active,
.btn-yellow[disabled].active,
fieldset[disabled] .btn-yellow.active {
  background-color: #fee188 !important;
  border-color: #fee188;
}
.btn-light {
  color: #888888 !important;
  text-shadow: 0 -1px 0 rgba(250, 250, 250, 0.25) !important;
}
.btn-light,
.btn-light:focus {
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7;
}
.btn-light:hover,
.open .btn-light.dropdown-toggle {
  background-color: #d9d9d9 !important;
  border-color: #e7e7e7;
}
.btn-light.no-border:hover {
  border-color: #d9d9d9;
}
.btn-light.no-hover:hover {
  background-color: #e7e7e7 !important;
}
.btn-light.active {
  background-color: #e0e0e0 !important;
  border-color: #cecece;
}
.btn-light.no-border.active {
  background-color: #d8d8d8 !important;
  border-color: #d8d8d8;
}
.btn-light.disabled,
.btn-light[disabled],
fieldset[disabled] .btn-light,
.btn-light.disabled:hover,
.btn-light[disabled]:hover,
fieldset[disabled] .btn-light:hover,
.btn-light.disabled:focus,
.btn-light[disabled]:focus,
fieldset[disabled] .btn-light:focus,
.btn-light.disabled:active,
.btn-light[disabled]:active,
fieldset[disabled] .btn-light:active,
.btn-light.disabled.active,
.btn-light[disabled].active,
fieldset[disabled] .btn-light.active {
  background-color: #e7e7e7 !important;
  border-color: #e7e7e7;
}
.btn-light.btn-xs:after {
  left: -2px;
  right: -2px;
  top: -2px;
  bottom: -2px;
}
.btn-light.btn-sm:after {
  left: -4px;
  right: -4px;
  top: -4px;
  bottom: -4px;
}
.btn-light.btn-large:after {
  left: -6px;
  right: -6px;
  top: -6px;
  bottom: -6px;
}
.btn.btn-white {
  border-width: 1px;
  border-color: #CCC;
  color: #444 !important;
  text-shadow: none !important;
  background-color: #FFF !important;
}
.btn.btn-white:hover,
.btn.btn-white:focus {
  background-color: #EBEBEB !important;
}
.btn.btn-white.no-border {
  border-color: #CCC;
}
.btn.disabled.active,
.btn[disabled].active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:active,
.btn[disabled]:active {
  outline: none;
}
.btn.disabled:active,
.btn[disabled]:active {
  top: 0;
  left: 0;
}
/* active buttons */
.btn.active {
  color: #efe5b5;
}
.btn.active:after {
  display: inline-block;
  content: "";
  position: absolute;
  border-bottom: 1px solid #efe5b5;
  left: -4px;
  right: -4px;
  bottom: -4px;
}
.btn.active.btn-sm:after {
  left: -3px;
  right: -3px;
  bottom: -3px;
}
.btn.active.btn-large:after {
  left: -5px;
  right: -5px;
  bottom: -5px;
}
.btn.active.btn-xs:after,
.btn.active.btn-minier:after {
  left: -1px;
  right: -1px;
  bottom: -2px;
}
.btn.active.btn-minier:after {
  bottom: -1px;
}
.btn.active.btn-yellow:after {
  border-bottom-color: #c96338;
}
.btn.active.btn-light {
  color: #515151;
}
.btn.active.btn-light:after {
  border-bottom-color: #B5B5B5;
}
/* icons inside buttons */
.btn > [class*="icon-"] {
  display: inline;
  margin-right: 4px;
}
.btn > [class*="icon-"].icon-on-right {
  margin-right: 0;
  margin-left: 4px;
}
.btn > .icon-only[class*="icon-"] {
  margin: 0;
  vertical-align: middle;
  text-align: center;
  padding: 0;
}
.btn-large > [class*="icon-"] {
  margin-right: 6px;
}
.btn-large > [class*="icon-"].icon-on-right {
  margin-right: 0;
  margin-left: 6px;
}
.btn-sm > [class*="icon-"] {
  margin-right: 3px;
}
.btn-sm > [class*="icon-"].icon-on-right {
  margin-right: 0;
  margin-left: 3px;
}
.btn-xs > [class*="icon-"],
.btn-minier > [class*="icon-"] {
  margin-right: 2px;
}
.btn-xs > [class*="icon-"].icon-on-right,
.btn-minier > [class*="icon-"].icon-on-right {
  margin-right: 0;
  margin-left: 2px;
}
.btn.btn-link {
  border: none !important;
  background: transparent none !important;
  color: #0088cc !important;
  text-shadow: none !important;
  padding: 4px 12px !important;
  line-height: 20px !important;
}
.btn.btn-link:hover {
  background: none !important;
  text-shadow: none !important;
}
.btn.btn-link.active {
  background: none !important;
  text-decoration: underline;
  color: #009ceb !important;
}
.btn.btn-link.active:after {
  display: none;
}
.btn.btn-link.disabled,
.btn.btn-link[disabled] {
  background: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
}
.btn.btn-link.disabled:hover,
.btn.btn-link[disabled]:hover {
  background: none !important;
  text-decoration: none !important;
}
.btn.btn-no-border {
  border-width: 0 !important;
}
/* button groups */
.btn-group > .btn {
  /* caret inside buttons */

  /* dropdown toggle */

}
.btn-group > .btn,
.btn-group > .btn + .btn {
  margin: 0 1px 0 0;
}
.btn-group > .btn:first-child {
  margin: 0 1px 0 0;
}
.btn-group > .btn:first-child,
.btn-group > .btn:last-child {
  border-radius: 0;
}
.btn-group > .btn > .caret {
  margin-top: 15px;
  margin-left: 1px;
  border-width: 5px;
  border-top-color: #FFF;
}
.btn-group > .btn.btn-sm > .caret {
  margin-top: 10px;
  border-width: 4px;
}
.btn-group > .btn.btn-large > .caret {
  margin-top: 18px;
  border-width: 6px;
}
.btn-group > .btn.btn-xs > .caret {
  margin-top: 9px;
  border-width: 4px;
}
.btn-group > .btn.btn-minier > .caret {
  margin-top: 7px;
  border-width: 3px;
}
.btn-group > .btn + .btn.dropdown-toggle {
  padding-right: 3px;
  padding-left: 3px;
}
.btn-group > .btn + .btn-large.dropdown-toggle {
  padding-right: 4px;
  padding-left: 4px;
}
.btn-group .dropdown-toggle {
  border-radius: 0;
}
.btn-group > .btn,
.btn-group + .btn {
  margin: 0 1px 0 0;
  border-width: 3px;
  /* the border under an active button in button groups */

}
.btn-group > .btn.active:after,
.btn-group + .btn.active:after {
  left: -2px;
  right: -2px;
  bottom: -2px;
  border-bottom-width: 1px;
}
.btn-group > .btn-large,
.btn-group + .btn-large {
  border-width: 4px;
  /* the border under an active button in button groups */

}
.btn-group > .btn-large.active:after,
.btn-group + .btn-large.active:after {
  left: -3px;
  right: -3px;
  bottom: -3px;
  border-bottom-width: 1px;
}
.btn-group > .btn-sm,
.btn-group + .btn-sm {
  border-width: 2px;
  /* the border under an active button in button groups */

}
.btn-group > .btn-sm.active:after,
.btn-group + .btn-sm.active:after {
  left: -1px;
  right: -1px;
  bottom: -1px;
  border-bottom-width: 1px;
}
.btn-group > .btn-xs,
.btn-group + .btn-xs {
  border-width: 1px;
  /* the border under an active button in button groups */

}
.btn-group > .btn-xs.active:after,
.btn-group + .btn-xs.active:after {
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom-width: 1px;
}
.btn-group > .btn-minier,
.btn-group + .btn-minier {
  border-width: 1px;
  /* the border under an active button in button groups */

}
.btn-group > .btn-minier.active:after,
.btn-group + .btn-minier.active:after {
  left: 0px;
  right: 0px;
  bottom: 0px;
  border-bottom-width: 1px;
}
.btn-group > .btn {
  border-radius: 0 !important;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn + .btn {
  margin: 1px 0 0;
}
.btn-group-vertical > .btn:first-child {
  margin-right: 0;
}
/* application buttons */
.btn.btn-app {
  display: inline-block;
  width: 100px;
  font-size: 18px;
  font-weight: normal;
  color: #FFF;
  text-align: center;
  text-shadow: 0 -1px -1px rgba(0, 0, 0, 0.2) !important;
  border: none;
  border-radius: 12px;
  padding: 12px 0 8px;
  margin: 2px;
  line-height: 1.7;
  position: relative;
}
.btn-app,
.btn-app.btn-default,
.btn-app.no-hover:hover,
.btn-app.btn-default.no-hover:hover,
.btn-app.disabled:hover,
.btn-app.btn-default.disabled:hover {
  background: #b4c2cc !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#bcc9d5), to(#abbac3)) !important;
  background-image: -webkit-linear-gradient(top, #bcc9d5, 0%, #abbac3, 100%) !important;
  background-image: -moz-linear-gradient(top, #bcc9d5 0%, #abbac3 100%) !important;
  background-image: linear-gradient(to bottom, #bcc9d5 0%, #abbac3 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffbcc9d5', endColorstr='#ffabbac3', GradientType=0) !important;
}
.btn-app:hover,
.btn-app.btn-default:hover {
  background: #9baebc !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#a3b5c5), to(#93a6b2)) !important;
  background-image: -webkit-linear-gradient(top, #a3b5c5, 0%, #93a6b2, 100%) !important;
  background-image: -moz-linear-gradient(top, #a3b5c5 0%, #93a6b2 100%) !important;
  background-image: linear-gradient(to bottom, #a3b5c5 0%, #93a6b2 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa3b5c5', endColorstr='#ff93a6b2', GradientType=0) !important;
}
.btn-app.btn-primary,
.btn-app.btn-primary.no-hover:hover,
.btn-app.btn-primary.disabled:hover {
  background: #2a8bcc !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#3b98d6), to(#197ec1)) !important;
  background-image: -webkit-linear-gradient(top, #3b98d6, 0%, #197ec1, 100%) !important;
  background-image: -moz-linear-gradient(top, #3b98d6 0%, #197ec1 100%) !important;
  background-image: linear-gradient(to bottom, #3b98d6 0%, #197ec1 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3b98d6', endColorstr='#ff197ec1', GradientType=0) !important;
}
.btn-app.btn-primary:hover {
  background: #1d6fa6 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#267eb8), to(#136194)) !important;
  background-image: -webkit-linear-gradient(top, #267eb8, 0%, #136194, 100%) !important;
  background-image: -moz-linear-gradient(top, #267eb8 0%, #136194 100%) !important;
  background-image: linear-gradient(to bottom, #267eb8 0%, #136194 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff267eb8', endColorstr='#ff136194', GradientType=0) !important;
}
.btn-app.btn-info,
.btn-app.btn-info.no-hover:hover,
.btn-app.btn-info.disabled:hover {
  background: #68adde !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#75b5e6), to(#5ba4d5)) !important;
  background-image: -webkit-linear-gradient(top, #75b5e6, 0%, #5ba4d5, 100%) !important;
  background-image: -moz-linear-gradient(top, #75b5e6 0%, #5ba4d5 100%) !important;
  background-image: linear-gradient(to bottom, #75b5e6 0%, #5ba4d5 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff75b5e6', endColorstr='#ff5ba4d5', GradientType=0) !important;
}
.btn-app.btn-info:hover {
  background: #3f96d4 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#4a9ede), to(#348dc9)) !important;
  background-image: -webkit-linear-gradient(top, #4a9ede, 0%, #348dc9, 100%) !important;
  background-image: -moz-linear-gradient(top, #4a9ede 0%, #348dc9 100%) !important;
  background-image: linear-gradient(to bottom, #4a9ede 0%, #348dc9 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff4a9ede', endColorstr='#ff348dc9', GradientType=0) !important;
}
.btn-app.btn-success,
.btn-app.btn-success.no-hover:hover,
.btn-app.btn-success.disabled:hover {
  background: #86b558 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#8ebf60), to(#7daa50)) !important;
  background-image: -webkit-linear-gradient(top, #8ebf60, 0%, #7daa50, 100%) !important;
  background-image: -moz-linear-gradient(top, #8ebf60 0%, #7daa50 100%) !important;
  background-image: linear-gradient(to bottom, #8ebf60 0%, #7daa50 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8ebf60', endColorstr='#ff7daa50', GradientType=0) !important;
}
.btn-app.btn-success:hover {
  background: #6c9842 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#74a844), to(#648740)) !important;
  background-image: -webkit-linear-gradient(top, #74a844, 0%, #648740, 100%) !important;
  background-image: -moz-linear-gradient(top, #74a844 0%, #648740 100%) !important;
  background-image: linear-gradient(to bottom, #74a844 0%, #648740 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff74a844', endColorstr='#ff648740', GradientType=0) !important;
}
.btn-app.btn-danger,
.btn-app.btn-danger.no-hover:hover,
.btn-app.btn-danger.disabled:hover {
  background: #d3413b !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#d55b52), to(#d12723)) !important;
  background-image: -webkit-linear-gradient(top, #d55b52, 0%, #d12723, 100%) !important;
  background-image: -moz-linear-gradient(top, #d55b52 0%, #d12723 100%) !important;
  background-image: linear-gradient(to bottom, #d55b52 0%, #d12723 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd55b52', endColorstr='#ffd12723', GradientType=0) !important;
}
.btn-app.btn-danger:hover {
  background: #b52c26 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#c43a30), to(#a51f1c)) !important;
  background-image: -webkit-linear-gradient(top, #c43a30, 0%, #a51f1c, 100%) !important;
  background-image: -moz-linear-gradient(top, #c43a30 0%, #a51f1c 100%) !important;
  background-image: linear-gradient(to bottom, #c43a30 0%, #a51f1c 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffc43a30', endColorstr='#ffa51f1c', GradientType=0) !important;
}
.btn-app.btn-warning,
.btn-app.btn-warning.no-hover:hover,
.btn-app.btn-warning.disabled:hover {
  background: #ffb44b !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffbf66), to(#ffa830)) !important;
  background-image: -webkit-linear-gradient(top, #ffbf66, 0%, #ffa830, 100%) !important;
  background-image: -moz-linear-gradient(top, #ffbf66 0%, #ffa830 100%) !important;
  background-image: linear-gradient(to bottom, #ffbf66 0%, #ffa830 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffbf66', endColorstr='#ffffa830', GradientType=0) !important;
}
.btn-app.btn-warning:hover {
  background: #fe9e19 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffaa33), to(#fc9200)) !important;
  background-image: -webkit-linear-gradient(top, #ffaa33, 0%, #fc9200, 100%) !important;
  background-image: -moz-linear-gradient(top, #ffaa33 0%, #fc9200 100%) !important;
  background-image: linear-gradient(to bottom, #ffaa33 0%, #fc9200 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffaa33', endColorstr='#fffc9200', GradientType=0) !important;
}
.btn-app.btn-purple,
.btn-app.btn-purple.no-hover:hover,
.btn-app.btn-purple.disabled:hover {
  background: #9889c1 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#a696ce), to(#8a7cb4)) !important;
  background-image: -webkit-linear-gradient(top, #a696ce, 0%, #8a7cb4, 100%) !important;
  background-image: -moz-linear-gradient(top, #a696ce 0%, #8a7cb4 100%) !important;
  background-image: linear-gradient(to bottom, #a696ce 0%, #8a7cb4 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffa696ce', endColorstr='#ff8a7cb4', GradientType=0) !important;
}
.btn-app.btn-purple:hover {
  background: #7b68af !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#8973be), to(#6d5ca1)) !important;
  background-image: -webkit-linear-gradient(top, #8973be, 0%, #6d5ca1, 100%) !important;
  background-image: -moz-linear-gradient(top, #8973be 0%, #6d5ca1 100%) !important;
  background-image: linear-gradient(to bottom, #8973be 0%, #6d5ca1 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8973be', endColorstr='#ff6d5ca1', GradientType=0) !important;
}
.btn-app.btn-pink,
.btn-app.btn-pink.no-hover:hover,
.btn-app.btn-pink.disabled:hover {
  background: #d54c7e !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#db5e8c), to(#ce3970)) !important;
  background-image: -webkit-linear-gradient(top, #db5e8c, 0%, #ce3970, 100%) !important;
  background-image: -moz-linear-gradient(top, #db5e8c 0%, #ce3970 100%) !important;
  background-image: linear-gradient(to bottom, #db5e8c 0%, #ce3970 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdb5e8c', endColorstr='#ffce3970', GradientType=0) !important;
}
.btn-app.btn-pink:hover {
  background: #be2f64 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#d2346e), to(#aa2a59)) !important;
  background-image: -webkit-linear-gradient(top, #d2346e, 0%, #aa2a59, 100%) !important;
  background-image: -moz-linear-gradient(top, #d2346e 0%, #aa2a59 100%) !important;
  background-image: linear-gradient(to bottom, #d2346e 0%, #aa2a59 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd2346e', endColorstr='#ffaa2a59', GradientType=0) !important;
}
.btn-app.btn-inverse,
.btn-app.btn-inverse.no-hover:hover,
.btn-app.btn-inverse.disabled:hover {
  background: #444444 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#555555), to(#333333)) !important;
  background-image: -webkit-linear-gradient(top, #555555, 0%, #333333, 100%) !important;
  background-image: -moz-linear-gradient(top, #555555 0%, #333333 100%) !important;
  background-image: linear-gradient(to bottom, #555555 0%, #333333 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff555555', endColorstr='#ff333333', GradientType=0) !important;
}
.btn-app.btn-inverse:hover {
  background: #2b2b2b !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#3b3b3b), to(#1a1a1a)) !important;
  background-image: -webkit-linear-gradient(top, #3b3b3b, 0%, #1a1a1a, 100%) !important;
  background-image: -moz-linear-gradient(top, #3b3b3b 0%, #1a1a1a 100%) !important;
  background-image: linear-gradient(to bottom, #3b3b3b 0%, #1a1a1a 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3b3b3b', endColorstr='#ff1a1a1a', GradientType=0) !important;
}
.btn-app.btn-grey,
.btn-app.btn-grey.no-hover:hover,
.btn-app.btn-grey.disabled:hover {
  background: #797979 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#898989), to(#696969)) !important;
  background-image: -webkit-linear-gradient(top, #898989, 0%, #696969, 100%) !important;
  background-image: -moz-linear-gradient(top, #898989 0%, #696969 100%) !important;
  background-image: linear-gradient(to bottom, #898989 0%, #696969 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff898989', endColorstr='#ff696969', GradientType=0) !important;
}
.btn-app.btn-grey:hover {
  background: #6c6c6c !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#7c7c7c), to(#5c5c5c)) !important;
  background-image: -webkit-linear-gradient(top, #7c7c7c, 0%, #5c5c5c, 100%) !important;
  background-image: -moz-linear-gradient(top, #7c7c7c 0%, #5c5c5c 100%) !important;
  background-image: linear-gradient(to bottom, #7c7c7c 0%, #5c5c5c 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff7c7c7c', endColorstr='#ff5c5c5c', GradientType=0) !important;
}
.btn.btn-app.btn-light {
  color: #5a5a5a !important;
  text-shadow: 0 1px 1px #EEE !important;
}
.btn.btn-app.btn-light,
.btn.btn-app.btn-light.no-hover:hover,
.btn.btn-app.btn-light.disabled:hover {
  background: #ededed !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f4f4f4), to(#e6e6e6)) !important;
  background-image: -webkit-linear-gradient(top, #f4f4f4, 0%, #e6e6e6, 100%) !important;
  background-image: -moz-linear-gradient(top, #f4f4f4 0%, #e6e6e6 100%) !important;
  background-image: linear-gradient(to bottom, #f4f4f4 0%, #e6e6e6 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff4f4f4', endColorstr='#ffe6e6e6', GradientType=0) !important;
}
.btn.btn-app.btn-light:hover {
  background: #e0e0e0 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#e7e7e7), to(#d9d9d9)) !important;
  background-image: -webkit-linear-gradient(top, #e7e7e7, 0%, #d9d9d9, 100%) !important;
  background-image: -moz-linear-gradient(top, #e7e7e7 0%, #d9d9d9 100%) !important;
  background-image: linear-gradient(to bottom, #e7e7e7 0%, #d9d9d9 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe7e7e7', endColorstr='#ffd9d9d9', GradientType=0) !important;
}
.btn.btn-app.btn-yellow {
  color: #996633 !important;
  text-shadow: 0 -1px 0 rgba(255, 255, 255, 0.4) !important;
}
.btn.btn-app.btn-yellow,
.btn.btn-app.btn-yellow.no-hover:hover,
.btn.btn-app.btn-yellow.disabled:hover {
  background: #fee088 !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffe8a5), to(#fcd76a)) !important;
  background-image: -webkit-linear-gradient(top, #ffe8a5, 0%, #fcd76a, 100%) !important;
  background-image: -moz-linear-gradient(top, #ffe8a5 0%, #fcd76a 100%) !important;
  background-image: linear-gradient(to bottom, #ffe8a5 0%, #fcd76a 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffe8a5', endColorstr='#fffcd76a', GradientType=0) !important;
}
.btn.btn-app.btn-yellow:hover {
  background: #fdd96e !important;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffe18b), to(#fbd051)) !important;
  background-image: -webkit-linear-gradient(top, #ffe18b, 0%, #fbd051, 100%) !important;
  background-image: -moz-linear-gradient(top, #ffe18b 0%, #fbd051 100%) !important;
  background-image: linear-gradient(to bottom, #ffe18b 0%, #fbd051 100%) !important;
  background-repeat: repeat-x !important;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffe18b', endColorstr='#fffbd051', GradientType=0) !important;
}
.btn.btn-app {
  /* badge & label inside buttons */

  /* active state */

}
.btn.btn-app.btn-sm {
  width: 80px;
  font-size: 16px;
  border-radius: 10px ;
  line-height: 1.5;
}
.btn.btn-app.btn-xs {
  width: 64px;
  font-size: 15px;
  border-radius: 8px;
  padding-bottom: 7px;
  padding-top: 8px;
  line-height: 1.45;
}
.btn.btn-app > [class*=icon] {
  display: block;
  font-size: 42px;
  margin: 0 0 4px;
  line-height: 36px;
  min-width: 0;
  padding: 0;
}
.btn.btn-app.btn-sm > [class*=icon] {
  display: block;
  font-size: 32px;
  line-height: 30px;
  margin: 0 0 3px;
}
.btn.btn-app.btn-xs > [class*=icon] {
  display: block;
  font-size: 24px;
  line-height: 24px;
  margin: 0;
}
.btn.btn-app.no-radius {
  border-radius: 0;
}
.btn.btn-app.radius-4 {
  border-radius: 4px;
}
.btn.btn-app > .badge,
.btn.btn-app > .label {
  position: absolute !important;
  top: -2px;
  right: -2px;
  padding: 1px 3px;
  text-align: center;
  font-size: 12px;
}
.btn.btn-app > .badge.badge-left,
.btn.btn-app > .label.badge-left,
.btn.btn-app > .badge.label-left,
.btn.btn-app > .label.label-left {
  right: auto;
  left: -2px;
}
.btn.btn-app > .label {
  padding: 1px 6px 3px;
  font-size: 13px;
}
.btn.btn-app.radius-4 > .badge,
.btn.btn-app.no-radius > .badge {
  border-radius: 3px;
}
.btn.btn-app.radius-4 > .badge.no-radius,
.btn.btn-app.no-radius > .badge.no-radius {
  border-radius: 0;
}
.btn.btn-app.active {
  color: #ffffff;
}
.btn.btn-app.active:after {
  display: none;
}
.btn.btn-app.active.btn-yellow {
  color: #996633;
  border-color: #fee188;
}
.btn.btn-app.active.btn-light {
  color: #515151;
}
.btn.btn-app.btn-light,
.btn.btn-app.btn-yellow {
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset !important;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08) inset !important;
}
/* labels & badges */
.label {
  border-radius: 0;
  text-shadow: none;
  font-weight: normal;
  display: inline-block;
  background-color: #abbac3 !important;
}
.label[class*="col-"][class*="arrow"] {
  min-height: 0;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  ::safari-only,
  .label:empty {
    display: inline-block;
  }
  ::safari-only,
  .badge:empty {
    display: inline-block;
  }
}
.badge {
  text-shadow: none;
  font-size: 12px;
  padding-top: 1px;
  padding-bottom: 3px;
  font-weight: normal;
  line-height: 15px;
  background-color: #abbac3 !important;
}
.badge.no-radius {
  border-radius: 0;
}
.badge.radius-1 {
  border-radius: 1px;
}
.badge.radius-2 {
  border-radius: 2px;
}
.badge.radius-3 {
  border-radius: 3px;
}
.badge.radius-4 {
  border-radius: 4px;
}
.badge.radius-5 {
  border-radius: 5px;
}
.badge.radius-6 {
  border-radius: 6px;
}
.label-transparent,
.badge-transparent {
  background-color: transparent !important;
}
.label-grey,
.badge-grey {
  background-color: #a0a0a0 !important;
}
.label-info,
.badge-info {
  background-color: #3a87ad !important;
}
.label-primary,
.badge-primary {
  background-color: #428bca !important;
}
.label-success,
.badge-success {
  background-color: #82af6f !important;
}
.label-danger,
.badge-danger {
  background-color: #d15b47 !important;
}
.label-important,
.badge-important {
  background-color: #d15b47 !important;
}
.label-inverse,
.badge-inverse {
  background-color: #333333 !important;
}
.label-warning,
.badge-warning {
  background-color: #f89406 !important;
}
.label-pink,
.badge-pink {
  background-color: #d6487e !important;
}
.label-purple,
.badge-purple {
  background-color: #9585bf !important;
}
.label-yellow,
.badge-yellow {
  background-color: #fee188 !important;
}
.label-light,
.badge-light {
  background-color: #e7e7e7 !important;
}
.badge-yellow,
.label-yellow {
  color: #996633 !important;
  border-color: #fee188;
}
.badge-light,
.label-light {
  color: #888 !important;
}
.label.arrowed,
.label.arrowed-in {
  position: relative;
  z-index: 1;
}
.label.arrowed:before,
.label.arrowed-in:before {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  border: 1px solid transparent;
  border-right-color: #abbac3;
}
.label.arrowed-in:before {
  border-color: #abbac3;
  border-left-color: transparent !important;
}
.label.arrowed-right,
.label.arrowed-in-right {
  position: relative;
  z-index: 1;
}
.label.arrowed-right:after,
.label.arrowed-in-right:after {
  display: inline-block;
  content: "";
  position: absolute;
  top: 0;
  z-index: -1;
  border: 1px solid transparent;
  border-left-color: #abbac3;
}
.label.arrowed-in-right:after {
  border-color: #abbac3;
  border-right-color: transparent !important;
}
.label-info.arrowed:before {
  border-right-color: #3a87ad;
}
.label-info.arrowed-in:before {
  border-color: #3a87ad;
}
.label-info.arrowed-right:after {
  border-left-color: #3a87ad;
}
.label-info.arrowed-in-right:after {
  border-color: #3a87ad;
}
.label-primary.arrowed:before {
  border-right-color: #428bca;
}
.label-primary.arrowed-in:before {
  border-color: #428bca;
}
.label-primary.arrowed-right:after {
  border-left-color: #428bca;
}
.label-primary.arrowed-in-right:after {
  border-color: #428bca;
}
.label-success.arrowed:before {
  border-right-color: #82af6f;
}
.label-success.arrowed-in:before {
  border-color: #82af6f;
}
.label-success.arrowed-right:after {
  border-left-color: #82af6f;
}
.label-success.arrowed-in-right:after {
  border-color: #82af6f;
}
.label-warning.arrowed:before {
  border-right-color: #f89406;
}
.label-warning.arrowed-in:before {
  border-color: #f89406;
}
.label-warning.arrowed-right:after {
  border-left-color: #f89406;
}
.label-warning.arrowed-in-right:after {
  border-color: #f89406;
}
.label-important.arrowed:before {
  border-right-color: #d15b47;
}
.label-important.arrowed-in:before {
  border-color: #d15b47;
}
.label-important.arrowed-right:after {
  border-left-color: #d15b47;
}
.label-important.arrowed-in-right:after {
  border-color: #d15b47;
}
.label-danger.arrowed:before {
  border-right-color: #d15b47;
}
.label-danger.arrowed-in:before {
  border-color: #d15b47;
}
.label-danger.arrowed-right:after {
  border-left-color: #d15b47;
}
.label-danger.arrowed-in-right:after {
  border-color: #d15b47;
}
.label-inverse.arrowed:before {
  border-right-color: #333333;
}
.label-inverse.arrowed-in:before {
  border-color: #333333;
}
.label-inverse.arrowed-right:after {
  border-left-color: #333333;
}
.label-inverse.arrowed-in-right:after {
  border-color: #333333;
}
.label-pink.arrowed:before {
  border-right-color: #d6487e;
}
.label-pink.arrowed-in:before {
  border-color: #d6487e;
}
.label-pink.arrowed-right:after {
  border-left-color: #d6487e;
}
.label-pink.arrowed-in-right:after {
  border-color: #d6487e;
}
.label-purple.arrowed:before {
  border-right-color: #9585bf;
}
.label-purple.arrowed-in:before {
  border-color: #9585bf;
}
.label-purple.arrowed-right:after {
  border-left-color: #9585bf;
}
.label-purple.arrowed-in-right:after {
  border-color: #9585bf;
}
.label-yellow.arrowed:before {
  border-right-color: #fee188;
}
.label-yellow.arrowed-in:before {
  border-color: #fee188;
}
.label-yellow.arrowed-right:after {
  border-left-color: #fee188;
}
.label-yellow.arrowed-in-right:after {
  border-color: #fee188;
}
.label-light.arrowed:before {
  border-right-color: #e7e7e7;
}
.label-light.arrowed-in:before {
  border-color: #e7e7e7;
}
.label-light.arrowed-right:after {
  border-left-color: #e7e7e7;
}
.label-light.arrowed-in-right:after {
  border-color: #e7e7e7;
}
.label-grey.arrowed:before {
  border-right-color: #a0a0a0;
}
.label-grey.arrowed-in:before {
  border-color: #a0a0a0;
}
.label-grey.arrowed-right:after {
  border-left-color: #a0a0a0;
}
.label-grey.arrowed-in-right:after {
  border-color: #a0a0a0;
}
.label {
  font-size: 12px;
  line-height: 1.15;
  height: 20px;
}
.label.arrowed {
  margin-left: 5px;
}
.label.arrowed:before {
  left: -10px;
  border-width: 10px 5px;
}
.label.arrowed-in {
  margin-left: 5px;
}
.label.arrowed-in:before {
  left: -5px;
  border-width: 10px 5px;
}
.label.arrowed-right {
  margin-right: 5px;
}
.label.arrowed-right:after {
  right: -10px;
  border-width: 10px 5px;
}
.label.arrowed-in-right {
  margin-right: 5px;
}
.label.arrowed-in-right:after {
  right: -5px;
  border-width: 10px 5px;
}
.label-lg {
  padding: 0.3em 0.6em 0.4em;
  font-size: 13px;
  line-height: 1.1;
  height: 24px;
}
.label-lg.arrowed {
  margin-left: 6px;
}
.label-lg.arrowed:before {
  left: -12px;
  border-width: 12px 6px;
}
.label-lg.arrowed-in {
  margin-left: 6px;
}
.label-lg.arrowed-in:before {
  left: -6px;
  border-width: 12px 6px;
}
.label-lg.arrowed-right {
  margin-right: 6px;
}
.label-lg.arrowed-right:after {
  right: -12px;
  border-width: 12px 6px;
}
.label-lg.arrowed-in-right {
  margin-right: 6px;
}
.label-lg.arrowed-in-right:after {
  right: -6px;
  border-width: 12px 6px;
}
.label-xlg {
  padding: 0.3em 0.7em 0.4em;
  font-size: 14px;
  line-height: 1.3;
  height: 28px;
}
.label-xlg.arrowed {
  margin-left: 7px;
}
.label-xlg.arrowed:before {
  left: -14px;
  border-width: 14px 7px;
}
.label-xlg.arrowed-in {
  margin-left: 7px;
}
.label-xlg.arrowed-in:before {
  left: -7px;
  border-width: 14px 7px;
}
.label-xlg.arrowed-right {
  margin-right: 7px;
}
.label-xlg.arrowed-right:after {
  right: -14px;
  border-width: 14px 7px;
}
.label-xlg.arrowed-in-right {
  margin-right: 7px;
}
.label-xlg.arrowed-in-right:after {
  right: -7px;
  border-width: 14px 7px;
}
.label-sm {
  padding: 0.2em 0.4em 0.3em;
  font-size: 11px;
  line-height: 1;
  height: 18px;
}
.label-sm.arrowed {
  margin-left: 4px;
}
.label-sm.arrowed:before {
  left: -8px;
  border-width: 9px 4px;
}
.label-sm.arrowed-in {
  margin-left: 4px;
}
.label-sm.arrowed-in:before {
  left: -4px;
  border-width: 9px 4px;
}
.label-sm.arrowed-right {
  margin-right: 4px;
}
.label-sm.arrowed-right:after {
  right: -8px;
  border-width: 9px 4px;
}
.label-sm.arrowed-in-right {
  margin-right: 4px;
}
.label-sm.arrowed-in-right:after {
  right: -4px;
  border-width: 9px 4px;
}
.label > span,
.label > [class*="icon-"] {
  line-height: 1;
  vertical-align: bottom;
}
/* dropdown menus */
.icon-white,
.nav-pills > .active > a > [class^="icon-"],
.nav-pills > .active > a > [class*=" icon-"],
.nav-list > .active > a > [class^="icon-"],
.nav-list > .active > a > [class*=" icon-"],
.navbar-inverse .nav > .active > a > [class^="icon-"],
.navbar-inverse .nav > .active > a > [class*=" icon-"],
.dropdown-menu > li > a:hover > [class^="icon-"],
.dropdown-menu > li > a:focus > [class^="icon-"],
.dropdown-menu > li > a:hover > [class*=" icon-"],
.dropdown-menu > li > a:focus > [class*=" icon-"],
.dropdown-menu > .active > a > [class^="icon-"],
.dropdown-menu > .active > a > [class*=" icon-"],
.dropdown-submenu:hover > a > [class^="icon-"],
.dropdown-submenu:focus > a > [class^="icon-"],
.dropdown-submenu:hover > a > [class*=" icon-"],
.dropdown-submenu:focus > a > [class*=" icon-"] {
  background-image: none;
}
.dropdown-menu {
  border-radius: 0 !important;
  -webkit-box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}
.dropdown-menu > li > a {
  font-size: 13px;
  padding-left: 11px;
  padding-right: 11px;
  margin-bottom: 1px;
  margin-top: 1px;
}
.dropdown-menu.dropdown-only-icon {
  min-width: 50px;
}
.dropdown-menu.dropdown-only-icon > li {
  float: left;
  margin: 0 4px;
}
.dropdown-menu.dropdown-only-icon > li > a [class*="icon-"] {
  width: 18px;
  display: inline-block;
}
.dropdown-menu.dropdown-only-icon > li > a .icon-2x {
  width: 36px;
}
.dropdown-menu li a:hover,
.dropdown-menu li a:focus,
.dropdown-menu li a:active,
.dropdown-menu li.active a,
.dropdown-menu li.active a:hover,
.dropdown-menu .dropdown-submenu:hover > a,
.nav-tabs .dropdown-menu li > a:focus {
  background: #4f99c6;
  color: #ffffff;
}
.dropdown-default li a:hover,
.dropdown-default li a:focus,
.dropdown-default li a:active,
.dropdown-default li.active a,
.dropdown-default li.active a:hover,
.dropdown-default .dropdown-submenu:hover > a,
.nav-tabs .dropdown-default li > a:focus {
  background: #abbac3;
  color: #ffffff;
}
.dropdown-info li a:hover,
.dropdown-info li a:focus,
.dropdown-info li a:active,
.dropdown-info li.active a,
.dropdown-info li.active a:hover,
.dropdown-info .dropdown-submenu:hover > a,
.nav-tabs .dropdown-info li > a:focus {
  background: #6fb3e0;
  color: #ffffff;
}
.dropdown-primary li a:hover,
.dropdown-primary li a:focus,
.dropdown-primary li a:active,
.dropdown-primary li.active a,
.dropdown-primary li.active a:hover,
.dropdown-primary .dropdown-submenu:hover > a,
.nav-tabs .dropdown-primary li > a:focus {
  background: #428bca;
  color: #ffffff;
}
.dropdown-success li a:hover,
.dropdown-success li a:focus,
.dropdown-success li a:active,
.dropdown-success li.active a,
.dropdown-success li.active a:hover,
.dropdown-success .dropdown-submenu:hover > a,
.nav-tabs .dropdown-success li > a:focus {
  background: #87b87f;
  color: #ffffff;
}
.dropdown-warning li a:hover,
.dropdown-warning li a:focus,
.dropdown-warning li a:active,
.dropdown-warning li.active a,
.dropdown-warning li.active a:hover,
.dropdown-warning .dropdown-submenu:hover > a,
.nav-tabs .dropdown-warning li > a:focus {
  background: #ffa24d;
  color: #ffffff;
}
.dropdown-danger li a:hover,
.dropdown-danger li a:focus,
.dropdown-danger li a:active,
.dropdown-danger li.active a,
.dropdown-danger li.active a:hover,
.dropdown-danger .dropdown-submenu:hover > a,
.nav-tabs .dropdown-danger li > a:focus {
  background: #d15b47;
  color: #ffffff;
}
.dropdown-inverse li a:hover,
.dropdown-inverse li a:focus,
.dropdown-inverse li a:active,
.dropdown-inverse li.active a,
.dropdown-inverse li.active a:hover,
.dropdown-inverse .dropdown-submenu:hover > a,
.nav-tabs .dropdown-inverse li > a:focus {
  background: #555555;
  color: #ffffff;
}
.dropdown-purple li a:hover,
.dropdown-purple li a:focus,
.dropdown-purple li a:active,
.dropdown-purple li.active a,
.dropdown-purple li.active a:hover,
.dropdown-purple .dropdown-submenu:hover > a,
.nav-tabs .dropdown-purple li > a:focus {
  background: #9585bf;
  color: #ffffff;
}
.dropdown-pink li a:hover,
.dropdown-pink li a:focus,
.dropdown-pink li a:active,
.dropdown-pink li.active a,
.dropdown-pink li.active a:hover,
.dropdown-pink .dropdown-submenu:hover > a,
.nav-tabs .dropdown-pink li > a:focus {
  background: #d6487e;
  color: #ffffff;
}
.dropdown-grey li a:hover,
.dropdown-grey li a:focus,
.dropdown-grey li a:active,
.dropdown-grey li.active a,
.dropdown-grey li.active a:hover,
.dropdown-grey .dropdown-submenu:hover > a,
.nav-tabs .dropdown-grey li > a:focus {
  background: #a0a0a0;
  color: #ffffff;
}
.dropdown-light li a:hover,
.dropdown-light li a:focus,
.dropdown-light li a:active,
.dropdown-light li.active a,
.dropdown-light li.active a:hover,
.dropdown-light .dropdown-submenu:hover > a,
.nav-tabs .dropdown-light li > a:focus {
  background: #e7e7e7;
  color: #333333;
}
.dropdown-lighter li a:hover,
.dropdown-lighter li a:focus,
.dropdown-lighter li a:active,
.dropdown-lighter li.active a,
.dropdown-lighter li.active a:hover,
.dropdown-lighter .dropdown-submenu:hover > a,
.nav-tabs .dropdown-lighter li > a:focus {
  background: #efefef;
  color: #444444;
}
.dropdown-lightest li a:hover,
.dropdown-lightest li a:focus,
.dropdown-lightest li a:active,
.dropdown-lightest li.active a,
.dropdown-lightest li.active a:hover,
.dropdown-lightest .dropdown-submenu:hover > a,
.nav-tabs .dropdown-lightest li > a:focus {
  background: #f3f3f3;
  color: #444444;
}
.dropdown-yellow li a:hover,
.dropdown-yellow li a:focus,
.dropdown-yellow li a:active,
.dropdown-yellow li.active a,
.dropdown-yellow li.active a:hover,
.dropdown-yellow .dropdown-submenu:hover > a,
.nav-tabs .dropdown-yellow li > a:focus {
  background: #fee188;
  color: #444444;
}
.dropdown-yellow2 li a:hover,
.dropdown-yellow2 li a:focus,
.dropdown-yellow2 li a:active,
.dropdown-yellow2 li.active a,
.dropdown-yellow2 li.active a:hover,
.dropdown-yellow2 .dropdown-submenu:hover > a,
.nav-tabs .dropdown-yellow2 li > a:focus {
  background: #f9e8b3;
  color: #444444;
}
.dropdown-light-blue li a:hover,
.dropdown-light-blue li a:focus,
.dropdown-light-blue li a:active,
.dropdown-light-blue li.active a,
.dropdown-light-blue li.active a:hover,
.dropdown-light-blue .dropdown-submenu:hover > a,
.nav-tabs .dropdown-light-blue li > a:focus {
  background: #ecf3f9;
  color: #445566;
}
.dropdown-light .dropdown-submenu:hover > a:after,
.dropdown-lighter .dropdown-submenu:hover > a:after,
.dropdown-lightest .dropdown-submenu:hover > a:after {
  border-left-color: #444;
}
/* closer to the toggle button */
.dropdown-menu.dropdown-close {
  top: 92%;
  left: -5px;
}
.dropdown-menu.dropdown-close.pull-right {
  left: auto;
  right: -5px;
}
.dropdown-menu.dropdown-closer {
  top: 80%;
  left: -10px;
}
.dropdown-menu.dropdown-closer.pull-right {
  right: -10px;
  left: auto;
}
.dropdown-submenu > .dropdown-menu {
  border-radius: 0;
}
.dropdown-submenu > a:after {
  margin-right: -5px;
}
/* top user info dropdowns */
.dropdown-navbar {
  padding: 0;
  width: 240px;
  -webkit-box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  box-shadow: 0 2px 4px rgba(30, 30, 100, 0.25);
  border-color: #bcd4e5;
  /* user info on top navbar icons */

}
.dropdown-navbar > li {
  padding: 0 8px;
  background-color: #FFFFFF;
}
.dropdown-navbar > li.dropdown-header {
  text-shadow: none;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 34px;
  font-size: 13px;
  font-weight: bold;
  text-transform: none;
  border-bottom: 1px solid;
}
.dropdown-navbar > li > [class*="icon-"],
.dropdown-navbar > li > a > [class*="icon-"] {
  margin-right: 5px !important;
  color: #555;
  font-size: 14px;
}
.dropdown-navbar > li > a {
  padding: 10px 2px;
  margin: 0;
  border-bottom: 1px solid;
  font-size: 12px;
  line-height: 16px;
  color: #555;
}
.dropdown-navbar > li > a:active,
.dropdown-navbar > li > a:hover,
.dropdown-navbar > li > a:focus {
  background-color: transparent !important;
  color: #555;
}
.dropdown-navbar > li > a .progress {
  margin-bottom: 0;
  margin-top: 4px;
}
.dropdown-navbar > li > a .badge {
  line-height: 16px;
  padding-right: 4px;
  padding-left: 4px;
  font-size: 12px;
}
.dropdown-navbar > li:last-child > a {
  border-bottom: 0 solid #DDD;
  border-top: 1px dotted transparent;
  color: #4F99C6;
  text-align: center;
  font-size: 13px;
}
.dropdown-navbar > li:last-child > a:hover {
  background-color: #FFF;
  color: #4F99C6;
  text-decoration: underline;
}
.dropdown-navbar > li:last-child > a:hover > [class*="icon-"] {
  text-decoration: none;
}
.dropdown-navbar > li:hover {
  background-color: #f4f9fc !important;
}
.dropdown-navbar > li.dropdown-header {
  background-color: #ecf2f7 !important;
  color: #8090a0;
  border-bottom-color: #bcd4e5;
}
.dropdown-navbar > li.dropdown-header > [class*="icon-"] {
  color: #8090a0;
}
.dropdown-navbar > li > a {
  border-bottom-color: #e4ecf3;
}
.dropdown-navbar.navbar-pink {
  border-color: #e5bcd4;
}
.dropdown-navbar.navbar-pink > li:hover {
  background-color: #fcf4f9 !important;
}
.dropdown-navbar.navbar-pink > li.dropdown-header {
  background-color: #f7ecf2 !important;
  color: #b471a0;
  border-bottom-color: #e5bcd4;
}
.dropdown-navbar.navbar-pink > li.dropdown-header > [class*="icon-"] {
  color: #c06090;
}
.dropdown-navbar.navbar-pink > li > a {
  border-bottom-color: #f3e4ec;
}
.dropdown-navbar.navbar-grey {
  border-color: #e5e5e5;
}
.dropdown-navbar.navbar-grey > li:hover {
  background-color: #f8f8f8 !important;
}
.dropdown-navbar.navbar-grey > li.dropdown-header {
  background-color: #f2f2f2 !important;
  color: #3a87ad;
  border-bottom-color: #e5e5e5;
}
.dropdown-navbar.navbar-grey > li.dropdown-header > [class*="icon-"] {
  color: #3a87ad;
}
.dropdown-navbar.navbar-grey > li > a {
  border-bottom-color: #eeeeee;
}
.dropdown-navbar.navbar-green {
  border-color: #b4d5ac;
}
.dropdown-navbar.navbar-green > li:hover {
  background-color: #f4f9ef !important;
}
.dropdown-navbar.navbar-green > li.dropdown-header {
  background-color: #ebf7e4 !important;
  color: #88aa66;
  border-bottom-color: #b4d5ac;
}
.dropdown-navbar.navbar-green > li.dropdown-header > [class*="icon-"] {
  color: #90c060;
}
.dropdown-navbar.navbar-green > li > a {
  border-bottom-color: #ecf3e4;
}
.dropdown-navbar [class*="btn"][class*="icon-"] {
  display: inline-block;
  margin: 0 5px 0 0;
  width: 24px;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
}
.dropdown-navbar .msg-photo {
  margin-right: 6px;
  max-width: 42px;
}
.dropdown-navbar .msg-body {
  display: inline-block;
  line-height: 20px;
  white-space: normal;
  vertical-align: middle;
  max-width: 175px;
}
.dropdown-navbar .msg-title {
  display: inline-block;
  line-height: 14px;
}
.dropdown-navbar .msg-time {
  display: block;
  font-size: 11px;
  color: #777;
}
.dropdown-navbar .msg-time > [class*="icon-"] {
  font-size: 14px;
  color: #555;
}
.dropdown-100 {
  min-width: 100px;
}
.dropdown-125 {
  min-width: 125px;
}
.dropdown-150 {
  min-width: 150px;
}
.dropdown-hover {
  position: relative;
}
.dropdown-hover:hover > .dropdown-menu {
  display: block;
}
/** form elements */
.form-line {
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 1px solid #EEE;
}
.form-actions {
  display: block;
  background-color: #F5F5F5;
  border-top: 1px solid #E5E5E5;
  margin-bottom: 20px;
  margin-top: 20px;
  padding: 19px 20px 20px;
}
.help-button {
  display: inline-block;
  height: 22px;
  width: 22px;
  line-height: 22px;
  text-align: center;
  padding: 0;
  background-color: #65bcda;
  color: #FFF;
  font-size: 12px;
  font-weight: bold;
  cursor: default;
  margin-left: 4px;
  border-radius: 100%;
  border-color: #FFF;
  border: 2px solid #FFF;
  -webkit-box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 0px 1px rgba(0, 0, 0, 0.2);
}
.help-button:hover {
  background-color: #65bcda;
  text-shadow: none;
}
label {
  font-weight: normal;
  font-size: 14px;
}
.form-group > label[class*="col-"] {
  padding-top: 4px;
  margin-bottom: 4px;
}
label,
.lbl {
  vertical-align: middle;
}
td > label,
th > label,
label.inline {
  margin-bottom: 0;
  line-height: inherit;
}
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  border-radius: 0 !important;
  color: #858585;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
  padding: 5px 4px;
  line-height: 1.2;
  font-size: 14px;
  font-family: inherit;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transition-duration: 0.1s;
  transition-duration: 0.1s;
}
textarea:hover,
input[type="text"]:hover,
input[type="password"]:hover,
input[type="datetime"]:hover,
input[type="datetime-local"]:hover,
input[type="date"]:hover,
input[type="month"]:hover,
input[type="time"]:hover,
input[type="week"]:hover,
input[type="number"]:hover,
input[type="email"]:hover,
input[type="url"]:hover,
input[type="search"]:hover,
input[type="tel"]:hover,
input[type="color"]:hover {
  border-color: #b5b5b5;
}
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #696969;
  border-color: #f59942;
  background-color: #ffffff;
  outline: none;
}
.form-control,
select {
  border-radius: 0;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
  color: #858585;
  background-color: #ffffff;
  border: 1px solid #d5d5d5;
}
.form-control:focus,
select:focus {
  color: #696969;
  border-color: #f59942;
  background-color: #ffffff;
  outline: none;
}
textarea.form-control {
  padding: 5px 9px;
}
select {
  padding: 3px 4px;
  height: 30px;
}
select.form-control {
  padding: 4px 6px;
  height: 32px;
}
select[multiple],
select.form-control[multiple] {
  height: auto;
}
input.block {
  display: block;
  margin-bottom: 9px;
}
.limiterBox {
  border: 1px solid #222;
  border-top: none;
  background-color: #333;
  padding: 3px 6px;
  font-size: 12px;
  color: #FFF;
  margin-top: 6px;
}
.limiterBox:after {
  display: none;
}
.limiterBox:before {
  display: block;
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  top: -8px;
  left: 50%;
  margin-left: -5px;
  border-color: transparent;
  border-style: solid;
  border-bottom-color: #333;
  border-width: 0 8px 8px;
}
select option,
select.form-control option {
  padding: 3px 4px;
}
select option:active,
select.form-control option:active,
select option:hover,
select.form-control option:hover,
select option:focus,
select.form-control option:focus {
  background-color: #e5e9ee;
  color: #111;
}
select option[value=""],
select.form-control option[value=""] {
  padding: 0;
}
input[disabled] {
  color: #848484 !important;
  background-color: #eeeeee !important;
}
input[disabled]:hover {
  border-color: #d5d5d5 !important;
}
input[readonly] {
  color: #939192;
  background: #f5f5f5 !important;
  cursor: default;
}
input[readonly]:hover {
  border-color: #c3c3c3;
}
input[readonly]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-color: #aaaaaa;
  background-color: #f9f9f9;
}
.help-inline {
  font-size: 13px !important;
}
.input-icon {
  position: relative;
}
span.input-icon {
  display: inline-block;
}
.input-icon > input {
  padding-left: 24px;
  padding-right: 6px;
}
.input-icon.input-icon-right > input {
  padding-left: 6px;
  padding-right: 24px;
}
.input-icon > [class*="icon-"] {
  padding: 0 3px;
  z-index: 2;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 3px;
  line-height: 28px;
  display: inline-block;
  color: #909090;
  font-size: 16px;
}
.input-icon.input-icon-right > [class*="icon-"] {
  left: auto;
  right: 3px;
}
.input-icon > input:focus + [class*="icon-"] {
  color: #579;
}
.input-icon ~ .help-inline {
  padding-left: 8px;
}
/* checkboxes , radio and switches */
.form-search .radio [type=radio] + label,
.form-inline .radio [type=radio] + label,
.form-search .checkbox [type=checkbox] + label,
.form-inline .checkbox [type=checkbox] + label {
  float: left;
  margin-left: -20px;
}
.form-search .form-search .radio [type=radio] + label,
.form-search .form-inline .radio [type=radio] + label,
.form-search .form-search .checkbox [type=checkbox] + label,
.form-search .form-inline .checkbox [type=checkbox] + label,
.form-inline .form-search .radio [type=radio] + label,
.form-inline .form-inline .radio [type=radio] + label,
.form-inline .form-search .checkbox [type=checkbox] + label,
.form-inline .form-inline .checkbox [type=checkbox] + label {
  margin-left: 0;
  margin-right: 3px;
}
.form-search .input-append .search-query:focus,
.form-search .input-prepend .search-query:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.input-append input,
.input-prepend input,
.input-append select,
.input-prepend select,
.input-append .uneditable-input,
.input-prepend .uneditable-input {
  border-radius: 0;
}
.input-mini {
  width: 60px;
  max-width: 100%;
}
.input-small {
  width: 90px;
  max-width: 100%;
}
.input-medium {
  width: 150px;
  max-width: 100%;
}
.input-large {
  width: 210px;
  max-width: 100%;
}
.input-xlarge {
  width: 270px;
  max-width: 100%;
}
.input-xxlarge {
  width: 530px;
  max-width: 100%;
}
input.input-lg {
  font-size: 18px;
}
input.input-sm {
  font-size: 12px;
}
/* Checkbox & Radio */
input[type=checkbox].ace,
input[type=radio].ace {
  opacity: 0;
  position: absolute;
  z-index: 12;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
input[type=checkbox].ace:checked,
input[type=radio].ace:checked,
input[type=checkbox].ace:focus,
input[type=radio].ace:focus {
  outline: none !important;
}
input[type=checkbox].ace + .lbl,
input[type=radio].ace + .lbl {
  position: relative;
  z-index: 11;
  display: inline-block;
  margin: 0;
  line-height: 20px;
  min-height: 18px;
  min-width: 18px;
  font-weight: normal;
}
input[type=checkbox].ace + .lbl.padding-16::before,
input[type=radio].ace + .lbl.padding-16::before {
  margin-right: 16px;
}
input[type=checkbox].ace + .lbl.padding-12::before,
input[type=radio].ace + .lbl.padding-12::before {
  margin-right: 12px;
}
input[type=checkbox].ace + .lbl.padding-8::before,
input[type=radio].ace + .lbl.padding-8::before {
  margin-right: 8px;
}
input[type=checkbox].ace + .lbl.padding-4::before,
input[type=radio].ace + .lbl.padding-4::before {
  margin-right: 4px;
}
input[type=checkbox].ace + .lbl.padding-0::before,
input[type=radio].ace + .lbl.padding-0::before {
  margin-right: 0px;
}
input[type=checkbox].ace + .lbl::before,
input[type=radio].ace + .lbl::before {
  font-family: fontAwesome;
  font-weight: normal;
  font-size: 12px;
  color: #32a3ce;
  content: "\a0";
  background-color: #FAFAFA;
  border: 1px solid #c8c8c8;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  /*, inset 0px -15px 10px -12px rgba(0,0,0,0.05);*/

  border-radius: 0;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  height: 16px;
  line-height: 14px;
  min-width: 16px;
  margin-right: 1px;
}
input[type=checkbox].ace:checked + .lbl::before,
input[type=radio].ace:checked + .lbl::before {
  display: inline-block;
  content: '\f00c';
  background-color: #F5F8FC;
  border-color: #adb8c0;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05), inset 15px 10px -12px rgba(255, 255, 255, 0.1);
}
input[type=checkbox].ace:hover + .lbl::before,
input[type=radio].ace:hover + .lbl::before,
input[type=checkbox].ace + .lbl:hover::before,
input[type=radio].ace + .lbl:hover::before {
  border-color: #ff893c;
}
input[type=checkbox].ace:active + .lbl::before,
input[type=radio].ace:active + .lbl::before,
input[type=checkbox].ace:checked:active + .lbl::before,
input[type=radio].ace:checked:active + .lbl::before {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px 1px 3px rgba(0, 0, 0, 0.1);
}
input[type=checkbox].ace.ace-checkbox-2 + .lbl::before,
input[type=radio].ace.ace-checkbox-2 + .lbl::before {
  box-shadow: none;
}
input[type=checkbox].ace.ace-checkbox-2:checked + .lbl::before,
input[type=radio].ace.ace-checkbox-2:checked + .lbl::before {
  background-color: #f9a021;
  border-color: #f9a021;
  color: #FFF;
}
input[type=checkbox].ace:disabled + .lbl::before,
input[type=radio].ace:disabled + .lbl::before,
input[type=checkbox].ace[disabled] + .lbl::before,
input[type=radio].ace[disabled] + .lbl::before,
input[type=checkbox].ace.disabled + .lbl::before,
input[type=radio].ace.disabled + .lbl::before {
  background-color: #DDD !important;
  border-color: #CCC !important;
  box-shadow: none !important;
  color: #BBB;
}
/**
input[type=radio].ace + .lbl::before {
	border-radius:100%;
	font-size:34px;
	font-family: Helvetica, Arial, "Sans-Serif";//looks better but incosistent
	line-height:13px;
}
*/
input[type=radio].ace + .lbl::before {
  border-radius: 100%;
  font-size: 11px;
  font-family: FontAwesome;
  text-shadow: 0 0 1px #32a3ce;
  line-height: 15px;
  height: 17px;
  min-width: 17px;
}
input[type=radio].ace:checked + .lbl::before {
  content: "\f111";
}
/* CSS3 on/off switches */
input[type=checkbox].ace.ace-switch {
  width: 55px;
  height: 25px;
  /* switch style 4 & 5 */

  /* switch style 6 */

}
input[type=checkbox].ace.ace-switch + .lbl {
  margin: 0 4px;
  min-height: 24px;
}
input[type=checkbox].ace.ace-switch + .lbl::before {
  font-family: Arial, Helvetica, sans-serif;
  content: "ON\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF";
  color: #999;
  font-weight: bold;
  font-size: 11px;
  line-height: 18px;
  line-height: 21px\9;
  /*ie9*/

  height: 20px;
  overflow: hidden;
  border-radius: 12px;
  background-color: #F5F5F5;
  -webkit-box-shadow: inset 0px 2px 2px 0px rgba(0,0,0,.2);
  box-shadow: inset 0px 2px 2px 0px rgba(0,0,0,.2);
  border: 1px solid #CCC;
  text-align: left;
  float: left;
  padding: 0;
  width: 52px;
  text-indent: -19px;
  text-indent: -21px\9;
  margin-right: 0;
  -webkit-transition: text-indent .4s ease;
  transition: text-indent .4s ease;
}
input[type=checkbox].ace.ace-switch + .lbl::after {
  font-family: Arial, Helvetica, sans-serif;
  content: '|||';
  font-size: 10px;
  font-weight: lighter;
  color: #D5D5D5;
  background-color: #FFF;
  text-shadow: -1px 0px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  line-height: 20px;
  position: absolute;
  top: -2px;
  left: -3px;
  padding: 0;
  -webkit-box-shadow: 0px 1px 1px 1px rgba(0,0,0,.3);
  box-shadow: 0px 1px 1px 1px rgba(0,0,0,.3);
  -webkit-transition: left .4s ease;
  transition: left .4s ease;
}
input[type=checkbox].ace.ace-switch:checked + .lbl::before {
  text-indent: 9px;
  color: #FFF;
  background-color: #8ab2c9;
  border-color: #468fcc;
}
input[type=checkbox].ace.ace-switch:checked + .lbl::after {
  left: 34px;
  background-color: #FFF;
  color: #8ab2c9;
}
input[type=checkbox].ace.ace-switch.ace-switch-2 + .lbl::before {
  content: "YES\a0\a0\a0\a0\a0\a0\a0\a0NO";
}
input[type=checkbox].ace.ace-switch.ace-switch-3 + .lbl::after {
  font-family: FontAwesome;
  font-size: 13px;
  line-height: 23px;
  content: "\f00d";
  top: -1px;
}
input[type=checkbox].ace.ace-switch.ace-switch-3:checked + .lbl::after {
  content: "\f00c";
}
input[type=checkbox].ace.ace-switch.ace-switch-4 + .lbl::before,
input[type=checkbox].ace.ace-switch.ace-switch-5 + .lbl::before {
  content: "ON\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0OFF";
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bolder;
  font-size: 12px;
  line-height: 23px;
  height: 24px;
  overflow: hidden;
  line-height: 25px\9;
  border-radius: 12px;
  background-color: #8b9aa3;
  border: 1px solid #8b9aa3;
  color: #FFF;
  width: 56px;
  text-indent: -25px;
  text-indent: -28px\9;
  display: inline-block;
  position: relative;
  box-shadow: none;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
input[type=checkbox].ace.ace-switch.ace-switch-4 + .lbl::after,
input[type=checkbox].ace.ace-switch.ace-switch-5 + .lbl::after {
  font-family: Helvetica, Arial, sans-serif;
  content: '|||';
  text-shadow: -1px 0px 0 rgba(0, 0, 0, 0.2);
  font-size: 8px;
  font-weight: lighter;
  color: #8b9aa3;
  text-align: center;
  position: absolute;
  border-radius: 12px;
  color: #5b6a73;
  top: 2px;
  left: 2px;
  width: 20px;
  height: 20px;
  line-height: 18px;
  background-color: #FFF;
  -webkit-transition: all .4s ease;
  transition: all .4s ease;
}
input[type=checkbox].ace.ace-switch.ace-switch-4:checked + .lbl::before,
input[type=checkbox].ace.ace-switch.ace-switch-5:checked + .lbl::before {
  text-indent: 9px;
  background-color: #468fcc;
  border-color: #468FCC;
}
input[type=checkbox].ace.ace-switch.ace-switch-4:checked + .lbl::after,
input[type=checkbox].ace.ace-switch.ace-switch-5:checked + .lbl::after {
  left: 34px;
  background-color: #FFF;
}
input[type=checkbox].ace.ace-switch.ace-switch-5 + .lbl::before {
  content: "YES\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0NO";
}
input[type=checkbox].ace.ace-switch.ace-switch-5:checked + .lbl::before {
  text-indent: 8px;
}
input[type=checkbox].ace.ace-switch.ace-switch-6 + .lbl {
  position: relative;
}
input[type=checkbox].ace.ace-switch.ace-switch-6 + .lbl::before {
  font-family: FontAwesome;
  content: "\f00d";
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: none;
  border: none;
  font-weight: lighter;
  font-size: 16px;
  border-radius: 12px;
  display: inline-block;
  background-color: #888;
  color: #F2F2F2;
  width: 52px;
  height: 22px;
  line-height: 20px;
  text-indent: 32px;
  -webkit-transition: background 0.1s ease;
  transition: background 0.1s ease;
}
input[type=checkbox].ace.ace-switch.ace-switch-6 + .lbl::after {
  content: '';
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  position: absolute;
  top: 2px;
  left: 3px;
  border-radius: 12px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
  width: 18px;
  height: 18px;
  text-align: center;
  background-color: #F2F2F2;
  border: 4px solid #F2F2F2;
  -webkit-transition: left 0.2s ease;
  transition: left 0.2s ease;
}
input[type=checkbox].ace.ace-switch.ace-switch-6:checked + .lbl::before {
  content: "\f00c";
  text-indent: 6px;
  color: #FFF;
  border-color: #b7d3e5;
  background-color: #ff893c;
}
input[type=checkbox].ace.ace-switch.ace-switch-6:checked + .lbl::after {
  left: 32px;
  background-color: #FFF;
  border: 4px solid #FFF;
  text-shadow: 0 -1px 0 rgba(0, 200, 0, 0.25);
}
input[type=checkbox].ace.ace-switch.ace-switch-7 {
  width: 75px;
}
input[type=checkbox].ace.ace-switch.ace-switch-7 + .lbl {
  position: relative;
}
input[type=checkbox].ace.ace-switch.ace-switch-7 + .lbl::before {
  content: "OFF\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0\a0ON";
  font-weight: bolder;
  font-size: 14px;
  line-height: 20px;
  background-color: #FFF;
  border: 2px solid #AAA;
  border-radius: 0;
  box-shadow: none;
  color: #aaa;
  width: 74px;
  height: 26px;
  line-height: 22px;
  overflow: hidden;
  text-indent: 4px;
  display: inline-block;
  position: relative;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
input[type=checkbox].ace.ace-switch.ace-switch-7 + .lbl::after {
  content: '\f00d';
  font-family: FontAwesome;
  font-size: 16px;
  position: absolute;
  top: 3px;
  left: 39px;
  width: 32px;
  height: 20px;
  line-height: 18px;
  text-align: center;
  background-color: #aaa;
  color: #FFF;
  border-radius: 0;
  box-shadow: none;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
input[type=checkbox].ace.ace-switch.ace-switch-7:checked + .lbl::before {
  color: #468fcc;
  background-color: #FFF;
  text-indent: -33px;
  border-color: #6fb3e0;
}
input[type=checkbox].ace.ace-switch.ace-switch-7:checked + .lbl::after {
  left: 3px;
  content: '\f00c';
  background-color: #468fcc;
  color: #FFF;
}
/* addon */
.input-group .input-group-addon {
  border-radius: 0 !important;
}
.form-group.has-success .input-group .input-group-addon {
  border-color: #92bf65;
}
.form-group.has-error .input-group .input-group-addon {
  border-color: #f09784;
}
.form-group.has-warning .input-group .input-group-addon {
  border-color: #e0c43a;
}
.form-group.has-info .input-group .input-group-addon {
  border-color: #64a6bc;
}
.input-group > .btn {
  line-height: 20px;
  padding: 0 6px;
  border-radius: 0 !important;
}
.input-group > .btn.btn-sm {
  line-height: 22px;
}
.input-group > .btn + .btn {
  margin-left: 1px;
}
.input-group > .btn-group > .btn {
  line-height: 23px;
}
.input-group > .btn-group > .btn.btn-sm {
  line-height: 26px;
}
.input-group > .btn > .caret,
.input-group > .btn-group > .btn > .caret,
.input-group > .btn.btn-sm > .caret,
.input-group > .btn-group > .btn.btn-sm > .caret {
  margin-top: 10px;
}
/** input error states */
.form-group select,
.form-group textarea,
.form-group input[type="text"],
.form-group input[type="password"],
.form-group input[type="datetime"],
.form-group input[type="datetime-local"],
.form-group input[type="date"],
.form-group input[type="month"],
.form-group input[type="time"],
.form-group input[type="week"],
.form-group input[type="number"],
.form-group input[type="email"],
.form-group input[type="url"],
.form-group input[type="search"],
.form-group input[type="tel"],
.form-group input[type="color"] {
  background: #FFF;
}
.form-group.has-success input,
.form-group.has-success select,
.form-group.has-success textarea {
  border-color: #92bf65;
  color: #8bad4c;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.has-success input:focus,
.form-group.has-success select:focus,
.form-group.has-success textarea:focus {
  -webkit-box-shadow: 0px 0px 0px 2px rgba(130, 188, 58, 0.3);
  box-shadow: 0px 0px 0px 2px rgba(130, 188, 58, 0.3);
  color: #778866;
  border-color: #81a85a;
}
.form-group.has-success input:focus + [class*="icon-"],
.form-group.has-success select:focus + [class*="icon-"],
.form-group.has-success textarea:focus + [class*="icon-"] {
  color: #8bad4c;
}
.form-group.has-success [class*="icon-"] {
  color: #8bad4c;
}
.form-group.has-success .btn [class*="icon-"] {
  color: inherit;
}
.form-group.has-success .control-label,
.form-group.has-success .help-block,
.form-group.has-success .help-inline {
  color: #7ba065;
}
.form-group.has-info input,
.form-group.has-info select,
.form-group.has-info textarea {
  border-color: #64a6bc;
  color: #4b89aa;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.has-info input:focus,
.form-group.has-info select:focus,
.form-group.has-info textarea:focus {
  -webkit-box-shadow: 0px 0px 0px 2px rgba(58, 120, 188, 0.3);
  box-shadow: 0px 0px 0px 2px rgba(58, 120, 188, 0.3);
  color: #667788;
  border-color: #5a81a8;
}
.form-group.has-info input:focus + [class*="icon-"],
.form-group.has-info select:focus + [class*="icon-"],
.form-group.has-info textarea:focus + [class*="icon-"] {
  color: #4b89aa;
}
.form-group.has-info [class*="icon-"] {
  color: #4b89aa;
}
.form-group.has-info .btn [class*="icon-"] {
  color: inherit;
}
.form-group.has-info .control-label,
.form-group.has-info .help-block,
.form-group.has-info .help-inline {
  color: #657ba0;
}
.form-group.has-error input,
.form-group.has-error select,
.form-group.has-error textarea {
  border-color: #f09784;
  color: #d68273;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.has-error input:focus,
.form-group.has-error select:focus,
.form-group.has-error textarea:focus {
  -webkit-box-shadow: 0px 0px 0px 2px rgba(219, 137, 120, 0.3);
  box-shadow: 0px 0px 0px 2px rgba(219, 137, 120, 0.3);
  color: #886666;
  border-color: #db8978;
}
.form-group.has-error input:focus + [class*="icon-"],
.form-group.has-error select:focus + [class*="icon-"],
.form-group.has-error textarea:focus + [class*="icon-"] {
  color: #d68273;
}
.form-group.has-error [class*="icon-"] {
  color: #d68273;
}
.form-group.has-error .btn [class*="icon-"] {
  color: inherit;
}
.form-group.has-error .control-label,
.form-group.has-error .help-block,
.form-group.has-error .help-inline {
  color: #d16e6c;
}
.form-group.has-warning input,
.form-group.has-warning select,
.form-group.has-warning textarea {
  border-color: #e0c43a;
  color: #d3bd50;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.form-group.has-warning input:focus,
.form-group.has-warning select:focus,
.form-group.has-warning textarea:focus {
  -webkit-box-shadow: 0px 0px 0px 2px rgba(216, 188, 65, 0.3);
  box-shadow: 0px 0px 0px 2px rgba(216, 188, 65, 0.3);
  color: #887755;
  border-color: #d8bc41;
}
.form-group.has-warning input:focus + [class*="icon-"],
.form-group.has-warning select:focus + [class*="icon-"],
.form-group.has-warning textarea:focus + [class*="icon-"] {
  color: #d3bd50;
}
.form-group.has-warning [class*="icon-"] {
  color: #d3bd50;
}
.form-group.has-warning .btn [class*="icon-"] {
  color: inherit;
}
.form-group.has-warning .control-label,
.form-group.has-warning .help-block,
.form-group.has-warning .help-inline {
  color: #d19d59;
}
.form-group input[disabled],
.form-group input:disabled {
  color: #848484 !important;
  background-color: #eeeeee !important;
}
@media only screen and (max-width: 767px) {
  .help-inline,
  .input-icon + .help-inline {
    padding-left: 0;
    display: block !important;
  }
}
.tab-content {
  border: 1px solid #c5d0dc;
  padding: 16px 12px;
  position: relative;
  z-index: 11;
}
.tab-content.no-padding {
  padding: 0;
}
.tab-content.no-border {
  border: none;
  padding: 12px;
}
.tab-content.padding-32 {
  padding: 32px 24px;
}
.tab-content.no-border.padding-32 {
  padding: 32px;
}
.tab-content.padding-30 {
  padding: 30px 23px;
}
.tab-content.no-border.padding-30 {
  padding: 30px;
}
.tab-content.padding-28 {
  padding: 28px 21px;
}
.tab-content.no-border.padding-28 {
  padding: 28px;
}
.tab-content.padding-26 {
  padding: 26px 20px;
}
.tab-content.no-border.padding-26 {
  padding: 26px;
}
.tab-content.padding-24 {
  padding: 24px 18px;
}
.tab-content.no-border.padding-24 {
  padding: 24px;
}
.tab-content.padding-22 {
  padding: 22px 17px;
}
.tab-content.no-border.padding-22 {
  padding: 22px;
}
.tab-content.padding-20 {
  padding: 20px 15px;
}
.tab-content.no-border.padding-20 {
  padding: 20px;
}
.tab-content.padding-18 {
  padding: 18px 14px;
}
.tab-content.no-border.padding-18 {
  padding: 18px;
}
.tab-content.padding-16 {
  padding: 16px 12px;
}
.tab-content.no-border.padding-16 {
  padding: 16px;
}
.tab-content.padding-14 {
  padding: 14px 11px;
}
.tab-content.no-border.padding-14 {
  padding: 14px;
}
.tab-content.padding-12 {
  padding: 12px 9px;
}
.tab-content.no-border.padding-12 {
  padding: 12px;
}
.tab-content.padding-10 {
  padding: 10px 8px;
}
.tab-content.no-border.padding-10 {
  padding: 10px;
}
.tab-content.padding-8 {
  padding: 8px 6px;
}
.tab-content.no-border.padding-8 {
  padding: 8px;
}
.tab-content.padding-6 {
  padding: 6px 5px;
}
.tab-content.no-border.padding-6 {
  padding: 6px;
}
.tab-content.padding-4 {
  padding: 4px 3px;
}
.tab-content.no-border.padding-4 {
  padding: 4px;
}
.tab-content.padding-2 {
  padding: 2px 2px;
}
.tab-content.no-border.padding-2 {
  padding: 2px;
}
.tab-content.padding-0 {
  padding: 0px 0px;
}
.tab-content.no-border.padding-0 {
  padding: 0px;
}
.nav-tabs.padding-32 {
  padding-left: 32px;
}
.tabs-right > .nav-tabs.padding-32,
.tabs-left > .nav-tabs.padding-32 {
  padding-left: 0;
  padding-top: 32px;
}
.nav-tabs.padding-30 {
  padding-left: 30px;
}
.tabs-right > .nav-tabs.padding-30,
.tabs-left > .nav-tabs.padding-30 {
  padding-left: 0;
  padding-top: 30px;
}
.nav-tabs.padding-28 {
  padding-left: 28px;
}
.tabs-right > .nav-tabs.padding-28,
.tabs-left > .nav-tabs.padding-28 {
  padding-left: 0;
  padding-top: 28px;
}
.nav-tabs.padding-26 {
  padding-left: 26px;
}
.tabs-right > .nav-tabs.padding-26,
.tabs-left > .nav-tabs.padding-26 {
  padding-left: 0;
  padding-top: 26px;
}
.nav-tabs.padding-24 {
  padding-left: 24px;
}
.tabs-right > .nav-tabs.padding-24,
.tabs-left > .nav-tabs.padding-24 {
  padding-left: 0;
  padding-top: 24px;
}
.nav-tabs.padding-22 {
  padding-left: 22px;
}
.tabs-right > .nav-tabs.padding-22,
.tabs-left > .nav-tabs.padding-22 {
  padding-left: 0;
  padding-top: 22px;
}
.nav-tabs.padding-20 {
  padding-left: 20px;
}
.tabs-right > .nav-tabs.padding-20,
.tabs-left > .nav-tabs.padding-20 {
  padding-left: 0;
  padding-top: 20px;
}
.nav-tabs.padding-18 {
  padding-left: 18px;
}
.tabs-right > .nav-tabs.padding-18,
.tabs-left > .nav-tabs.padding-18 {
  padding-left: 0;
  padding-top: 18px;
}
.nav-tabs.padding-16 {
  padding-left: 16px;
}
.tabs-right > .nav-tabs.padding-16,
.tabs-left > .nav-tabs.padding-16 {
  padding-left: 0;
  padding-top: 16px;
}
.nav-tabs.padding-14 {
  padding-left: 14px;
}
.tabs-right > .nav-tabs.padding-14,
.tabs-left > .nav-tabs.padding-14 {
  padding-left: 0;
  padding-top: 14px;
}
.nav-tabs.padding-12 {
  padding-left: 12px;
}
.tabs-right > .nav-tabs.padding-12,
.tabs-left > .nav-tabs.padding-12 {
  padding-left: 0;
  padding-top: 12px;
}
.nav-tabs.padding-10 {
  padding-left: 10px;
}
.tabs-right > .nav-tabs.padding-10,
.tabs-left > .nav-tabs.padding-10 {
  padding-left: 0;
  padding-top: 10px;
}
.nav-tabs.padding-8 {
  padding-left: 8px;
}
.tabs-right > .nav-tabs.padding-8,
.tabs-left > .nav-tabs.padding-8 {
  padding-left: 0;
  padding-top: 8px;
}
.nav-tabs.padding-6 {
  padding-left: 6px;
}
.tabs-right > .nav-tabs.padding-6,
.tabs-left > .nav-tabs.padding-6 {
  padding-left: 0;
  padding-top: 6px;
}
.nav-tabs.padding-4 {
  padding-left: 4px;
}
.tabs-right > .nav-tabs.padding-4,
.tabs-left > .nav-tabs.padding-4 {
  padding-left: 0;
  padding-top: 4px;
}
.nav-tabs.padding-2 {
  padding-left: 2px;
}
.tabs-right > .nav-tabs.padding-2,
.tabs-left > .nav-tabs.padding-2 {
  padding-left: 0;
  padding-top: 2px;
}
.nav-tabs {
  border-color: #C5D0DC;
  margin-bottom: 0;
  margin-left: 0;
  position: relative;
  top: 1px;
}
.nav-tabs > li > a {
  padding: 8px 12px;
}
.nav-tabs > li > a,
.nav-tabs > li > a:focus {
  border-radius: 0 !important;
  background-color: #F9F9F9;
  color: #999;
  margin-right: -1px;
  line-height: 16px;
  position: relative;
  z-index: 11;
  border-color: #c5d0dc;
}
.nav-tabs > li > a:hover {
  background-color: #FFF;
  color: #4c8fbd;
  border-color: #c5d0dc;
}
.nav-tabs > li > a:active,
.nav-tabs > li > a:focus {
  outline: none !important;
}
.nav-tabs > li:first-child > a {
  margin-left: 0;
}
.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:focus {
  color: #576373;
  border-color: #c5d0dc;
  border-top: 2px solid #4c8fbd;
  border-bottom-color: transparent;
  background-color: #FFF;
  z-index: 12;
  line-height: 16px;
  margin-top: -1px;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.15);
}
.tabs-below > .nav-tabs {
  /* tabs below */

  top: auto;
  margin-bottom: 0;
  margin-top: -1px;
  border-color: #c5d0dc;
  border-bottom-width: 0;
}
.tabs-below > .nav-tabs > li > a,
.tabs-below > .nav-tabs > li > a:hover,
.tabs-below > .nav-tabs > li > a:focus {
  border-color: #c5d0dc;
}
.tabs-below > .nav-tabs > li.active > a,
.tabs-below > .nav-tabs > li.active > a:hover,
.tabs-below > .nav-tabs > li.active > a:focus {
  border-color: #c5d0dc;
  border-top-width: 1px;
  border-bottom: 2px solid #4c8fbd;
  border-top-color: transparent;
  margin-top: 0;
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.15);
}
.tabs-left > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a {
  /* tabs left */

  min-width: 60px;
}
.tabs-left > .nav-tabs {
  top: auto;
  margin-bottom: 0;
  border-color: #c5d0dc;
  float: left;
}
.tabs-left > .nav-tabs > li {
  float: none;
}
.tabs-left > .nav-tabs > li > a,
.tabs-left > .nav-tabs > li > a:focus,
.tabs-left > .nav-tabs > li > a:hover {
  border-color: #c5d0dc;
  margin: 0 -1px 0 0;
}
.tabs-left > .nav-tabs > li.active > a,
.tabs-left > .nav-tabs > li.active > a:focus,
.tabs-left > .nav-tabs > li.active > a:hover {
  border-color: #c5d0dc;
  border-top-width: 1px;
  border-left: 2px solid #4c8fbd;
  border-right-color: transparent;
  margin: 0 -1px 0 -1px;
  -webkit-box-shadow: -2px 0 3px 0 rgba(0,0,0,0.15);
  box-shadow: -2px 0 3px 0 rgba(0,0,0,0.15);
}
.tabs-right > .nav-tabs {
  /* tabs right */

  top: auto;
  margin-bottom: 0;
  border-color: #c5d0dc;
  float: right;
}
.tabs-right > .nav-tabs > li {
  float: none;
}
.tabs-right > .nav-tabs > li > a,
.tabs-right > .nav-tabs > li > a:focus,
.tabs-right > .nav-tabs > li > a:hover {
  border-color: #c5d0dc;
  margin: 0 -1px;
}
.tabs-right > .nav-tabs > li.active > a,
.tabs-right > .nav-tabs > li.active > a:focus,
.tabs-right > .nav-tabs > li.active > a:hover {
  border-color: #c5d0dc;
  border-top-width: 1px;
  border-right: 2px solid #4c8fbd;
  border-left-color: transparent;
  margin: 0 -2px 0 -1px;
  -webkit-box-shadow: 2px 0 3px 0 rgba(0,0,0,0.15);
  box-shadow: 2px 0 3px 0 rgba(0,0,0,0.15);
}
.nav-tabs > li > a {
  /* icon and badges */

}
.nav-tabs > li > a > .badge {
  padding: 0 4px;
  line-height: 15px;
  opacity: 0.7;
}
.nav-tabs > li > a > [class*="icon-"] {
  opacity: 0.75;
}
.nav-tabs > li.active > a > .badge,
.nav-tabs > li.active > a > [class*="icon-"] {
  opacity: 1;
}
.nav-tabs li [class*=" icon-"],
.nav-tabs li [class^="icon-"] {
  width: 1.25em;
  display: inline-block;
  text-align: center;
}
.nav-tabs > li.open .dropdown-toggle {
  /* dropdown in tabs */

  background-color: #4F99C6;
  border-color: #4F99C6;
  color: #FFF;
}
.nav-tabs > li.open .dropdown-toggle > [class*="icon-"] {
  color: #FFF !important;
}
.tabs-left .tab-content,
.tabs-right .tab-content {
  overflow: auto;
}
.nav-pills .open .dropdown-toggle,
.nav > li.dropdown.open.active > a:hover,
.nav > li.dropdown.open.active > a:focus {
  background-color: #4F99C6;
  border-color: #4F99C6;
  color: #FFFFFF;
}
.nav-pills .open .dropdown-toggle > [class*="icon-"],
.nav > li.dropdown.open.active > a:hover > [class*="icon-"],
.nav > li.dropdown.open.active > a:focus > [class*="icon-"] {
  color: #FFF !important;
}
/* bigger tab buttons */
.nav-tabs > li:not(.active):not(.open) > a:not(:hover) > [class*="icon-"]:first-child.disabled {
  color: #909090 !important;
}
/* bigger tab buttons */
.nav-tabs.tab-size-bigger > li > a {
  padding-left: 14px;
  padding-right: 14px;
}
.nav-tabs.tab-size-bigger > li > a > [class*="icon-"]:first-child {
  display: block;
  margin-bottom: 6px;
  width: auto;
}
/* spaced tabs (top & bottom) */
.nav-tabs.tab-space-1 > li > a {
  margin-right: 1px;
}
.nav-tabs.tab-space-2 > li > a {
  margin-right: 2px;
}
.nav-tabs.tab-space-3 > li > a {
  margin-right: 3px;
}
.nav-tabs.tab-space-4 > li > a {
  margin-right: 4px;
}
/* colored tabs*/
.nav-tabs[class*="tab-color-"] > li > a,
.nav-tabs[class*="tab-color-"] > li > a:focus,
.nav-tabs[class*="tab-color-"] > li > a:hover {
  color: #FFF;
  border-color: transparent;
  margin-right: 3px;
}
.nav-tabs[class*="tab-color-"] > li > a > .badge {
  border-radius: 2px;
}
.nav-tabs[class*="tab-color-"] > li:not(.active) > a:hover {
  opacity: 0.85;
  border-color: rgba(0, 0, 0, 0.15);
  border-bottom-color: transparent;
}
.nav-tabs[class*="tab-color-"] > li:not(.active) > a > [class*="icon-"]:first-child {
  color: #FFF !important;
}
.nav-tabs[class*="tab-color-"] > li:not(.active) > a > .badge {
  color: rgba(0, 0, 0, 0.4) !important;
  background-color: #FFF !important;
  border-radius: 2px;
}
.nav-tabs.tab-color-blue > li > a,
.nav-tabs.tab-color-blue > li > a:focus {
  background-color: #7db4d8;
}
.nav-tabs[class*="tab-color-"] > li.active > a,
.nav-tabs[class*="tab-color-"] > li.active > a:focus,
.nav-tabs[class*="tab-color-"] > li.active > a:hover {
  background-color: #FFF;
  color: #4f80a0;
  box-shadow: none;
}
.nav-tabs.tab-color-blue > li.active > a,
.nav-tabs.tab-color-blue > li.active > a:focus,
.nav-tabs.tab-color-blue > li.active > a:hover {
  color: #4c718a;
  border-color: #7db4d8 #7db4d8 transparent;
}
.tabs-below .nav-tabs.tab-color-blue > li.active > a {
  border-color: transparent #7db4d8 #7db4d8;
}
.nav-tabs.tab-color-blue {
  border-bottom-color: #C5D0DC;
}
/* background for tab links */
.nav-tabs.background-blue {
  padding-top: 6px;
  background-color: #EFF3F8;
  border: 1px solid #C5D0DC;
}
.tabs-below .nav-tabs.background-blue {
  padding-top: 0;
  padding-bottom: 6px;
}
.tabs-below .nav-tabs.tab-color-blue {
  border-top: none;
  border-bottom-color: #C5D0DC;
}
/** accordion */
.accordion-style1.panel-group .panel {
  border-radius: 0;
  border-color: #cdd8e3;
  background-color: #FFF;
  box-shadow: none;
}
.accordion-style1.panel-group .panel:last-child {
  border-bottom-width: 1px;
}
.accordion-style1.panel-group .panel .collapse {
  background-color: #FFF;
}
.accordion-style1.panel-group .panel + .panel {
  margin-top: 2px;
}
.accordion-style1.panel-group .panel-heading + .panel-collapse .panel-body {
  border-top-color: #cdd8e3 !important;
}
.accordion-style1.panel-group .panel-heading {
  padding: 0;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle {
  color: #4c8fbd;
  background-color: #eef4f9;
  position: relative;
  font-weight: bold;
  font-size: 13px;
  line-height: 1;
  padding: 10px;
  display: block;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle.collapsed {
  color: #478fca;
  font-weight: normal;
  background-color: #F9F9F9;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:hover {
  color: #6ea6cc;
  background-color: #f1f8fd;
  text-decoration: none;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:focus,
.accordion-style1.panel-group .panel-heading .accordion-toggle:active {
  outline: none;
  text-decoration: none;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle > [class*="icon-"]:first-child {
  width: 16px;
}
.accordion-style1.panel-group .panel-heading .accordion-toggle:hover > [class*="icon-"]:first-child {
  text-decoration: none;
}
.accordion-style1.panel-group .panel-body,
.accordion-style1.panel-group .collapse.in > .panel-body {
  border-top: 1px solid #cdd8e3;
}
.accordion-style1.panel-group.no-padding {
  padding: 0;
}
.accordion-style2.panel-group .panel {
  border-width: 0;
}
.accordion-style2.panel-group .panel:last-child {
  border-bottom-width: 0;
}
.accordion-style2.panel-group .panel + .panel {
  margin-top: 4px;
}
.accordion-style2.panel-group .panel .panel-body {
  border-top: none;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle {
  background-color: #EDF3F7;
  border: 2px solid #6EAED1;
  border-width: 0 0 0 2px;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle:hover {
  text-decoration: none;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle.collapsed {
  background-color: #F3F3F3;
  color: #606060;
  border-width: 0 0 0 1px;
  border-color: #D9D9D9;
}
.accordion-style2.panel-group .panel-heading .accordion-toggle.collapsed:hover {
  background-color: #F6F6F6;
  color: #438EB9;
  text-decoration: none;
}
.accordion-style2.panel-group .panel-body,
.accordion-style2.panel-group .collapse.in > .panel-body {
  border-top: none;
}
/* nested questions */
.accordion-style2.panel-group .accordion-style2.panel-group .panel {
  border-bottom: 1px dotted #D9D9D9;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel:last-child {
  border-bottom: none;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading,
.accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading .accordion-toggle {
  background-color: transparent;
  border-width: 0;
  font-size: 13px;
  padding-top: 6px;
  padding-bottom: 8px;
}
.accordion-style2.panel-group .accordion-style2.panel-group .panel .panel-heading {
  padding-top: 0;
  padding-bottom: 0;
}
/* tables */th,
td,
.table-bordered {
  border-radius: 0 !important;
}
.table thead tr {
  color: #707070;
  font-weight: normal;
  background: #F2F2F2;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f8f8f8), to(#ececec));
  background-image: -webkit-linear-gradient(top, #f8f8f8, 0%, #ececec, 100%);
  background-image: -moz-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
}
.table thead tr th {
  border-color: #DDD;
  font-weight: bold;
}
.table thead tr th [class*="icon-"]:first-child {
  margin-right: 2px;
}
.table thead tr th:first-child {
  border-left-color: #F1F1F1;
}
.table thead tr th:left-child {
  border-right-color: #F1F1F1;
}
.table.table-bordered thead tr th {
  vertical-align: middle;
}
.table.table-bordered thead tr th:first-child {
  border-left-color: #DDD;
}
th.center,
td.center {
  text-align: center;
}
th .lbl,
td .lbl {
  margin-bottom: 0;
}
th .lbl:only-child,
td .lbl:only-child {
  vertical-align: top;
}
.table-header {
  background-color: #307ecc;
  color: #FFF;
  font-size: 14px;
  line-height: 38px;
  padding-left: 12px;
  margin-bottom: 1px;
}
.table-header .close {
  margin-right: 8px;
  margin-top: 0;
  opacity: 0.45;
  filter: alpha(opacity=45);
}
.table-header .close:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
/* widget boxes */
.widget-box {
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  margin: 3px 0;
  border-bottom: 1px solid #CCC;
}
@media only screen and (max-width: 767px) {
  .widget-box {
    margin-top: 7px;
    margin-bottom: 7px;
  }
}
.widget-header {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  position: relative;
  min-height: 38px;
  background: #f7f7f7;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#ffffff), to(#eeeeee));
  background-image: -webkit-linear-gradient(top, #ffffff, 0%, #eeeeee, 100%);
  background-image: -moz-linear-gradient(top, #ffffff 0%, #eeeeee 100%);
  background-image: linear-gradient(to bottom, #ffffff 0%, #eeeeee 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffffff', endColorstr='#ffeeeeee', GradientType=0);
  color: #669fc7;
  border: 1px solid #CCC;
  border-bottom: 1px solid #DDD;
  padding-left: 12px;
}
.widget-header:before,
.widget-header:after {
  content: "";
  display: table;
  line-height: 0;
}
.widget-header:after {
  clear: right;
}
.collapsed .widget-header {
  border-bottom-width: 0;
}
.collapsed .widget-body {
  display: none;
}
.widget-header-flat {
  background: #F7F7F7;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.widget-header-large {
  min-height: 49px;
  padding-left: 18px;
}
.widget-header-small {
  min-height: 31px;
  padding-left: 10px;
}
.widget-header > .widget-caption,
.widget-header > :first-child {
  line-height: 36px;
  padding: 0;
  margin: 0;
  display: inline;
}
.widget-header > .widget-caption > [class*="icon-"],
.widget-header > :first-child > [class*="icon-"] {
  margin-right: 5px;
  font-weight: normal;
  display: inline-block;
}
.widget-header-large > .widget-caption,
.widget-header-large > :first-child {
  line-height: 48px;
}
.widget-header-small > .widget-caption,
.widget-header-small > :first-child {
  line-height: 30px;
}
.widget-toolbar {
  display: inline-block;
  padding: 0 10px;
  line-height: 37px;
  float: right;
  position: relative;
  /* widget toolbar basic buttons */

  /* toolbar buttons */

}
.widget-header-large > .widget-toolbar {
  line-height: 48px;
}
.widget-header-small > .widget-toolbar {
  line-height: 29px;
}
.widget-toolbar.no-padding {
  padding: 0;
}
.widget-toolbar.padding-5 {
  padding: 0 5px;
}
.widget-toolbar:before {
  /* border on left ot it! */

  display: inline-block;
  content: "";
  position: absolute;
  top: 3px;
  bottom: 3px;
  left: -1px;
  border: 1px solid #D9D9D9;
  border-width: 0 1px 0 0;
}
.widget-header-large > .widget-toolbar:before {
  top: 6px;
  bottom: 6px;
}
[class*="header-color-"] > .widget-toolbar:before {
  border-color: #EEE;
}
.header-color-orange > .widget-toolbar:before {
  border-color: #FEA;
}
.header-color-dark > .widget-toolbar:before {
  border-color: #222;
  box-shadow: -1px 0 0 rgba(255, 255, 255, 0.2), inset 1px 0 0 rgba(255, 255, 255, 0.1);
}
.widget-toolbar.no-border:before {
  display: none;
}
.widget-toolbar label {
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0;
}
.widget-toolbar > a {
  font-size: 14px;
  margin: 0 1px;
  display: inline-block;
  padding: 0;
  line-height: 24px;
}
.widget-toolbar > a:hover {
  text-decoration: none;
}
.widget-header-large > .widget-toolbar > a {
  font-size: 15px;
  margin: 0 1px;
}
.widget-toolbar > .btn {
  line-height: 27px;
  margin-top: -2px;
}
.widget-toolbar > .btn.smaller {
  line-height: 26px;
}
.widget-toolbar > .btn.bigger {
  line-height: 28px;
}
.widget-toolbar > .btn-sm {
  line-height: 24px;
}
.widget-toolbar > .btn-sm.smaller {
  line-height: 23px;
}
.widget-toolbar > .btn-sm.bigger {
  line-height: 25px;
}
.widget-toolbar > .btn-xs {
  line-height: 22px;
}
.widget-toolbar > .btn-xs.smaller {
  line-height: 21px;
}
.widget-toolbar > .btn-xs.bigger {
  line-height: 23px;
}
.widget-toolbar > .btn-minier {
  line-height: 18px;
}
.widget-toolbar > .btn-minier.smaller {
  line-height: 17px;
}
.widget-toolbar > .btn-minier.bigger {
  line-height: 19px;
}
.widget-toolbar > .btn-lg {
  line-height: 36px;
}
.widget-toolbar > .btn-lg.smaller {
  line-height: 34px;
}
.widget-toolbar > .btn-lg.bigger {
  line-height: 38px;
}
.widget-toolbar-dark {
  background: #444;
}
.widget-toolbar-light {
  background: rgba(255, 255, 255, 0.85);
}
/* widget-toolbar buttons*/
.widget-toolbar > [data-action] > [class*="icon-"] {
  margin-right: 0;
}
.widget-toolbar > [data-action]:focus {
  text-decoration: none;
}
[class*="header-color-"] > .widget-toolbar > [data-action] {
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2);
}
[class*="header-color-"] > .widget-toolbar > [data-action="settings"] {
  color: #D3E4ED;
}
[class*="header-color-"] > .widget-toolbar > [data-action="reload"] {
  color: #DEEAD3;
}
[class*="header-color-"] > .widget-toolbar > [data-action="collapse"] {
  color: #E2E2E2;
}
[class*="header-color-"] > .widget-toolbar > [data-action="close"] {
  color: #FFD9D5;
}
.header-color-orange > .widget-toolbar > [data-action] {
  text-shadow: none;
}
.header-color-orange > .widget-toolbar > [data-action="settings"] {
  color: #559AAB;
}
.header-color-orange > .widget-toolbar > [data-action="reload"] {
  color: #7CA362;
}
.header-color-orange > .widget-toolbar > [data-action="collapse"] {
  color: #777777;
}
.header-color-orange > .widget-toolbar > [data-action="close"] {
  color: #A05656;
}
.widget-toolbar > [data-action="settings"],
.header-color-dark > .widget-toolbar > [data-action="settings"] {
  color: #99CADB;
}
.widget-toolbar > [data-action="reload"],
.header-color-dark > .widget-toolbar > [data-action="reload"] {
  color: #ACD392;
}
.widget-toolbar > [data-action="collapse"],
.header-color-dark > .widget-toolbar > [data-action="collapse"] {
  color: #AAAAAA;
}
.widget-toolbar > [data-action="close"],
.header-color-dark > .widget-toolbar > [data-action="close"] {
  color: #E09E96;
}
.widget-body {
  border: 1px solid #CCC;
  border-top: none;
  background-color: #FFF;
}
.widget-main {
  padding: 12px;
}
.widget-main.padding-32 {
  padding: 32px;
}
.widget-main.padding-30 {
  padding: 30px;
}
.widget-main.padding-28 {
  padding: 28px;
}
.widget-main.padding-26 {
  padding: 26px;
}
.widget-main.padding-24 {
  padding: 24px;
}
.widget-main.padding-22 {
  padding: 22px;
}
.widget-main.padding-20 {
  padding: 20px;
}
.widget-main.padding-18 {
  padding: 18px;
}
.widget-main.padding-16 {
  padding: 16px;
}
.widget-main.padding-14 {
  padding: 14px;
}
.widget-main.padding-12 {
  padding: 12px;
}
.widget-main.padding-10 {
  padding: 10px;
}
.widget-main.padding-8 {
  padding: 8px;
}
.widget-main.padding-6 {
  padding: 6px;
}
.widget-main.padding-4 {
  padding: 4px;
}
.widget-main.padding-2 {
  padding: 2px;
}
.widget-main.padding-0 {
  padding: 0px;
}
.widget-main.no-padding {
  padding: 0;
}
.widget-toolbar .progress {
  vertical-align: middle;
  display: inline-block;
  margin: 0;
}
/* toolbar dropdowns */
.widget-toolbar > .dropdown,
.widget-toolbar > .dropup {
  display: inline-block;
}
.widget-toolbar > .dropdown > .dropdown-menu:before,
.dropdown-menu.dropdown-caret:before {
  border-bottom: 7px solid rgba(0, 0, 0, 0.2);
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  content: "";
  display: inline-block;
  left: 9px;
  position: absolute;
  top: -7px;
}
.widget-toolbar > .dropdown > .dropdown-menu:after,
.dropdown-menu.dropdown-caret:after {
  border-bottom: 6px solid #FFFFFF;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  content: "";
  display: inline-block;
  left: 10px;
  position: absolute;
  top: -6px;
}
.widget-toolbar > .dropdown > .dropdown-menu.pull-right:before,
.dropdown-menu.pull-right.dropdown-caret:before {
  left: auto;
  right: 9px;
}
.widget-toolbar > .dropdown > .dropdown-menu.pull-right:after,
.dropdown-menu.pull-right.dropdown-caret:after {
  left: auto;
  right: 10px;
}
/* widget header colors */
.widget-header[class*="header-color-"] {
  color: #FFF;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}
.header-color-blue {
  background: #307ecc;
  border-color: #307ecc;
}
.header-color-blue + .widget-body {
  border-color: #307ecc;
}
.header-color-blue2 {
  background: #5090c1;
  border-color: #5090c1;
}
.header-color-blue2 + .widget-body {
  border-color: #5090c1;
}
.header-color-blue3 {
  background: #6379aa;
  border-color: #6379aa;
}
.header-color-blue3 + .widget-body {
  border-color: #6379aa;
}
.header-color-green {
  background: #82af6f;
  border-color: #82af6f;
}
.header-color-green + .widget-body {
  border-color: #82af6f;
}
.header-color-green2 {
  background: #2e8965;
  border-color: #2e8965;
}
.header-color-green2 + .widget-body {
  border-color: #2e8965;
}
.header-color-green3 {
  background: #4ebc30;
  border-color: #4ebc30;
}
.header-color-green3 + .widget-body {
  border-color: #4ebc30;
}
.header-color-red {
  background: #e2755f;
  border-color: #e2755f;
}
.header-color-red + .widget-body {
  border-color: #e2755f;
}
.header-color-red2 {
  background: #e04141;
  border-color: #e04141;
}
.header-color-red2 + .widget-body {
  border-color: #e04141;
}
.header-color-red3 {
  background: #d15b47;
  border-color: #d15b47;
}
.header-color-red3 + .widget-body {
  border-color: #d15b47;
}
.header-color-purple {
  background: #7e6eb0;
  border-color: #7e6eb0;
}
.header-color-purple + .widget-body {
  border-color: #7e6eb0;
}
.header-color-pink {
  background: #ce6f9e;
  border-color: #ce6f9e;
}
.header-color-pink + .widget-body {
  border-color: #ce6f9e;
}
.header-color-orange {
  background: #ffc657;
  border-color: #e8b10d;
  color: #855d10 !important;
}
.header-color-orange + .widget-body {
  border-color: #e8b10d;
}
.header-color-dark {
  background: #404040;
  border-color: #454545;
}
.header-color-dark + .widget-body {
  border-color: #666666;
}
.header-color-grey {
  background: #848484;
  border-color: #989898;
}
.header-color-grey + .widget-body {
  border-color: #aaaaaa;
}
/* different borders */
.widget-box.light-border > [class*="header-color-"] + .widget-body {
  border-color: #D6D6D6 !important;
}
.widget-box.no-border {
  border-bottom: none;
}
.widget-box.no-border > .widget-body {
  border: none;
}
/* transparent box */
.widget-box.transparent {
  border: none;
  box-shadow: none;
}
.widget-box.transparent > .widget-header {
  background: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
  border: none;
  border-bottom: 1px solid #DCE8F1;
  color: #4383B4;
  padding-left: 3px;
}
.widget-box.transparent > .widget-header-large {
  padding-left: 5px;
}
.widget-box.transparent > .widget-header-small {
  padding-left: 1px;
}
.widget-box.transparent .widget-body {
  border: none;
  background-color: transparent;
}
.widget-box.transparent .widget-main.no-padding-left {
  padding-left: 0;
}
.widget-box.transparent .widget-main.no-padding-right {
  padding-right: 0;
}
.widget-box.transparent .widget-main.no-padding-top {
  padding-top: 0;
}
.widget-box.transparent .widget-main.no-padding-bottom {
  padding-bottom: 0;
}
/* table in widget */
.widget-body .table {
  border-top: 1px solid #E5E5E5;
}
.widget-body .table thead:first-child tr {
  background: #FFF;
}
[class*="header-color-"] + .widget-body .table thead:first-child tr {
  background: #f2f2f2;
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#f8f8f8), to(#ececec));
  background-image: -webkit-linear-gradient(top, #f8f8f8, 0%, #ececec, 100%);
  background-image: -moz-linear-gradient(top, #f8f8f8 0%, #ececec 100%);
  background-image: linear-gradient(to bottom, #f8f8f8 0%, #ececec 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff8f8f8', endColorstr='#ffececec', GradientType=0);
}
.widget-body .table.table-bordered thead:first-child > tr {
  border-top: none;
}
.widget-main.no-padding .table,
.widget-main.padding-0 .table {
  margin-bottom: 0;
  border: none;
}
.widget-main.no-padding .table-bordered th:first-child,
.widget-main.padding-0 .table-bordered th:first-child,
.widget-main.no-padding .table-bordered td:first-child,
.widget-main.padding-0 .table-bordered td:first-child {
  border-left-width: 0;
}
.transparent .widget-main .table-bordered > thead > tr > th:last-child,
.widget-main.no-padding .table-bordered > thead > tr > th:last-child,
.transparent .widget-main .table-bordered > tbody > tr > td:last-child,
.widget-main.no-padding .table-bordered > tbody > tr > td:last-child,
.transparent .widget-main .table-bordered > tfoot > tr > td:last-child,
.widget-main.no-padding .table-bordered > tfoot > tr > td:last-child {
  border-right-width: 0 !important;
}
.transparent .widget-main .table-bordered > tbody > tr:last-child > td,
.widget-main.no-padding .table-bordered > tbody > tr:last-child > td {
  border-bottom-width: 0 !important;
}
.table-bordered > thead.thin-border-bottom > tr > th,
.table-bordered > thead.thin-border-bottom > tr > td {
  border-bottom-width: 1px;
}
/* elements in widget */
.widget-body .alert:last-child {
  margin-bottom: 0;
}
/* tabs in widget */
.widget-main .tab-content {
  border: none;
}
.widget-toolbar > .nav-tabs {
  border-bottom: none;
  margin-bottom: 0;
  position: relative;
  top: 5px;
}
.widget-toolbar > .nav-tabs > li > a {
  box-shadow: none;
}
.widget-toolbar > .nav-tabs > li:not(.active) > a {
  border-color: transparent;
  background-color: transparent;
}
.widget-toolbar > .nav-tabs > li:not(.active) > a:hover {
  background-color: transparent;
}
.widget-toolbar > .nav-tabs > li.active > a {
  background-color: #FFF;
  border-bottom-color: transparent;
  box-shadow: none;
}
.widget-header-small > .widget-toolbar > .nav-tabs {
  top: 5px;
}
.widget-header-small > .widget-toolbar > .nav-tabs > li > a {
  line-height: 16px;
  padding-top: 6px;
  padding-bottom: 6px;
}
.widget-header-small > .widget-toolbar > .nav-tabs > li.active > a {
  border-top-width: 2px;
  margin-top: -1px;
}
.widget-header-large > .widget-toolbar > .nav-tabs {
  top: 8px;
}
.widget-header-large > .widget-toolbar > .nav-tabs > li > a {
  line-height: 22px;
  padding-top: 9px;
  padding-bottom: 9px;
}
[class*="header-color-"] > .widget-toolbar > .nav-tabs > li > a {
  border-color: transparent;
  background-color: transparent;
  color: #FFF;
  margin-right: 1px;
}
[class*="header-color-"] > .widget-toolbar > .nav-tabs > li > a:hover {
  background-color: #FFF;
  color: #555;
  border-top-color: #FFF;
}
[class*="header-color-"] > .widget-toolbar > .nav-tabs > li.active > a {
  background-color: #FFF;
  color: #555;
  border-top-width: 1px;
  margin-top: 0;
}
.header-color-orange > .widget-toolbar > .nav-tabs > li > a {
  color: #855D10;
}
.transparent .widget-toolbar > .nav-tabs > li > a {
  color: #555;
  background-color: transparent;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}
.transparent .widget-toolbar > .nav-tabs > li.active > a {
  border-top-color: #4C8FBD;
  border-right: 1px solid #C5D0DC;
  border-left: 1px solid #C5D0DC;
  background-color: #FFF;
  box-shadow: none;
}
/* toolbox */
.widget-toolbox {
  background-color: #EEE;
}
.widget-toolbox:first-child {
  /* the toolbox coming before content */

  padding: 2px;
  border-bottom: 1px solid #CCC;
}
.widget-toolbox:last-child {
  /* the toolbox coming after content */

  padding: 2px;
  border-top: 1px solid #CCC;
}
.transparent .widget-toolbox:last-child {
  border: none;
  border-top: 1px solid #CCC;
}
.widget-toolbox > .btn-toolbar {
  margin: 0;
  padding: 0;
}
.widget-toolbox.center {
  text-align: center;
}
.widget-toolbox.padding-16 {
  padding: 16px;
}
.widget-toolbox.padding-14 {
  padding: 14px;
}
.widget-toolbox.padding-12 {
  padding: 12px;
}
.widget-toolbox.padding-10 {
  padding: 10px;
}
.widget-toolbox.padding-8 {
  padding: 8px;
}
.widget-toolbox.padding-6 {
  padding: 6px;
}
.widget-toolbox.padding-4 {
  padding: 4px;
}
.widget-toolbox.padding-2 {
  padding: 2px;
}
.widget-toolbox.padding-0 {
  padding: 0px;
}
/*when clicking refresh*/
.widget-box-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 21;
}
.widget-box-overlay > [class*="icon-"] {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  text-align: center;
}
.widget-box.collapsed .widget-box-overlay > [class*="icon-"] {
  top: 5%;
}
.widget-box-overlay > .icon-spin {
  -moz-animation-duration: 1.2s;
  -webkit-animation-duration: 1.2s;
  -o-animation-duration: 1.2s;
  -ms-animation-duration: 1.2s;
  animation-duration: 1.2s;
}
/* simple forms in widgetboxes */
.widget-main > form {
  margin-bottom: 0;
}
.widget-main > form .input-append,
.widget-main > form .input-prepend {
  margin-bottom: 0;
}
.widget-main.no-padding > form > fieldset,
.widget-main.padding-0 > form > fieldset {
  padding: 16px;
}
.widget-main.no-padding > form > fieldset + .form-actions,
.widget-main.padding-0 > form > fieldset + .form-actions {
  padding: 10px 0 12px;
}
.widget-main.no-padding > form > .form-actions,
.widget-main.padding-0 > form > .form-actions {
  margin: 0;
  padding: 10px 12px 12px;
}
/* for dragging and dropping */
.widget-placeholder {
  border: 2px dashed #D9D9D9;
}
/* tooltips and popovers */
.tooltip.in {
  opacity: 1;
  filter: alpha(opacity=100);
}
.tooltip-inner {
  background-color: #333333;
  color: #FFF;
  font-size: 12px;
  text-shadow: 1px 1px 0 rgba(42, 45, 50, 0.5);
  border-radius: 0;
  padding: 5px 9px;
}
.tooltip.top .tooltip-arrow {
  border-top-color: #333333;
}
.tooltip.right .tooltip-arrow {
  border-right-color: #333333;
}
.tooltip.left .tooltip-arrow {
  border-left-color: #333333;
}
.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #333333;
}
/* error tooltip */
.tooltip-error + .tooltip > .tooltip-inner {
  background-color: #c94d32;
  color: #FFF;
  text-shadow: 1px 1px 0 rgba(100, 60, 20, 0.3);
  border-radius: 0;
}
.tooltip-error + .tooltip.top .tooltip-arrow {
  border-top-color: #c94d32;
}
.tooltip-error + .tooltip.right .tooltip-arrow {
  border-right-color: #c94d32;
}
.tooltip-error + .tooltip.left .tooltip-arrow {
  border-left-color: #c94d32;
}
.tooltip-error + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #c94d32;
}
/* success tooltip */
.tooltip-success + .tooltip > .tooltip-inner {
  background-color: #629b58;
  color: #FFF;
  text-shadow: 1px 1px 0 rgba(60, 100, 20, 0.3);
  border-radius: 0;
}
.tooltip-success + .tooltip.top .tooltip-arrow {
  border-top-color: #629b58;
}
.tooltip-success + .tooltip.right .tooltip-arrow {
  border-right-color: #629b58;
}
.tooltip-success + .tooltip.left .tooltip-arrow {
  border-left-color: #629b58;
}
.tooltip-success + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #629b58;
}
/* warning tooltip */
.tooltip-warning + .tooltip > .tooltip-inner {
  background-color: #ed9421;
  color: #FFF;
  text-shadow: 1px 1px 0 rgba(100, 90, 10, 0.3);
  border-radius: 0;
}
.tooltip-warning + .tooltip.top .tooltip-arrow {
  border-top-color: #ed9421;
}
.tooltip-warning + .tooltip.right .tooltip-arrow {
  border-right-color: #ed9421;
}
.tooltip-warning + .tooltip.left .tooltip-arrow {
  border-left-color: #ed9421;
}
.tooltip-warning + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #ed9421;
}
/* info tooltip */
.tooltip-info + .tooltip > .tooltip-inner {
  background-color: #4b89aa;
  color: #FFF;
  text-shadow: 1px 1px 0 rgba(40, 50, 100, 0.3);
  border-radius: 0;
}
.tooltip-info + .tooltip.top .tooltip-arrow {
  border-top-color: #4b89aa;
}
.tooltip-info + .tooltip.right .tooltip-arrow {
  border-right-color: #4b89aa;
}
.tooltip-info + .tooltip.left .tooltip-arrow {
  border-left-color: #4b89aa;
}
.tooltip-info + .tooltip.bottom .tooltip-arrow {
  border-bottom-color: #4b89aa;
}
/* popover */
.popover {
  border-radius: 0;
  padding: 0;
  border-color: #ccc;
  border-width: 1px;
  -webkit-box-shadow: 0 0 4px 2px rgba(0,0,0,0.2);
  box-shadow: 0 0 4px 2px rgba(0,0,0,0.2);
  color: #4D6883;
}
.popover-title {
  border-radius: 0;
  background-color: #EFF3F8;
  color: #555;
  border-bottom: 1px solid #DFE3E8;
  text-shadow: 1px 1px 1px rgba(220, 220, 220, 0.2);
}
.popover.bottom .arrow:after {
  top: 1px;
  margin-left: -10px;
  border-bottom-color: #EFF3F8;
  border-top-width: 0;
}
.tooltip-error + .popover {
  color: #555;
  border: 1px solid #F7F0EF;
  /*
	&.top .arrow:after {
		border-top-color:#F7F0EF;
	}
	&.bottom .arrow:after {
		border-bottom-color:#F7F0EF;
	}
	&.right .arrow:after {
		border-right-color:#F7F0EF;
	}
	&.left .arrow:after {
		border-left-color:#F7F0EF;
	}
	*/

}
.tooltip-error + .popover .popover-title {
  background-color: #F7F0EF;
  border-bottom-color: #E8E0DF;
  color: #B75445;
  text-shadow: none;
}
.tooltip-warning + .popover {
  color: #555;
  border: 1px solid #F4EEE3;
  /*
	&.top .arrow:after {
		border-top-color:#F4EEE3;
	}
	&.bottom .arrow:after {
		border-bottom-color:#F4EEE3;
	}
	&.right .arrow:after {
		border-right-color:#F4EEE3;
	}
	&.left .arrow:after {
		border-left-color:#F4EEE3;
	}
	*/

}
.tooltip-warning + .popover .popover-title {
  background-color: #F4EEE3;
  border-bottom-color: #E4DCD3;
  color: #D67E31;
  text-shadow: none;
}
.tooltip-success + .popover {
  color: #555;
  border: 1px solid #E8F2E3;
  /*
	&.top .arrow:after {
		border-top-color:#E8F2E3;
	}
	&.bottom .arrow:after {
		border-bottom-color:#E8F2E3;
	}
	&.right .arrow:after {
		border-right-color:#E8F2E3;
	}
	&.left .arrow:after {
		border-left-color:#E8F2E3;
	}
	*/

}
.tooltip-success + .popover .popover-title {
  background-color: #E8F2E3;
  border-bottom-color: #D8E2D3;
  color: #629b58;
  text-shadow: none;
}
.tooltip-info + .popover {
  color: #555;
  border: 1px solid #E5EDF8;
  /*
	&.top .arrow:after {
		border-top-color:#E5EDF8;
	}
	&.bottom .arrow:after {
		border-bottom-color:#E5EDF8;
	}
	&.right .arrow:after {
		border-right-color:#E5EDF8;
	}
	&.left .arrow:after {
		border-left-color:#E5EDF8;
	}
	*/

}
.tooltip-info + .popover .popover-title {
  background-color: #E5EDF8;
  border-bottom-color: #D5DDE8;
  color: #3F79B6;
  text-shadow: none;
}
.popover-notitle + .popover .popover-title {
  display: none;
}
.popover-notitle + .popover.top .arrow:after {
  border-top-color: #FFF;
}
.popover-notitle + .popover.bottom .arrow:after {
  border-bottom-color: #FFF;
}
.popover-notitle + .popover.left .arrow:after {
  border-left-color: #FFF;
}
.popover-notitle + .popover.right .arrow:after {
  border-left-color: #FFF;
}
/* progressbar */
.progress {
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  background: #dadada;
  height: 18px;
}
.progress .progress-bar {
  -webkit-box-shadow: none;
  box-shadow: none;
  line-height: 18px;
}
.progress[data-percent] {
  position: relative;
}
.progress[data-percent]:after {
  display: inline-block;
  content: attr(data-percent);
  color: #FFF;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  line-height: 16px;
  text-align: center;
  font-size: 12px;
  font-family: Verdana;
}
.progress.progress-yellow[data-percent]:after {
  color: #996633;
}
.progress.progress-small {
  height: 12px;
}
.progress.progress-small .progress-bar {
  line-height: 10px;
  font-size: 11px;
}
.progress.progress-small[data-percent]:after {
  line-height: 10px;
  font-size: 11px;
}
.progress.progress-mini {
  height: 9px;
}
.progress.progress-mini .progress-bar {
  line-height: 8px;
  font-size: 11px;
}
.progress.progress-mini[data-percent]:after {
  line-height: 8px;
  font-size: 11px;
}
.progress-bar {
  background-color: #2a91d8;
}
.progress-striped .progress-bar {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-danger {
  background-color: #ca5952;
}
.progress-striped .progress-bar-danger {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-success {
  background-color: #59a84b;
}
.progress-striped .progress-bar-success {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-warning {
  background-color: #f2bb46;
}
.progress-striped .progress-bar-warning {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-pink {
  background-color: #d6487e;
}
.progress-striped .progress-bar-pink {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-purple {
  background-color: #9585bf;
}
.progress-striped .progress-bar-purple {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-yellow {
  background-color: #ffd259;
}
.progress-striped .progress-bar-yellow {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-inverse {
  background-color: #404040;
}
.progress-striped .progress-bar-inverse {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress-bar-grey {
  background-color: #8a8a8a;
}
.progress-striped .progress-bar-grey {
  background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.15)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.15)), color-stop(0.75, rgba(255, 255, 255, 0.15)), color-stop(0.75, transparent), to(transparent));
  background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
}
.progress {
  position: relative;
}
.progress:before {
  display: inline-block;
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: radial-gradient(9px 9px 0deg, circle cover, #00ffff 0%, rgba(0, 0, 255, 0) 100%, #0000ff 95%);
}
/** dashboard info and stats mini boxes **/
.infobox-container {
  text-align: center;
  font-size: 0;
}
.infobox {
  display: inline-block;
  width: 210px;
  height: 66px;
  color: #555;
  background-color: #FFF;
  box-shadow: none;
  border-radius: 0;
  margin: -1px 0 0 -1px;
  padding: 8px 3px 6px 9px;
  border: 1px dotted;
  border-color: #D8D8D8 !important;
  vertical-align: middle;
  text-align: left;
  position: relative;
}
.infobox > .infobox-icon {
  display: inline-block;
  vertical-align: top;
  width: 44px;
}
.infobox > .infobox-icon > [class*="icon-"] {
  display: inline-block;
  height: 42px;
  margin: 0;
  padding: 1px 1px 0 2px;
  background-color: transparent;
  border: none;
  text-align: center;
  position: relative;
  border-radius: 100%;
  -webkit-box-shadow: 1px 1px 0 rgba(0,0,0,0.2);
  box-shadow: 1px 1px 0 rgba(0,0,0,0.2);
}
.infobox > .infobox-icon > [class*="icon-"]:before {
  font-size: 24px;
  display: block;
  padding: 6px 0 7px;
  width: 40px;
  text-align: center;
  border-radius: 100%;
  color: rgba(255, 255, 255, 0.9);
  background-color: rgba(255, 255, 255, 0.2);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
}
.infobox .infobox-content {
  color: #555;
}
.infobox .infobox-content:first-child {
  /* like in small infoboxes that have no number data etc */

  font-weight: bold;
}
.infobox > .infobox-data {
  display: inline-block;
  border: none;
  border-top-width: 0;
  font-size: 13px;
  text-align: left;
  line-height: 21px;
  min-width: 130px;
  padding-left: 8px;
  position: relative;
  top: 0;
}
.infobox > .infobox-data > .infobox-data-number {
  display: block;
  font-size: 22px;
  margin: 2px 0 4px;
  position: relative;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.15);
}
.infobox > .infobox-data > .infobox-text {
  display: block;
  font-size: 16px;
  margin: 2px 0 4px;
  position: relative;
  text-shadow: none;
}
.infobox.no-border {
  border: none !important;
}
@media only screen and (max-width: 460px) {
  .infobox {
    min-width: 95%;
    margin-top: 2px;
    margin-bottom: 2px;
  }
}
.infobox-purple {
  color: #6f3cc4;
  border-color: #6f3cc4;
}
.infobox-purple > .infobox-icon > [class*="icon-"] {
  background-color: #6f3cc4;
}
.infobox-purple.infobox-dark {
  background-color: #6f3cc4;
  border-color: #6f3cc4;
}
.infobox-purple2 {
  color: #5f47b0;
  border-color: #5f47b0;
}
.infobox-purple2 > .infobox-icon > [class*="icon-"] {
  background-color: #5f47b0;
}
.infobox-purple2.infobox-dark {
  background-color: #5f47b0;
  border-color: #5f47b0;
}
.infobox-pink {
  color: #cb6fd7;
  border-color: #cb6fd7;
}
.infobox-pink > .infobox-icon > [class*="icon-"] {
  background-color: #cb6fd7;
}
.infobox-pink.infobox-dark {
  background-color: #cb6fd7;
  border-color: #cb6fd7;
}
.infobox-blue {
  color: #6fb3e0;
  border-color: #6fb3e0;
}
.infobox-blue > .infobox-icon > [class*="icon-"] {
  background-color: #6fb3e0;
}
.infobox-blue.infobox-dark {
  background-color: #6fb3e0;
  border-color: #6fb3e0;
}
.infobox-blue2 {
  color: #3983c2;
  border-color: #3983c2;
}
.infobox-blue2 > .infobox-icon > [class*="icon-"] {
  background-color: #3983c2;
}
.infobox-blue2.infobox-dark {
  background-color: #3983c2;
  border-color: #3983c2;
}
.infobox-blue3 {
  color: #1144eb;
  border-color: #1144eb;
}
.infobox-blue3 > .infobox-icon > [class*="icon-"] {
  background-color: #1144eb;
}
.infobox-blue3.infobox-dark {
  background-color: #1144eb;
  border-color: #1144eb;
}
.infobox-red {
  color: #d53f40;
  border-color: #d53f40;
}
.infobox-red > .infobox-icon > [class*="icon-"] {
  background-color: #d53f40;
}
.infobox-red.infobox-dark {
  background-color: #d53f40;
  border-color: #d53f40;
}
.infobox-brown {
  color: #c67a3e;
  border-color: #c67a3e;
}
.infobox-brown > .infobox-icon > [class*="icon-"] {
  background-color: #c67a3e;
}
.infobox-brown.infobox-dark {
  background-color: #c67a3e;
  border-color: #c67a3e;
}
.infobox-wood {
  color: #7b3f25;
  border-color: #7b3f25;
}
.infobox-wood > .infobox-icon > [class*="icon-"] {
  background-color: #7b3f25;
}
.infobox-wood.infobox-dark {
  background-color: #7b3f25;
  border-color: #7b3f25;
}
.infobox-light-brown {
  color: #cebea5;
  border-color: #cebea5;
}
.infobox-light-brown > .infobox-icon > [class*="icon-"] {
  background-color: #cebea5;
}
.infobox-light-brown.infobox-dark {
  background-color: #cebea5;
  border-color: #cebea5;
}
.infobox-orange {
  color: #e8b110;
  border-color: #e8b110;
}
.infobox-orange > .infobox-icon > [class*="icon-"] {
  background-color: #e8b110;
}
.infobox-orange.infobox-dark {
  background-color: #e8b110;
  border-color: #e8b110;
}
.infobox-orange2 {
  color: #f79263;
  border-color: #f79263;
}
.infobox-orange2 > .infobox-icon > [class*="icon-"] {
  background-color: #f79263;
}
.infobox-orange2.infobox-dark {
  background-color: #f79263;
  border-color: #f79263;
}
.infobox-green {
  color: #9abc32;
  border-color: #9abc32;
}
.infobox-green > .infobox-icon > [class*="icon-"] {
  background-color: #9abc32;
}
.infobox-green.infobox-dark {
  background-color: #9abc32;
  border-color: #9abc32;
}
.infobox-green2 {
  color: #0490a6;
  border-color: #0490a6;
}
.infobox-green2 > .infobox-icon > [class*="icon-"] {
  background-color: #0490a6;
}
.infobox-green2.infobox-dark {
  background-color: #0490a6;
  border-color: #0490a6;
}
.infobox-grey {
  color: #999999;
  border-color: #999999;
}
.infobox-grey > .infobox-icon > [class*="icon-"] {
  background-color: #999999;
}
.infobox-grey.infobox-dark {
  background-color: #999999;
  border-color: #999999;
}
.infobox-black {
  color: #393939;
  border-color: #393939;
}
.infobox-black > .infobox-icon > [class*="icon-"] {
  background-color: #393939;
}
.infobox-black.infobox-dark {
  background-color: #393939;
  border-color: #393939;
}
.infobox-dark {
  margin: 1px 1px 0 0;
  border-color: transparent !important;
  border: none;
  color: #FFF;
  padding: 4px;
}
.infobox-dark > .infobox-icon > [class*="icon-"],
.infobox-dark > .infobox-icon > [class*="icon-"]:before {
  background-color: transparent;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0;
  font-size: 30px;
}
.infobox-dark > .infobox-icon > [class*="icon-"]:before {
  opacity: 1;
  filter: alpha(opacity=100);
}
.infobox-dark .infobox-content {
  color: #FFF;
}
.infobox {
  /* stat trend indicators and badges */

}
.infobox > .infobox-progress {
  padding-top: 0;
  display: inline-block;
  vertical-align: top;
  width: 44px;
}
.infobox > .infobox-chart {
  padding-top: 0;
  display: inline-block;
  vertical-align: text-bottom;
  width: 44px;
  text-align: center;
}
.infobox > .infobox-chart canvas {
  vertical-align: middle !important;
}
.infobox > .stat {
  display: inline-block;
  position: absolute;
  right: 20px;
  top: 11px;
  text-shadow: none;
  color: #ABBAC3;
  font-size: 13px;
  font-weight: bold;
  padding-right: 18px;
  padding-top: 3px;
}
.infobox > .stat:before {
  display: inline-block;
  content: "";
  width: 8px;
  height: 11px;
  background-color: #ABBAC3;
  position: absolute;
  right: 4px;
  top: 7px;
}
.infobox > .stat:after {
  display: inline-block;
  content: "";
  position: absolute;
  right: 1px;
  top: -8px;
  border: 12px solid transparent;
  border-width: 8px 7px;
  border-bottom-color: #ABBAC3;
}
.infobox > .stat.stat-success {
  /*pointing up*/

  color: #77C646;
}
.infobox > .stat.stat-success:before {
  background-color: #77C646;
}
.infobox > .stat.stat-success:after {
  border-bottom-color: #77C646;
}
.infobox > .stat.stat-important {
  /*pointing down*/

  color: #E4564F;
}
.infobox > .stat.stat-important:before {
  background-color: #E4564F;
  top: 3px;
}
.infobox > .stat.stat-important:after {
  border-top-color: #E4564F;
  border-bottom-color: transparent;
  bottom: -6px;
  top: auto;
}
.infobox.infobox-dark > .stat {
  color: #FFF;
}
.infobox.infobox-dark > .stat:before {
  background-color: #E1E5E8;
}
.infobox.infobox-dark > .stat:after {
  border-bottom-color: #E1E5E8;
}
.infobox.infobox-dark > .stat.stat-success {
  color: #FFF;
}
.infobox.infobox-dark > .stat.stat-success:before {
  background-color: #D0E29E;
}
.infobox.infobox-dark > .stat.stat-success:after {
  border-bottom-color: #D0E29E;
}
.infobox.infobox-dark > .stat.stat-important {
  color: #FFF;
}
.infobox.infobox-dark > .stat.stat-important:before {
  background-color: #FF8482;
  top: 3px;
}
.infobox.infobox-dark > .stat.stat-important:after {
  border-top-color: #FF8482;
  border-bottom-color: transparent;
  bottom: -6px;
  top: auto;
}
.infobox > .badge {
  position: absolute;
  right: 20px;
  top: 11px;
  border-radius: 0;
  text-shadow: none;
  color: #FFF;
  font-size: 11px;
  font-weight: bold;
  line-height: 15px;
  height: 16px;
  padding: 0 1px;
}
.infobox.infobox-dark > .badge {
  color: #FFF;
  background-color: rgba(255, 255, 255, 0.2) !important;
  border: 1px solid #F1F1F1;
  top: 2px;
  right: 2px;
}
.infobox.infobox-dark > .badge.badge-success > [class*="icon-"] {
  color: #C6E9A1;
}
.infobox.infobox-dark > .badge.badge-important > [class*="icon-"] {
  color: #ECB792;
}
.infobox.infobox-dark > .badge.badge-warning > [class*="icon-"] {
  color: #ECB792;
}
.infobox-small {
  width: 135px;
  height: 52px;
  text-align: left;
  padding-bottom: 5px;
}
.infobox-small > .infobox-icon,
.infobox-small > .infobox-chart,
.infobox-small > .infobox-progress {
  display: inline-block;
  width: 40px;
  max-width: 40px;
  height: 42px;
  line-height: 38px;
  vertical-align: middle;
}
.infobox-small > .infobox-data {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  max-width: 72px;
  min-width: 0;
}
.percentage {
  font-size: 14px;
  font-weight: bold;
  display: inline-block;
  vertical-align: top;
}
.infobox-small .percentage {
  font-size: 13px;
  font-weight: normal;
  margin-top: 2px;
  margin-left: 2px;
}
/* pricing table */
.pricing-box {
  /* the purchase button */

}
.pricing-box:not(:first-child) {
  padding-left: 7px;
}
.pricing-box:not(:last-child) {
  padding-right: 7px;
}
.pricing-box .price {
  font-size: 22px;
  line-height: 20px;
  height: 28px;
  text-align: center;
  color: #555;
}
.pricing-box .price small {
  font-size: 14px;
}
.pricing-box .btn {
  font-size: 16px;
}
.pricing-box .widget-header {
  /* the title */

  text-align: center;
  padding-left: 0;
}
@media only screen and (max-width: 768px) {
  .pricing-box {
    margin: 0;
    margin-bottom: 16px;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: -1px;
  }
  .pricing-box:nth-child(odd) {
    padding-left: 12px !important;
  }
  .pricing-box:nth-child(even) {
    padding-right: 12px !important;
  }
}
@media only screen and (max-width: 460px) {
  .pricing-box {
    margin: 0;
    margin-bottom: 16px;
    width: 100%;
    padding-left: 12px !important;
    padding-right: 12px !important;
  }
}
.pricing-table-header {
  padding-top: 0;
  margin-top: 0;
  text-align: left;
}
.pricing-table-header > li {
  padding: 7px 0 7px 11px;
  font-size: 13px;
}
.pricing-table {
  margin-top: 0;
}
.pricing-table > li {
  text-align: center;
  padding: 7px 0;
  font-size: 13px;
}
.list-striped > li:nth-child(odd) {
  background-color: #FFF;
}
.list-striped > li:nth-child(even) {
  background-color: #F2F3EB;
}
.list-striped.pricing-table-header > li:nth-child(even) {
  background-color: #EEE;
}
.pricing-box-small {
  box-shadow: none;
  margin-left: -2px;
  background-color: #FFF;
  position: relative;
  z-index: 10;
}
.pricing-box-small .price {
  line-height: 20px;
  height: 28px;
  text-align: center;
}
.pricing-box-small .price .label:before,
.pricing-box-small .price .label:after {
  margin-top: -2px;
  opacity: 0.9;
  filter: alpha(opacity=90);
}
.pricing-box-small:hover {
  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.15);
  z-index: 11;
  -webkit-transform: scale(1.04);
  -ms-transform: scale(1.04);
  transform: scale(1.04);
}
.pricing-box-small:hover .price > .label {
  -webkit-transform: scale(0.96);
  -ms-transform: scale(0.96);
  transform: scale(0.96);
}
.pricing-span {
  margin: 0;
  width: 19%;
  max-width: 150px !important;
  min-width: 110px !important;
  float: left !important;
}
.pricing-span-header {
  padding-right: 0;
}
@media only screen and (min-width: 481px) {
  .pricing-span-body {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 480px) {
  .pricing-span-header,
  .pricing-span-body {
    width: 100%;
    padding-right: 12px;
  }
}
/* login pages */
.login-container {
  width: 375px;
  margin: 0 auto;
}
.login-layout {
  background-color: #1D2024;
}
.login-layout .main-container:after {
  display: none;
}
.login-layout .main-content {
  margin-left: 0;
  min-height: 100%;
  padding-left: 15px;
  padding-right: 15px;
}
.login-layout label {
  margin-bottom: 11px;
}
.login-layout .widget-box {
  visibility: hidden;
  position: absolute;
  overflow: hidden;
  width: 100%;
  border-bottom: none;
  box-shadow: none;
  padding: 6px;
  background-color: #394557;
  -moz-transform: scale(0,1) translate(-150px);
  -webkit-transform: scale(0,1) translate(-150px);
  -o-transform: scale(0,1) translate(-150px);
  -ms-transform: scale(0,1) translate(-150px);
  transform: scale(0,1) translate(-150px);
}
.login-layout .widget-box.visible {
  visibility: visible;
  -moz-transform: scale(1,1) translate(0);
  -webkit-transform: scale(1,1) translate(0);
  -o-transform: scale(1,1) translate(0);
  -ms-transform: scale(1,1) translate(0);
  transform: scale(1,1) translate(0);
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  -o-transition: none;
  /* too slow */

  -webkit-transition: none;
  /* works in chrome but not in safari, never scales back to 1! */

}
.login-layout .widget-box .widget-main {
  padding: 16px 36px 36px;
  background: #F7F7F7;
}
.login-layout .widget-box .widget-main form {
  margin: 0;
}
.login-layout .widget-box .widget-body .toolbar > div > a {
  font-size: 15px;
  font-weight: 400;
  text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
}
.login-box .forgot-password-link {
  color: #FE9;
}
.login-box .user-signup-link {
  color: #CF7;
}
.login-box .toolbar {
  background: #5090C1;
  border-top: 2px solid #597597;
}
.login-box .toolbar > div {
  width: 50%;
  display: inline-block;
  padding: 9px 0 11px;
}
.login-box .toolbar > div:first-child {
  float: left;
  text-align: left;
}
.login-box .toolbar > div:first-child > a {
  margin-left: 11px;
}
.login-box .toolbar > div:first-child + div {
  float: right;
  text-align: right;
}
.login-box .toolbar > div:first-child + div > a {
  margin-right: 11px;
}
.forgot-box .toolbar {
  background: #C16050;
  border-top: 2px solid #976559;
  padding: 9px 18px;
}
.signup-box .toolbar {
  background: #76B774;
  border-top: 2px solid #759759;
  padding: 9px 18px;
}
.forgot-box .back-to-login-link,
.signup-box .back-to-login-link {
  color: #FE9;
  font-size: 14px;
  font-weight: bold;
  text-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
}
/* social login */
.login-layout .login-box .widget-main {
  padding-bottom: 16px;
}
.login-box .social-or-login {
  margin-top: 4px;
  position: relative;
  z-index: 1;
}
.login-box .social-or-login :first-child {
  display: inline-block;
  background: #F7F7F7;
  padding: 0 8px;
  color: #5090C1;
  font-size: 13px;
}
.login-box .social-or-login:before {
  content: "";
  display: block;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px dotted #A6C4DB;
}
.login-box .social-login {
  margin-top: 12px;
}
.login-box .social-login a {
  border-radius: 100%;
  width: 42px;
  height: 42px;
  line-height: 46px;
  padding: 0;
  margin: 0 1px;
  border: none;
}
.login-box .social-login a > [class*="icon-"] {
  font-size: 24px;
  margin: 0;
}
/* loginbox */
@media only screen and (max-width: 480px) {
  .login-layout .widget-box .widget-main {
    padding: 16px;
  }
}
@media only screen and (max-width: 480px) {
  .login-container {
    width: 98%;
  }
  .login-layout .widget-box {
    padding: 0;
  }
  .login-box .toolbar > div {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .login-layout .widget-box.visible {
    -webkit-transition: none;
    transition: none;
  }
}
/* invoice */
.invoice-info {
  line-height: 24px !important;
  color: #444444;
  vertical-align: bottom;
  margin-left: 9px;
  margin-right: 9px;
}
.invoice-info-label {
  display: inline-block;
  max-width: 100px;
  text-align: right;
  font-size: 14px;
}
.invoice-box .label-large[class*="arrowed"] {
  margin-left: 11px !important;
  max-width: 95%;
}
/* error pages*/
.error-container {
  margin: 20px;
  padding: 0;
  background: #FFF;
}
/* gallery */
.ace-thumbnails {
  list-style: none;
  margin: 0;
  padding: 0;
}
.ace-thumbnails > li {
  float: left;
  display: block;
  position: relative;
  overflow: hidden;
  margin: 2px;
  border: 2px solid #333;
  /* the custom text on hover */

}
.ace-thumbnails > li > :first-child {
  display: block;
  position: relative;
}
.ace-thumbnails > li > .tools {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -30px;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.55);
  text-align: center;
  vertical-align: middle;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ace-thumbnails > li > .tools.tools-right {
  left: auto;
  right: -30px;
}
.ace-thumbnails > li > .tools.tools-bottom {
  width: auto;
  height: 28px;
  left: 0;
  right: 0;
  top: auto;
  bottom: -30px;
}
.ace-thumbnails > li > .tools.tools-top {
  width: auto;
  height: 28px;
  left: 0;
  right: 0;
  top: -30px;
  bottom: auto;
}
.ace-thumbnails > li:hover > .tools {
  left: 0;
}
.ace-thumbnails > li:hover > .tools.tools-bottom {
  top: auto;
  bottom: 0;
}
.ace-thumbnails > li:hover > .tools.tools-top {
  bottom: auto;
  top: 0;
}
.ace-thumbnails > li:hover > .tools.tools-right {
  left: auto;
  right: 0;
}
.ace-thumbnails > li > .tools > a,
.ace-thumbnails > li > :first-child .inner a {
  display: inline-block;
  color: #FFF;
  font-size: 18px;
  font-weight: normal;
  padding: 0 4px;
}
.ace-thumbnails > li > .tools > a:hover,
.ace-thumbnails > li > :first-child .inner a:hover {
  text-decoration: none;
  color: #C9E2EA;
}
.ace-thumbnails > li .tools.tools-bottom > a,
.ace-thumbnails > li .tools.tools-top > a {
  display: inline-block;
}
.ace-thumbnails > li > :first-child > .text {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  text-align: center;
  color: #FFF;
  background-color: rgba(0, 0, 0, 0.55);
  opacity: 0;
  filter: alpha(opacity=0);
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.ace-thumbnails > li > :first-child > .text:before {
  /* makes the inner text become vertically centered*/

  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
  margin-right: 0;
  /* Adjusts for spacing */

}
.ace-thumbnails > li > :first-child > .text > .inner {
  padding: 4px 0;
  margin: 0;
  display: inline-block;
  vertical-align: middle;
  max-width: 90%;
}
.ace-thumbnails > li:hover > :first-child > .text {
  opacity: 1;
  filter: alpha(opacity=100);
}
/* gallery */
@media only screen and (max-width: 480px) {
  .ace-thumbnails {
    text-align: center;
  }
  .ace-thumbnails > li {
    float: none;
    display: inline-block;
  }
}
.dialogs {
  padding: 9px 9px 0;
  position: relative;
}
.itemdiv {
  padding-right: 3px;
  min-height: 66px;
  position: relative;
}
.itemdiv > .user {
  display: inline-block;
  width: 42px;
  position: absolute;
  left: 0;
}
.itemdiv > .user > img {
  border-radius: 100%;
  border: 2px solid #5293C4;
  max-width: 40px;
  position: relative;
}
.itemdiv > .body {
  width: auto;
  margin-left: 50px;
  margin-right: 12px;
  position: relative;
}
.itemdiv > .body > .time {
  display: block;
  font-size: 11px;
  font-weight: bold;
  color: #666;
  position: absolute;
  right: 9px;
  top: 0;
}
.itemdiv > .body > .time [class*="icon-"] {
  font-size: 14px;
  font-weight: normal;
}
.itemdiv > .body > .name {
  display: block;
  color: #999;
}
.itemdiv > .body > .name > b {
  color: #777777;
}
.itemdiv > .body > .text {
  display: block;
  position: relative;
  margin-top: 2px;
  padding-bottom: 19px;
  padding-left: 7px;
  font-size: 13px;
}
.itemdiv > .body > .text:after {
  display: block;
  content: "";
  height: 1px;
  font-size: 0;
  overflow: hidden;
  position: absolute;
  left: 16px;
  right: -12px;
  margin-top: 9px;
  border-top: 1px solid #E4ECF3;
}
.itemdiv > .body > .text > [class*="icon-quote-"]:first-child {
  color: #DCE3ED;
  margin-right: 4px;
}
.itemdiv:last-child > .body > .text {
  border-bottom: none;
}
.itemdiv:last-child > .body > .text:after {
  display: none;
}
.itemdiv.dialogdiv {
  padding-bottom: 14px;
}
.itemdiv.dialogdiv:before {
  position: absolute;
  display: block;
  content: "";
  top: 0;
  bottom: 0;
  left: 19px;
  width: 3px;
  max-width: 3px;
  background-color: #E1E6ED;
  border: 1px solid #D7DBDD;
  border-width: 0 1px;
}
.itemdiv.dialogdiv:last-child:before {
  display: none;
}
.itemdiv.dialogdiv > .user > img {
  border-color: #C9D6E5;
}
.itemdiv.dialogdiv > .body {
  border: 1px solid #DDE4ED;
  padding: 5px 8px 8px;
  border-left-width: 2px;
  margin-right: 1px;
}
.itemdiv.dialogdiv > .body:before {
  content: "";
  display: block;
  position: absolute;
  left: -7px;
  top: 11px;
  width: 8px;
  height: 8px;
  border: 2px solid #DDE4ED;
  border-width: 2px 0 0 2px;
  background-color: #FFF;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.itemdiv.dialogdiv > .body > .time {
  position: static;
  float: right;
}
.itemdiv.dialogdiv > .body > .text {
  padding-left: 0;
  padding-bottom: 0px;
}
.itemdiv.dialogdiv > .body > .text:after {
  display: none;
}
.itemdiv.dialogdiv .tooltip-inner {
  word-break: break-all;
}
.itemdiv.memberdiv {
  width: 175px;
  padding: 2px;
  margin: 3px 0;
  float: left;
  border-bottom: 1px solid #E8E8E8;
}
.itemdiv.memberdiv > .user > img {
  border-color: #DCE3ED;
}
.itemdiv.memberdiv > .body > .time {
  position: static;
}
.itemdiv.memberdiv > .body > .name {
  line-height: 18px;
  height: 18px;
  margin-bottom: 0;
}
.itemdiv.memberdiv > .body > .name > a {
  display: inline-block;
  max-width: 100px;
  max-height: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}
.itemdiv .tools {
  position: absolute;
  right: 5px;
  bottom: 10px;
  display: none;
}
.itemdiv .tools .btn {
  border-radius: 36px;
  margin: 1px 0;
}
.itemdiv .body .tools {
  bottom: 4px;
}
.itemdiv.commentdiv .tools {
  right: 9px;
}
.itemdiv:hover .tools {
  display: inline-block;
}
/* task list */
.item-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.item-list > li {
  padding: 9px;
  background-color: #FFF;
  margin-top: -1px;
  position: relative;
}
.item-list > li.selected {
  color: #8090A0;
  background-color: #F4F9FC;
}
.item-list > li.selected label,
.item-list > li.selected .lbl {
  text-decoration: line-through;
  color: #8090A0;
}
.item-list > li > .checkbox {
  display: inline-block;
}
.item-list > li > label.inline {
  display: inline-block;
}
.item-list > li label {
  font-size: 13px;
}
.item-list > li .percentage {
  font-size: 11px;
  font-weight: bold;
  color: #777;
}
.item-list > li.ui-sortable-helper {
  cursor: move;
}
li[class*="item-"] {
  border: 1px solid #DDD;
  border-left-width: 3px;
}
li.item-orange {
  border-left-color: #e8b110;
}
li.item-orange2 {
  border-left-color: #f79263;
}
li.item-red {
  border-left-color: #d53f40;
}
li.item-red2 {
  border-left-color: #d15b47;
}
li.item-green {
  border-left-color: #9abc32;
}
li.item-green2 {
  border-left-color: #0490a6;
}
li.item-blue {
  border-left-color: #4f99c6;
}
li.item-blue2 {
  border-left-color: #3983c2;
}
li.item-blue3 {
  border-left-color: #1144eb;
}
li.item-pink {
  border-left-color: #cb6fd7;
}
li.item-purple {
  border-left-color: #6f3cc4;
}
li.item-black {
  border-left-color: #505050;
}
li.item-grey {
  border-left-color: #a0a0a0;
}
li.item-brown {
  border-left-color: #a52a2a;
}
li.item-default {
  border-left-color: #abbac3;
}
/* when dragging */
.ui-sortable-placeholder,
.ui-sortable-helper,
.ui-sortable-placeholder > a,
.ui-sortable-helper > a {
  cursor: move !important;
}
@media only screen and (max-width: 480px) {
  .itemdiv.memberdiv {
    float: none;
    width: auto;
  }
}
.profile-user-info {
  margin: 0 12px;
}
.profile-info-row {
  position: relative;
}
.profile-info-name {
  position: absolute;
  width: 110px;
  text-align: right;
  padding: 6px 10px 6px 0;
  left: 0;
  top: 0;
  bottom: 0;
  font-weight: normal;
  color: #667E99;
  background-color: transparent;
  border-top: 1px dotted #D5E4F1;
}
.profile-info-value {
  padding: 6px 4px 6px 6px;
  margin-left: 120px;
  border-top: 1px dotted #D5E4F1;
}
.profile-info-value > span + span:before {
  /* for a list of values (such as location city & country) put a comma between them */

  display: inline;
  content: ",";
  margin-left: 1px;
  margin-right: 3px;
  color: #666;
  border-bottom: 1px solid #FFF;
}
.profile-info-value > span + span.editable-container:before {
  display: none;
}
.profile-info-row:first-child .profile-info-name {
  border-top: none;
}
.profile-info-row:first-child .profile-info-value {
  border-top: none;
}
.profile-user-info-striped {
  border: 1px solid #DCEBF7;
}
.profile-user-info-striped .profile-info-name {
  color: #336199;
  background-color: #EDF3F4;
  border-top: 1px solid #F7FBFF;
}
.profile-user-info-striped .profile-info-value {
  border-top: 1px dotted #DCEBF7;
  padding-left: 12px;
}
.profile-picture {
  border: 1px solid #CCC;
  background-color: #FFF;
  padding: 4px;
  display: inline-block;
  max-width: 100%;
  -moz-box-sizing: border-box;
  box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
}
.profile-activity {
  padding: 10px 4px;
  border-bottom: 1px dotted #D0D8E0;
  position: relative;
  border-left: 1px dotted #FFF;
  border-right: 1px dotted #FFF;
}
.profile-activity:first-child {
  border-top: 1px dotted transparent;
}
.profile-activity:first-child:hover {
  border-top-color: #D0D8E0;
}
.profile-activity:hover {
  background-color: #F4F9FD;
  border-left: 1px dotted #D0D8E0;
  border-right: 1px dotted #D0D8E0;
}
.profile-activity img {
  border: 2px solid #C9D6E5;
  border-radius: 100%;
  max-width: 40px;
  margin-right: 10px;
  margin-left: 0px;
  box-shadow: none;
}
.profile-activity .thumbicon {
  background-color: #74ABD7;
  display: inline-block;
  border-radius: 100%;
  width: 38px;
  height: 38px;
  color: #FFF;
  font-size: 18px;
  text-align: center;
  line-height: 38px;
  margin-right: 10px;
  margin-left: 0px;
  text-shadow: none !important;
}
.profile-activity .time {
  display: block;
  margin-top: 4px;
  color: #777;
}
.profile-activity a.user {
  font-weight: bold;
  color: #9585BF;
}
.profile-activity .tools {
  position: absolute;
  right: 12px;
  bottom: 8px;
  display: none;
}
.profile-activity:hover .tools {
  display: block;
}
.user-profile .ace-thumbnails li {
  border: 1px solid #CCC;
  padding: 3px;
  margin: 6px;
}
.user-profile .ace-thumbnails li .tools {
  left: 3px;
  right: 3px;
}
.user-profile .ace-thumbnails li:hover .tools {
  bottom: 3px;
}
.user-profile .user-title-label:hover {
  text-decoration: none;
}
.user-profile .user-title-label + .dropdown-menu {
  margin-left: -12px;
}
.profile-contact-links {
  padding: 4px 2px 5px;
  border: 1px solid #E0E2E5;
  background-color: #F8FAFC;
}
.profile-contact-info .btn-link:hover > [class*="icon-"],
.profile-contact-info .btn-link:focus > [class*="icon-"] {
  text-decoration: none;
}
.profile-social-links > a {
  text-decoration: none;
  margin: 0 1px;
}
.profile-social-links > a:hover > [class*="icon-"] {
  text-decoration: none;
}
.profile-skills .progress {
  height: 26px;
  margin-bottom: 2px;
  background-color: transparent;
}
.profile-skills .progress .progress-bar {
  line-height: 26px;
  font-size: 13px;
  font-weight: bold;
  font-family: "Open Sans";
  padding: 0 8px;
}
.profile-users .user {
  display: block;
  position: static;
  text-align: center;
  width: auto;
}
.profile-users .user img {
  padding: 2px;
  border-radius: 100%;
  border: 1px solid #AAA;
  max-width: none;
  width: 64px;
  -webkit-transition: all 0.1s;
  transition: all 0.1s;
}
.profile-users .user img:hover {
  -webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,0.33);
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.33);
}
.profile-users .memberdiv {
  background-color: #FFF;
  width: 100px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border: none;
  text-align: center;
  margin: 0 8px 24px;
}
.profile-users .memberdiv .name a:hover [class*="icon-"] {
  text-decoration: none;
}
.profile-users .memberdiv .body {
  display: inline-block;
  margin: 8px 0 0 0;
}
.profile-users .memberdiv .popover {
  visibility: hidden;
  min-width: 150px;
  margin-left: 0;
  margin-right: 0;
  top: -5%;
  left: auto;
  right: auto;
  z-index: -1;
  opacity: 0;
  display: none;
  -webkit-transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
  transition: visibility 0s linear 0.2s, z-index 0s linear 0.2s, opacity 0.2s linear 0s;
}
.profile-users .memberdiv .popover.right {
  left: 100%;
  right: auto;
  display: block;
}
.profile-users .memberdiv .popover.left {
  left: auto;
  right: 100%;
  display: block;
}
.profile-users .memberdiv > :first-child:hover .popover {
  visibility: visible;
  opacity: 1;
  z-index: 1010;
  -webkit-transition-delay: 0s;
  -moz-transition-delay: 0s;
  -o-transition-delay: 0s;
  transition-delay: 0s;
}
.profile-users .memberdiv .tools {
  position: static;
  display: block;
  width: 100%;
  margin-top: 2px;
}
.profile-users .memberdiv .tools > a {
  margin: 0 2px;
}
.profile-users .memberdiv .tools > a:hover {
  text-decoration: none;
}
.user-status {
  display: inline-block;
  width: 11px;
  height: 11px;
  background-color: #FFF;
  border: 3px solid #AAA;
  border-radius: 100%;
  vertical-align: middle;
  margin-right: 1px;
}
.user-status.status-online {
  border-color: #8AC16C;
}
.user-status.status-busy {
  border-color: #E07F69;
}
.user-status.status-idle {
  border-color: #FFB752;
}
.tab-content.profile-edit-tab-content {
  border: 1px solid #DDD;
  padding: 8px 32px 32px;
  -webkit-box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
  box-shadow: 1px 1px 0 0 rgba(0, 0, 0, 0.2);
  background-color: #FFF;
}
/** profile **/
@media only screen and (max-width: 480px) {
  .profile-info-name {
    width: 80px;
  }
  .profile-info-value {
    margin-left: 90px;
  }
  .profile-user-info-striped .profile-info-name {
    position: static;
    width: auto;
    text-align: left;
    padding: 6px 0 6px 10px;
  }
  .profile-user-info-striped .profile-info-value {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .user-profile .memberdiv {
    width: 50%;
    margin-left: 0;
    margin-right: 0;
  }
}
.inbox-tabs.nav-tabs > li > a {
  background-color: #FAFAFA;
}
.inbox-tabs.nav-tabs > li.active:not(.open) > a,
.inbox-tabs.nav-tabs > li.active:not(.open) > a:hover,
.inbox-tabs.nav-tabs > li.active:not(.open) > a:focus {
  background-color: #F1F5FA;
  box-shadow: 0 -2px 3px 0 rgba(0, 0, 0, 0.1);
  color: #48768E;
}
.inbox-tabs.nav-tabs > li > a.btn-new-mail {
  background-color: transparent;
  border: none !important;
  padding: 0 !important;
}
.inbox-tabs.nav-tabs > li > a.btn-new-mail > .btn {
  border-width: 0 !important;
  border-radius: 3px !important;
  padding: 0 6px !important;
  position: relative;
  transition: none !important;
}
.inbox-tabs.nav-tabs > li.active > a.btn-new-mail {
  box-shadow: none !important;
}
.inbox-tabs.nav-tabs > li.active > a.btn-new-mail > .btn:before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 35%;
  left: calc(50% - 6px);
  border-width: 6px 8px;
  border-style: solid;
  border-color: transparent;
  border-top-color: inherit;
}
.inbox-tabs.nav-tabs.tab-size-bigger > li > a {
  padding: 5px 15px 7px;
  font-size: 14px;
}
.inbox-tabs.nav-tabs.tab-size-bigger > li > a > [class*="icon-"]:first-child {
  margin-bottom: 5px;
}
.inbox-tabs.nav-tabs.tab-size-bigger > li > a.btn-new-mail > .btn {
  padding: 10px !important;
  border-radius: 7px !important;
}
.inbox-tabs.nav-tabs.tab-size-bigger > li.active > a.btn-new-mail {
  margin-top: 0 !important;
  top: 1px;
}
.inbox-tabs.nav-tabs.tab-size-bigger > li.active > a.btn-new-mail > .btn:before {
  left: 35%;
  left: calc(50% - 8px);
  border-width: 8px 10px;
}
@media only screen and (max-width: 475px) {
  .inbox-tabs > .li-new-mail {
    display: block;
    text-align: right;
    margin-bottom: 8px !important;
    float: none !important;
  }
  .inbox-tabs > .li-new-mail > .btn-new-mail {
    display: inline-block;
    width: auto;
  }
}
.message-container {
  position: relative;
}
.message-list {
  position: relative;
}
.message-item {
  border: 1px solid #EAEDF1;
  border-bottom-width: 0;
  padding: 12px 12px 14px;
  line-height: 18px;
  position: relative;
  background-color: #FFF;
}
.message-item:first-child {
  border-top-width: 0;
}
.message-item:hover {
  border-color: #E2EAF2;
  background-color: #F2F6F9;
}
.message-item:hover + .message-item {
  border-top-color: #E2EAF2;
}
.message-item:hover + .message-item.selected {
  border-top-color: #FFF;
}
.message-item.selected {
  background-color: #EFF4F7;
  border-color: #FFF #E2EAF2;
}
.message-item.selected + .message-item {
  border-top-color: #FFF;
}
.message-item.selected + .message-item:hover + .message-item {
  border-top-color: #FFF;
}
.message-item .sender {
  margin: 0 6px 0 4px;
  vertical-align: middle;
  color: #467287;
  display: inline-block;
  width: 110px;
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.message-item.message-unread .sender {
  color: #6A9CBA;
  font-weight: bold;
}
.message-item .summary {
  vertical-align: middle;
  display: inline-block;
  position: relative;
  margin-left: 30px;
  max-width: 250px;
  max-width: calc(100% - 300px);
  min-width: 200px;
  white-space: nowrap;
}
.message-item .summary .text {
  color: #555;
  vertical-align: middle;
  display: inline-block;
  width: auto;
  max-width: 100%;
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}
.message-item .summary .text:hover {
  text-decoration: underline;
}
.message-item .summary .message-flags {
  display: block;
  position: absolute;
  right: 101%;
  right: calc(100% + 4px);
  height: 18px;
  white-space: nowrap;
}
.message-item.message-unread .summary .text {
  color: #609FC4;
  font-weight: bold;
}
.message-item .time {
  float: right;
  width: 60px;
  height: 18px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #666;
}
.message-item.message-unread .time {
  font-weight: bold;
  color: #609FC4;
}
.message-item .attachment {
  color: #999;
  font-size: 18px;
  vertical-align: middle;
  float: right;
  margin: 0 12px;
  position: relative;
}
.message-item.message-unread .attachment {
  color: #4F99C6;
}
.message-content .time {
  font-weight: normal;
}
.message-star {
  vertical-align: middle;
  margin: 2px 4px 0 6px;
  font-size: 15px;
  cursor: pointer;
}
.message-star:hover {
  color: #feb902 !important;
  text-decoration: none;
}
.mail-tag:empty {
  display: inline-block;
  width: 8px;
  height: 11px;
  padding: 0;
  line-height: normal;
  vertical-align: middle;
  margin: 0 1px 0 0;
}
.badge.mail-tag {
  border-radius: 2px;
}
@media only screen and (max-width: 979px) {
  .message-item .summary {
    min-width: 0;
  }
  .message-item .sender {
    width: 100px;
  }
}
@media only screen and (max-width: 550px) {
  .message-item .summary {
    margin: 8px 0 0 32px;
    max-width: 95%;
    min-width: 0;
    display: block;
  }
  .message-item .sender {
    width: auto;
    max-width: 150px;
  }
  .message-item .summary .text {
    max-width: 95%;
  }
}
.btn-message,
.btn-message:hover,
.btn-message:focus,
.btn-message:active,
.open .btn-message.dropdown-toggle {
  background-color: #FFF  !important;
  border: 1px solid #94B9CE !important;
  color: #7CA3BA !important;
  text-shadow: none !important;
}
.message-content {
  padding: 16px 12px;
  border: 1px solid #E9E9E9;
  -webkit-box-shadow: 0 0 1px 1px rgba(0,0,0,0.02);
  box-shadow: 0 0 1px 1px rgba(0,0,0,0.02);
  background-color: rgba(255, 255, 255, 0.8);
  border-top-width: 0;
}
.message-item .message-content {
  margin-top: 16px;
  border-top-width: 1px;
}
.message-body {
  padding: 0 9px;
  color: #6A7177;
}
.message-navbar {
  line-height: 24px;
  padding: 10px 12px;
  border: 1px solid #D6E1EA;
  border-color: #D6E1EA transparent;
  background-color: #F1F5FA;
  position: relative;
}
.message-navbar .dropdown-toggle,
.message-content .dropdown-toggle {
  color: #777;
}
.message-navbar .dropdown-toggle:hover,
.message-content .dropdown-toggle:hover,
.message-navbar .dropdown-toggle:focus,
.message-content .dropdown-toggle:focus {
  text-decoration: none;
  color: #2283C5;
}
.message-bar {
  display: inline-block;
  min-height: 28px;
}
@media only screen and (max-width: 480px) {
  .message-bar {
    display: block;
    min-height: 60px;
  }
}
.message-footer {
  background-color: #F1F1F1;
  padding: 12px 16px;
  border: 1px solid #E6E6E6;
  border-width: 1px 0;
  border-top: 1px solid #E4E9EE;
}
.message-footer .pagination {
  margin: 0;
}
.message-footer .pagination > li {
  margin: 0;
  padding: 0;
}
.message-footer .pagination > li > a,
.message-footer .pagination > li > span {
  color: #777;
  padding: 3px;
  margin-left: 3px;
  margin-right: 3px;
  background-color: transparent;
  border: none;
}
.message-footer .pagination > li.disabled > span {
  color: #BBBBBB;
  cursor: default;
}
.message-footer .pagination > li > a:hover {
  color: #2283C5;
  text-decoration: none;
}
.message-footer input[type=text] {
  font-size: 12px;
  width: 34px;
  height: 24px;
  line-height: 20px;
  margin-bottom: 0;
  padding: 3px;
  vertical-align: middle;
  text-align: center;
}
.message-footer-style2 .pagination > li > a,
.message-footer-style2 .pagination > li > span {
  border: 1px solid #B5B5B5;
  border-radius: 100% !important;
  width: 26px;
  height: 26px;
  line-height: 24px;
  display: inline-block;
  text-align: center;
  padding: 0;
}
.message-footer-style2 .pagination > li > span {
  border-color: #CCC;
}
.message-footer-style2 .pagination > li > a:hover {
  border-color: #84AFC9;
  background-color: #F7F7F7;
}
.message-item.message-inline-open {
  background-color: #F2F6F9;
  border: 1px solid #DDD;
  border-bottom-color: #CCC;
}
.message-item.message-inline-open:first-child {
  border-top-color: #EEE;
}
.message-item.message-inline-open:last-child {
  border-bottom-color: #DDD;
}
.message-item.message-inline-open + .message-item {
  border-bottom-color: transparent;
}
.message-loading-overlay {
  position: absolute;
  z-index: 14;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.5);
  text-align: center;
}
.message-loading-overlay > [class*="icon-"] {
  position: absolute;
  top: 15%;
  left: 0;
  right: 0;
  text-align: center;
}
.message-content .sender {
  color: #6A9CBA;
  font-weight: bold;
  width: auto;
  text-overflow: inherit;
  vertical-align: middle;
  margin: 0;
}
.message-content .time {
  width: auto;
  text-overflow: inherit;
  white-space: normal;
  float: none;
  vertical-align: middle;
}
ul.attachment-list {
  margin: 6px 0 4px 8px;
}
ul.attachment-list > li {
  margin-bottom: 3px;
}
.message-attachment {
  padding-left: 10px;
  padding-right: 10px;
}
.attached-file {
  color: #777;
  width: 200px;
}
.attached-file > [class*="icon-"] {
  display: inline-block;
  width: 16px;
  margin-right: 2px;
}
.attached-file:hover {
  text-decoration: none;
  color: #438EB9;
}
.attached-file:hover .attached-name {
  color: #2283C5;
}
.attached-file .attached-name {
  display: inline-block;
  max-width: 175px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.messagebar-item-left,
.messagebar-item-right {
  position: absolute;
  bottom: 14px;
  left: 12px;
  text-align: left;
}
.messagebar-item-right {
  right: 12px;
  left: auto;
}
.message-navbar .nav-search {
  right: auto;
  left: 60px;
  top: auto;
  bottom: 11px;
}
.message-form {
  border: 1px solid #ddd;
  border-top: none;
  padding-top: 22px;
}
@media only screen and (max-width: 480px) {
  .message-form {
    padding-left: 16px;
    padding-right: 16px;
  }
}
.message-form .form-actions {
  margin-bottom: 0;
}
.btn-send-message {
  position: relative;
  top: 6px;
}
.btn-back-message-list {
  color: #777;
}
.btn-back-message-list:hover {
  color: #478FCA;
  text-decoration: none;
}
.message-condensed .message-item {
  padding-top: 8px;
  padding-bottom: 9px;
}
.message-condensed .message-navbar,
.message-condensed .message-footer {
  padding-top: 7px;
  padding-bottom: 7px;
}
.message-condensed .messagebar-item-left,
.message-condensed .messagebar-item-right {
  bottom: 9px;
}
.message-condensed .message-navbar .nav-search {
  bottom: 7px;
}
@media only screen and (max-width: 480px) {
  .message-condensed .message-bar {
    min-height: 42px;
  }
}
.inbox-folders .btn-block {
  margin-top: 0;
}
@media only screen and (max-width: 767px) {
  .inbox-folders.inbox-folders-responsive .btn-block {
    width: 24%;
  }
}
@media only screen and (max-width: 600px) {
  .inbox-folders.inbox-folders-responsive .btn-block {
    width: 48%;
  }
}
@media only screen and (max-width: 320px) {
  .inbox-folders.inbox-folders-responsive .btn-block {
    width: 99%;
  }
}
.inbox-folders .btn-lighter,
.inbox-folders .btn-lighter.active {
  background-color: #F4F4F4 !important;
  text-shadow: none !important;
  color: #7C8395 !important;
  border: 1px solid #FFF  !important;
  padding: 5px 11px;
}
.inbox-folders .btn-lighter.active {
  background-color: #EDF2F8 !important;
  color: #53617C !important;
}
.inbox-folders .btn-lighter:hover {
  background-color: #EFEFEF !important;
  color: #6092C4 !important;
}
.inbox-folders .btn > [class*="icon-"]:first-child {
  display: inline-block;
  width: 14px;
  text-align: left;
}
.inbox-folders .btn-lighter + .btn-lighter {
  border-top-width: 0 !important;
}
.inbox-folders .btn.active:before {
  display: block;
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: -1px;
  border-left: 3px solid #4F99C6;
}
.inbox-folders .btn.active:after {
  display: none;
}
.inbox-folders .btn .counter {
  border-radius: 3px;
  position: absolute;
  right: 8px;
  top: 8px;
  padding-left: 6px;
  padding-right: 6px;
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.inbox-folders .btn:hover .badge {
  opacity: 1;
  filter: alpha(opacity=100);
}
.timeline-container {
  position: relative;
  padding-top: 4px;
  margin-bottom: 32px;
}
.timeline-container:last-child {
  margin-bottom: 0;
}
.timeline-container:before {
  /* the vertical line running through icons */

  content: "";
  display: block;
  position: absolute;
  left: 28px;
  top: 0;
  bottom: 0;
  border: 1px solid #E2E3E7;
  background-color: #E7EAEF;
  width: 4px;
  border-width: 0 1px;
}
.timeline-container:first-child:before {
  border-top-width: 1px;
}
.timeline-container:last-child:before {
  border-bottom-width: 1px;
}
.timeline-item {
  position: relative;
  margin-bottom: 8px;
}
.timeline-item .widget-box {
  background-color: #F2F6F9;
  color: #595C66;
}
.timeline-item .transparent.widget-box {
  border-left: 3px solid #DAE1E5;
}
.timeline-item .transparent .widget-header {
  background-color: #ECF1F4;
  border-bottom: none;
}
.timeline-item .transparent .widget-header > :first-child {
  margin-left: 8px;
}
.timeline-item:nth-child(even) .widget-box {
  background-color: #F3F3F3;
  color: #616161;
}
.timeline-item:nth-child(even) .widget-box.transparent {
  border-left-color: #DBDBDB !important;
}
.timeline-item:nth-child(even) .widget-box.transparent .widget-header {
  background-color: #EEE !important;
}
.timeline-item .widget-box {
  margin: 0;
  position: relative;
  max-width: none;
  border-bottom: none;
  margin-left: 60px;
}
.timeline-item .widget-main {
  margin: 0;
  position: relative;
  max-width: none;
  border-bottom: none;
}
.timeline-item .widget-body {
  background-color: transparent;
}
.timeline-item .widget-toolbox {
  padding: 4px 8px 0 !important;
  background-color: transparent !important;
  border: 0 solid #CCC !important;
  border-top: none !important;
  margin: 0 0px !important;
}
.timeline-info {
  float: left;
  width: 60px;
  text-align: center;
  position: relative;
}
.timeline-info img {
  border-radius: 100%;
  max-width: 42px;
}
.timeline-info .label,
.timeline-info .badge {
  font-size: 12px;
}
.timeline-container:not(.timeline-style2) .timeline-indicator {
  opacity: 1;
  border-radius: 100%;
  display: inline-block;
  font-size: 16px;
  height: 36px;
  line-height: 30px;
  width: 36px;
  text-align: center;
  text-shadow: none !important;
  padding: 0;
  cursor: default;
  border: 3px solid #FFF !important;
}
.timeline-label {
  display: block;
  clear: both;
  margin: 0 0 18px;
  margin-left: 34px;
}
.timeline-item img {
  border: 1px solid #AAA;
  padding: 2px;
  background-color: #FFF;
}
.timeline-style2:before {
  display: none;
}
.timeline-style2 .timeline-item {
  padding-bottom: 22px;
  margin-bottom: 0;
}
.timeline-style2 .timeline-item:last-child {
  padding-bottom: 0;
}
.timeline-style2 .timeline-item:before {
  content: "";
  display: block;
  position: absolute;
  left: 90px;
  top: 5px;
  bottom: -5px;
  border-width: 0;
  background-color: #DDD;
  width: 2px;
  max-width: 2px;
}
.timeline-style2 .timeline-item:last-child:before {
  display: none;
}
.timeline-style2 .timeline-item:first-child:before {
  display: block;
}
.timeline-style2 .timeline-item .transparent .widget-header {
  background-color: transparent !important;
}
.timeline-style2 .timeline-item .transparent.widget-box {
  background-color: transparent !important;
  border-left: none !important;
}
.timeline-style2 .timeline-info {
  width: 100px;
}
.timeline-style2 .timeline-indicator {
  font-size: 0;
  height: 12px;
  line-height: 12px;
  width: 12px;
  border-width: 1px !important;
  background-color: #FFFFFF !important;
  position: absolute;
  left: 85px;
  top: 3px;
  opacity: 1;
  border-radius: 100%;
  display: inline-block;
  padding: 0;
}
.timeline-style2 .timeline-date {
  display: inline-block;
  width: 72px;
  text-align: right;
  margin-right: 25px;
  color: #777;
}
.timeline-style2 .timeline-item .widget-box {
  margin-left: 112px;
}
.timeline-style2 .timeline-label {
  width: 75px;
  text-align: center;
  margin-left: 0;
  margin-bottom: 10px;
  text-align: right;
  color: #666;
  font-size: 14px;
}
.timeline-time {
  text-align: center;
  position: static;
}
/* full calendar */
.fc-header-title > h2 {
  font-size: 22px;
  color: #65A0CE;
}
.fc-widget-header,
.fc-widget-content {
  border: 1px solid #BCD4E5;
}
.fc-state-highlight {
  background: #FFC;
}
.fc-event-skin {
  border: none !important;
  /* default BORDER color */

  background-color: #ABBAC3;
  padding: 0 0 1px 2px;
}
.label-yellow .fc-event-skin {
  color: #996633;
}
.label-light .fc-event-skin {
  color: #888;
}
[class*="label-"] > .fc-event-skin,
[class*="label-"] > .fc-event-skin > .fc-event-skin.fc-event-head {
  background-color: inherit;
}
.fc-event-skin.ui-draggable-dragging {
  cursor: move;
}
.fc-event-skin.fc-event-vert,
.fc-event-vert > .fc-event-skin {
  padding: 0 0 1px;
}
.fc-grid .fc-day-number {
  color: #2E6589;
}
.fc-widget-header {
  background: #ECF2F7;
  color: #8090A0;
}
.fc-event-hori,
.fc-event-vert {
  border-radius: 0 !important;
  border-color: transparent;
}
.fc-event-vert .fc-event-content {
  padding-left: 1px;
  padding-right: 1px;
}
.fc-event-vert .fc-event-time {
  padding: 0;
}
.fc-state-default {
  border: none;
}
.fc-state-default,
.fc-state-default .fc-button-inner {
  border: none;
  background-color: #ABBAC3;
  color: #FFF;
  background-image: none;
  box-shadow: none;
  text-shadow: none;
  border-radius: 0 !important;
  margin-left: 2px;
}
.fc-state-default .fc-button-effect {
  display: none;
}
.fc-state-disabled,
.fc-state-disabled .fc-button-inner {
  opacity: 0.75;
  filter: alpha(opacity=75);
  color: #DDD;
}
.fc-state-active,
.fc-state-active .fc-button-inner {
  border-color: #4F99C6;
  background-color: #6FB3E0;
}
.fc-state-hover,
.fc-state-hover .fc-button-inner {
  background-color: #8B9AA3;
}
.external-event {
  margin: 6px 0;
  padding: 0;
  cursor: default;
  display: block;
  color: #FFF;
  background-color: #ABBAC3;
  font-size: 13px;
  line-height: 28px;
}
.external-event:hover {
  opacity: 1;
  filter: alpha(opacity=100);
}
.external-event.ui-draggable-dragging {
  cursor: move;
}
.external-event > [class*="icon-"]:first-child {
  /* the move & drag icon */

  display: inline-block;
  height: 32px;
  width: 32px;
  text-align: center;
  line-height: 30px;
  margin-right: 5px;
  font-size: 15px;
  border-right: 1px solid #FFF;
}
/* calendar inside widget-box --- not complete yet */
.widget-main .fc {
  position: relative;
  top: -40px;
}
.widget-main .fc > .fc-header {
  position: relative;
  z-index: 10;
}
.widget-main .fc .fc-header-space {
  padding-left: 2px;
}
.widget-main .fc-header-title > h2 {
  font-size: 18px;
  line-height: 36px;
}
.widget-main .fc-content {
  top: -14px;
  z-index: 11;
}
.widget-main .fc-button-content {
  height: 37px;
  line-height: 36px;
}
/* calendar */
@media only screen and (max-width: 480px) {
  .fc-header td {
    display: block;
    width: auto;
    text-align: left;
  }
}

/* Markdown Editor */
.widget-body .md-header {
  margin-top: -30px;
  margin-left: 9px;
}
.widget-body .md-header .btn {
  border-color: transparent;
  background: rgba(255, 255, 255, 0.25) !important;
  color: #FFF !important;
  text-align: center;
  padding-left: 0;
  padding-right: 0;
  border-width: 1px !important;
  padding-top: 2px;
  padding-bottom: 4px;
}
.widget-body .md-header .btn > [class*="icon-"] {
  font-size: 14px;
  width: 25px;
  max-width: 25px;
  display: inline-block;
}
.widget-body .md-header .btn-inverse {
  background: rgba(0, 0, 0, 0.25) !important;
  padding-right: 5px;
  margin-left: 4px;
}
.widget-body .md-preview {
  padding: 8px;
  min-height: 200px;
}
.widget-body .md-input {
  border: none !important;
  box-shadow: none !important;
  display: block;
  margin-bottom: 0;
  background-color: rgba(48, 126, 204, 0.07);
  padding: 8px;
  width: 100%;
}
.widget-body .md-input:focus {
  background-color: #FFF;
  box-shadow: none !important;
}
.editable-container .popover-title {
  color: #438EB9;
}
.editable-click {
  border-bottom: 1px dashed #BBB;
  cursor: pointer;
  font-weight: normal;
}
img.editable-click {
  border: 1px dotted #BBB;
}
.editable-click:hover {
  border-color: #0088CC;
  color: #0088CC;
}
img.editable-click:hover {
  opacity: 0.75;
  filter: alpha(opacity=75);
}
.editable-buttons,
.editable-input {
  display: inline-block;
}
.editable-buttons {
  margin-left: 1px;
}
.editable-buttons .btn {
  padding: 0;
  width: 28px;
  line-height: 24px;
  border-width: 3px;
  font-size: 12px;
  margin: 0 1px 0 0;
}
.editable-buttons .btn > [class*="icon-"] {
  margin: 0;
}
.editable-clear-x {
  cursor: pointer;
  color: #888;
  background: none;
}
.editable-clear-x:hover {
  color: #D15B47;
}
.editable-clear-x:before {
  display: inline-block;
  content: "\f057";
  font-family: FontAwesome;
  font-size: 15px;
  position: absolute;
  margin-top: -9px;
  width: 16px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.editable-input .ace-spinner {
  margin-right: 8px;
}
.editable-inline .editable-slider {
  margin-top: 10px;
  margin-right: 4px;
}
.editable-popup .editable-slider {
  display: block;
  margin-bottom: 16px;
  margin-top: 4px;
}
.editable-slider input {
  display: none;
}
.editable-input .ace-file-input {
  display: block;
}
.editable-image .ace-file-multiple label.selected {
  border-color: transparent;
}
.editable-image + .editable-buttons {
  display: block;
  text-align: center;
  margin-top: 8px;
}
.editableform .input-append.dropdown-menu {
  display: none;
}
.editableform .open .input-append.dropdown-menu {
  display: block;
}
.editable-container .editableform {
  margin-bottom: 10px;
}
.editable-inline .editableform {
  margin-bottom: 0;
}
.editableform-loading {
  background: none;
}
.editableform-loading [class*="icon-"],
.editableform-loading .progress {
  position: relative;
  top: 35%;
}

th[aria-selected=true] {
  background-image: -webkit-gradient(linear, left 0%, left 100%, from(#eff3f8), to(#e3e7ed));
  background-image: -webkit-linear-gradient(top, #eff3f8, 0%, #e3e7ed, 100%);
  background-image: -moz-linear-gradient(top, #eff3f8 0%, #e3e7ed 100%);
  background-image: linear-gradient(to bottom, #eff3f8 0%, #e3e7ed 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffeff3f8', endColorstr='#ffe3e7ed', GradientType=0);
}

.ui-icon {
  text-indent: 0;
  color: #307ecc;
  float: none;
  right: 2px;
  position: absolute;
}
.rtl .ui-icon {
  right: auto;
  left: 2px;
}
.ui-grid-ico-sort:before {
  display: inline;
  content: "\f0d7";
  font-family: FontAwesome;
  font-size: 12px;
}
.ui-icon-asc:before {
  content: "\f0d8";
}
.ui-state-disabled {
  color: #BBB;
}

.ui-icon-pencil {
  color: #478FCA;
}
.ui-icon-pencil:before {
  content: "\f040";
}
.ui-icon-trash {
  color: #DD5A43;
}
.ui-icon-trash:before {
  content: "\f014";
}
.ui-icon-disk {
  color: #69AA46;
}
.ui-icon-disk:before {
  content: "\f00c";
}
.ui-icon-cancel {
  color: #DD5A43;
}
.ui-icon-cancel:before {
  content: "\f00d";
}

.ui-state-error {
  background-color: #f2dede;
  border: 1px solid #eed3d7;
  color: #b94a48;
  margin: 4px 4px 8px;
  padding: 6px 10px;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  font-size: 13px;
}


.dd {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  max-width: 600px;
  list-style: none;
  line-height: 20px;
}
.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}
.dd-list .dd-list {
  padding-left: 30px;
}
.dd-collapsed .dd-list {
  display: none;
}
.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}
.dd-handle,
.dd2-content {
  display: block;
  min-height: 38px;
  margin: 5px 0;
  padding: 8px 12px;
  background: #F8FAFF;
  border: 1px solid #DAE2EA;
  color: #7C9EB2;
  text-decoration: none;
  font-weight: bold;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dd-handle:hover,
.dd2-content:hover {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd-handle[class*="btn-"],
.dd2-content[class*="btn-"] {
  color: #FFF;
  border: none;
  padding: 9px 12px;
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"]:hover {
  opacity: 0.85;
  color: #FFF;
}
.dd2-handle + .dd2-content,
.dd2-handle + .dd2-content[class*="btn-"] {
  padding-left: 44px;
}
.dd-handle[class*="btn-"]:hover,
.dd2-content[class*="btn-"] .dd2-handle[class*="btn-"]:hover + .dd2-content[class*="btn-"] {
  color: #FFF;
}
.dd-item > button:hover ~ .dd-handle,
.dd-item > button:hover ~ .dd2-content {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd-item > button:hover ~ .dd-handle[class*="btn-"],
.dd-item > button:hover ~ .dd2-content[class*="btn-"] {
  opacity: 0.85;
  color: #FFF;
}
.dd2-handle:hover ~ .dd2-content {
  color: #438EB9;
  background: #F4F6F7;
  border-color: #DCE2E8;
}
.dd2-handle:hover ~ .dd2-content[class*="btn-"] {
  opacity: 0.85;
  color: #FFF;
}
.dd2-item.dd-item > button {
  margin-left: 34px;
}
.dd-item > button {
  display: block;
  position: relative;
  z-index: 1;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 1px 5px 5px;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
  top: 4px;
  left: 1px;
  color: #707070;
}
.dd-item > button:before {
  font-family: FontAwesome;
  content: '\f067';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
  font-weight: normal;
  font-size: 14px;
}
.dd-item > button[data-action="collapse"]:before {
  content: '\f068';
}
.dd-item > button:hover {
  color: #707070;
}
.dd-item.dd-colored > button,
.dd-item.dd-colored > button:hover {
  color: #EEE;
}
.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #F0F9FF;
  border: 2px dashed #BED2DB;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.dd-empty {
  border-color: #AAA;
  border-style: solid;
  background-color: #e5e5e5;
}
.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 999;
  opacity: 0.8;
}
.dd-dragel > li > .dd-handle {
  color: #4B92BE;
  background: #F1F5FA;
  border-color: #D6E1EA;
  border-left: 2px solid #777;
  position: relative;
}
.dd-dragel > li > .dd-handle[class*="btn-"] {
  color: #FFF;
}
.dd-dragel > .dd-item > .dd-handle {
  margin-top: 0;
}
.dd-list > li[class*="item-"] {
  border-width: 0;
  padding: 0;
}
.dd-list > li[class*="item-"] > .dd-handle {
  border-left: 2px solid;
  border-left-color: inherit;
}
.dd-list > li > .dd-handle .sticker {
  position: absolute;
  right: 0;
  top: 0;
}
.dd2-handle,
.dd-dragel > li > .dd2-handle {
  left: 0;
  top: 0;
  width: 36px;
  margin: 0;
  border-width: 1px 1px 0 0;
  text-align: center;
  padding: 0 !important;
  line-height: 38px;
  height: 38px;
  background: #EBEDF2;
  border: 1px solid #DEE4EA;
  cursor: pointer;
  overflow: hidden;
  position: absolute;
  z-index: 1;
}
.dd2-handle:hover,
.dd-dragel > li > .dd2-handle {
  background: #E3E8ED;
}
.dd2-content[class*="btn-"] {
  text-shadow: none !important;
}
.dd2-handle[class*="btn-"] {
  text-shadow: none !important;
  background: rgba(0, 0, 0, 0.1) !important;
  border-right: 1px solid #EEE;
}
.dd2-handle[class*="btn-"]:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.dd-dragel .dd2-handle[class*="btn-"] {
  border-color: transparent;
  border-right-color: #EEE;
}
.dd2-handle.btn-yellow {
  text-shadow: none !important;
  background: rgba(0, 0, 0, 0.05) !important;
  border-right: 1px solid #FFF;
}
.dd2-handle.btn-yellow:hover {
  background: rgba(0, 0, 0, 0.08) !important;
}
.dd-dragel .dd2-handle.btn-yellow {
  border-color: transparent;
  border-right-color: #FFF;
}
.dd-item > .dd2-handle .drag-icon {
  display: none;
}
.dd-dragel > .dd-item > .dd2-handle .drag-icon {
  display: inline;
}
.dd-dragel > .dd-item > .dd2-handle .normal-icon {
  display: none;
}

/* custom animated icons */
.icon-animated-bell {
  display: inline-block;
  -moz-animation: ringing 2.0s 5 ease 1.0s;
  -webkit-animation: ringing 2.0s 5 ease 1.0s;
  -o-animation: ringing 2.0s 5 ease 1.0s;
  -ms-animation: ringing 2.0s 5 ease 1.0s;
  animation: ringing 2.0s 5 ease 1.0s;
  -moz-transform-origin: 50% 0%;
  -webkit-transform-origin: 50% 0%;
  -o-transform-origin: 50% 0%;
  -ms-transform-origin: 50% 0%;
  transform-origin: 50% 0%;
}
@-moz-keyframes ringing {
  0% {
    -moz-transform: rotate(-15deg);
  }
  2% {
    -moz-transform: rotate(15deg);
  }
  4% {
    -moz-transform: rotate(-18deg);
  }
  6% {
    -moz-transform: rotate(18deg);
  }
  8% {
    -moz-transform: rotate(-22deg);
  }
  10% {
    -moz-transform: rotate(22deg);
  }
  12% {
    -moz-transform: rotate(-18deg);
  }
  14% {
    -moz-transform: rotate(18deg);
  }
  16% {
    -moz-transform: rotate(-12deg);
  }
  18% {
    -moz-transform: rotate(12deg);
  }
  20% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes ringing {
  0% {
    -webkit-transform: rotate(-15deg);
  }
  2% {
    -webkit-transform: rotate(15deg);
  }
  4% {
    -webkit-transform: rotate(-18deg);
  }
  6% {
    -webkit-transform: rotate(18deg);
  }
  8% {
    -webkit-transform: rotate(-22deg);
  }
  10% {
    -webkit-transform: rotate(22deg);
  }
  12% {
    -webkit-transform: rotate(-18deg);
  }
  14% {
    -webkit-transform: rotate(18deg);
  }
  16% {
    -webkit-transform: rotate(-12deg);
  }
  18% {
    -webkit-transform: rotate(12deg);
  }
  20% {
    -webkit-transform: rotate(0deg);
  }
}
@-ms-keyframes ringing {
  0% {
    -ms-transform: rotate(-15deg);
  }
  2% {
    -ms-transform: rotate(15deg);
  }
  4% {
    -ms-transform: rotate(-18deg);
  }
  6% {
    -ms-transform: rotate(18deg);
  }
  8% {
    -ms-transform: rotate(-22deg);
  }
  10% {
    -ms-transform: rotate(22deg);
  }
  12% {
    -ms-transform: rotate(-18deg);
  }
  14% {
    -ms-transform: rotate(18deg);
  }
  16% {
    -ms-transform: rotate(-12deg);
  }
  18% {
    -ms-transform: rotate(12deg);
  }
  20% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes ringing {
  0% {
    transform: rotate(-15deg);
  }
  2% {
    transform: rotate(15deg);
  }
  4% {
    transform: rotate(-18deg);
  }
  6% {
    transform: rotate(18deg);
  }
  8% {
    transform: rotate(-22deg);
  }
  10% {
    transform: rotate(22deg);
  }
  12% {
    transform: rotate(-18deg);
  }
  14% {
    transform: rotate(18deg);
  }
  16% {
    transform: rotate(-12deg);
  }
  18% {
    transform: rotate(12deg);
  }
  20% {
    transform: rotate(0deg);
  }
}
.icon-animated-vertical {
  display: inline-block;
  -moz-animation: vertical 2.0s 5 ease 2.0s;
  -webkit-animation: vertical 2.0s 5 ease 2.0s;
  -o-animation: vertical 2.0s 5 ease 2.0s;
  -ms-animation: vertical 2.0s 5 ease 2.0s;
  animation: vertical 2.0s 5 ease 2.0s;
}
@-moz-keyframes vertical {
  0% {
    -moz-transform: translate(0, -3px);
  }
  4% {
    -moz-transform: translate(0, 3px);
  }
  8% {
    -moz-transform: translate(0, -3px);
  }
  12% {
    -moz-transform: translate(0, 3px);
  }
  16% {
    -moz-transform: translate(0, -3px);
  }
  20% {
    -moz-transform: translate(0, 3px);
  }
  22% {
    -moz-transform: translate(0, 0);
  }
}
@-webkit-keyframes vertical {
  0% {
    -webkit-transform: translate(0, -3px);
  }
  4% {
    -webkit-transform: translate(0, 3px);
  }
  8% {
    -webkit-transform: translate(0, -3px);
  }
  12% {
    -webkit-transform: translate(0, 3px);
  }
  16% {
    -webkit-transform: translate(0, -3px);
  }
  20% {
    -webkit-transform: translate(0, 3px);
  }
  22% {
    -webkit-transform: translate(0, 0);
  }
}
@-ms-keyframes vertical {
  0% {
    -ms-transform: translate(0, -3px);
  }
  4% {
    -ms-transform: translate(0, 3px);
  }
  8% {
    -ms-transform: translate(0, -3px);
  }
  12% {
    -ms-transform: translate(0, 3px);
  }
  16% {
    -ms-transform: translate(0, -3px);
  }
  20% {
    -ms-transform: translate(0, 3px);
  }
  22% {
    -ms-transform: translate(0, 0);
  }
}
@keyframes vertical {
  0% {
    transform: translate(0, -3px);
  }
  4% {
    transform: translate(0, 3px);
  }
  8% {
    transform: translate(0, -3px);
  }
  12% {
    transform: translate(0, 3px);
  }
  16% {
    transform: translate(0, -3px);
  }
  20% {
    transform: translate(0, 3px);
  }
  22% {
    transform: translate(0, 0);
  }
}
.icon-animated-hand-pointer {
  display: inline-block;
  -moz-animation: hand-pointer 2.0s 4 ease 2.0s;
  -webkit-animation: hand-pointer 2.0s 4 ease 2.0s;
  -o-animation: hand-pointer 2.0s 4 ease 2.0s;
  -ms-animation: hand-pointer 2.0s 4 ease 2.0s;
  animation: hand-pointer 2.0s 4 ease 2.0s;
}
@-moz-keyframes hand-pointer {
  0% {
    -moz-transform: translate(0, 0);
  }
  6% {
    -moz-transform: translate(5px, 0);
  }
  12% {
    -moz-transform: translate(0, 0);
  }
  18% {
    -moz-transform: translate(5px, 0);
  }
  24% {
    -moz-transform: translate(0, 0);
  }
  30% {
    -moz-transform: translate(5px, 0);
  }
  36% {
    -moz-transform: translate(0, 0);
  }
}
.icon-animated-wrench {
  display: inline-block;
  -moz-animation: wrenching 2.5s 4 ease;
  -webkit-animation: wrenching 2.5s 4 ease;
  -o-animation: wrenching 2.5s 4 ease;
  -ms-animation: wrenching 2.5s 4 ease;
  animation: wrenching 2.5s 4 ease;
  -moz-transform-origin: 90% 35%;
  -webkit-transform-origin: 90% 35%;
  -o-transform-origin: 90% 35%;
  -ms-transform-origin: 90% 35%;
  transform-origin: 90% 35%;
}
@-moz-keyframes wrenching {
  0% {
    -moz-transform: rotate(-12deg);
  }
  8% {
    -moz-transform: rotate(12deg);
  }
  10% {
    -moz-transform: rotate(24deg);
  }
  18% {
    -moz-transform: rotate(-24deg);
  }
  20% {
    -moz-transform: rotate(-24deg);
  }
  28% {
    -moz-transform: rotate(24deg);
  }
  30% {
    -moz-transform: rotate(24deg);
  }
  38% {
    -moz-transform: rotate(-24deg);
  }
  40% {
    -moz-transform: rotate(-24deg);
  }
  48% {
    -moz-transform: rotate(24deg);
  }
  50% {
    -moz-transform: rotate(24deg);
  }
  58% {
    -moz-transform: rotate(-24deg);
  }
  60% {
    -moz-transform: rotate(-24deg);
  }
  68% {
    -moz-transform: rotate(24deg);
  }
  75% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes wrenching {
  0% {
    -webkit-transform: rotate(-12deg);
  }
  8% {
    -webkit-transform: rotate(12deg);
  }
  10% {
    -webkit-transform: rotate(24deg);
  }
  18% {
    -webkit-transform: rotate(-24deg);
  }
  20% {
    -webkit-transform: rotate(-24deg);
  }
  28% {
    -webkit-transform: rotate(24deg);
  }
  30% {
    -webkit-transform: rotate(24deg);
  }
  38% {
    -webkit-transform: rotate(-24deg);
  }
  40% {
    -webkit-transform: rotate(-24deg);
  }
  48% {
    -webkit-transform: rotate(24deg);
  }
  50% {
    -webkit-transform: rotate(24deg);
  }
  58% {
    -webkit-transform: rotate(-24deg);
  }
  60% {
    -webkit-transform: rotate(-24deg);
  }
  68% {
    -webkit-transform: rotate(24deg);
  }
  75% {
    -webkit-transform: rotate(0deg);
  }
}
@-o-keyframes wrenching {
  0% {
    -o-transform: rotate(-12deg);
  }
  8% {
    -o-transform: rotate(12deg);
  }
  10% {
    -o-transform: rotate(24deg);
  }
  18% {
    -o-transform: rotate(-24deg);
  }
  20% {
    -o-transform: rotate(-24deg);
  }
  28% {
    -o-transform: rotate(24deg);
  }
  30% {
    -o-transform: rotate(24deg);
  }
  38% {
    -o-transform: rotate(-24deg);
  }
  40% {
    -o-transform: rotate(-24deg);
  }
  48% {
    -o-transform: rotate(24deg);
  }
  50% {
    -o-transform: rotate(24deg);
  }
  58% {
    -o-transform: rotate(-24deg);
  }
  60% {
    -o-transform: rotate(-24deg);
  }
  68% {
    -o-transform: rotate(24deg);
  }
  75% {
    -o-transform: rotate(0deg);
  }
}
@-ms-keyframes wrenching {
  0% {
    -ms-transform: rotate(-12deg);
  }
  8% {
    -ms-transform: rotate(12deg);
  }
  10% {
    -ms-transform: rotate(24deg);
  }
  18% {
    -ms-transform: rotate(-24deg);
  }
  20% {
    -ms-transform: rotate(-24deg);
  }
  28% {
    -ms-transform: rotate(24deg);
  }
  30% {
    -ms-transform: rotate(24deg);
  }
  38% {
    -ms-transform: rotate(-24deg);
  }
  40% {
    -ms-transform: rotate(-24deg);
  }
  48% {
    -ms-transform: rotate(24deg);
  }
  50% {
    -ms-transform: rotate(24deg);
  }
  58% {
    -ms-transform: rotate(-24deg);
  }
  60% {
    -ms-transform: rotate(-24deg);
  }
  68% {
    -ms-transform: rotate(24deg);
  }
  75% {
    -ms-transform: rotate(0deg);
  }
}
@keyframes wrenching {
  0% {
    transform: rotate(-12deg);
  }
  8% {
    transform: rotate(12deg);
  }
  10% {
    transform: rotate(24deg);
  }
  18% {
    transform: rotate(-24deg);
  }
  20% {
    transform: rotate(-24deg);
  }
  28% {
    transform: rotate(24deg);
  }
  30% {
    transform: rotate(24deg);
  }
  38% {
    transform: rotate(-24deg);
  }
  40% {
    transform: rotate(-24deg);
  }
  48% {
    transform: rotate(24deg);
  }
  50% {
    transform: rotate(24deg);
  }
  58% {
    transform: rotate(-24deg);
  }
  60% {
    transform: rotate(-24deg);
  }
  68% {
    transform: rotate(24deg);
  }
  75% {
    transform: rotate(0deg);
  }
}
/* other page sections */
.ace-settings-container {
  position: absolute;
  right: 0;
  top: 50px;
  z-index: 12;
}
.breadcrumbs-fixed .ace-settings-container {
  top: 9px;
}
.btn.ace-settings-btn {
  float: left;
  display: inline-block;
  width: 42px !important;
  text-align: center;
  border-radius: 6px 0 0 6px !important;
  opacity: 0.55;
  filter: alpha(opacity=55.00000000000001);
  vertical-align: top;
  margin: 0;
}
.btn.ace-settings-btn:hover,
.btn.ace-settings-btn.open {
  opacity: 1;
  filter: alpha(opacity=100);
}
.ace-settings-box {
  display: none;
  float: left;
  width: 175px;
  padding: 0 14px;
  background-color: #FFF;
  border: 2px solid #ffb34b;
}
.ace-settings-box.open {
  display: inline-block;
}
.ace-settings-box > div {
  margin: 6px 0;
  color: #444;
  max-height: 24px;
}
.ace-settings-box > div > label {
  font-size: 13px;
}
.btn-scroll-up {
  border: none;
  position: absolute;
  right: 2px;
  bottom: 2px;
  z-index: 11;
}
@media (min-width: 768px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 708px;
  }
}
@media (min-width: 992px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 928px;
  }
}
@media (min-width: 1200px) {
  .main-container.container > .btn-scroll-up {
    right: auto;
    margin-left: 1128px;
  }
}
.grid2,
.grid3,
.grid4 {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: block;
  margin: 0;
  float: left;
  border-left: 1px solid #E3E3E3;
}
.grid2:first-child,
.grid3:first-child,
.grid4:first-child {
  border-left: none;
}
.grid2 {
  width: 48%;
  padding: 0 2%;
}
.grid3 {
  width: 33%;
  padding: 0 2%;
}
.grid4 {
  width: 23%;
  margin: 0 1%;
  padding: 0 1%;
}
.draggable-placeholder {
  /* for when dragging items around */

  border: 2px dashed #D9D9D9 !important;
  background-color: #F7F7F7 !important;
}
/* date & time picker */
.datepicker td,
.daterangepicker td,
.datepicker th,
.daterangepicker th {
  border-radius: 0 !important;
  font-size: 13px;
}
.datepicker td.active,
.daterangepicker td.active,
.datepicker td.active:hover,
.daterangepicker td.active:hover {
  background: #2283c5 !important;
}
.datepicker td.active.disabled,
.daterangepicker td.active.disabled,
.datepicker td.active.disabled:hover,
.daterangepicker td.active.disabled:hover {
  background: #8b9aa3 !important;
}
.datepicker td,
.datepicker th {
  min-width: 32px;
}
.datepicker-months .month,
.datepicker-years .year {
  border-radius: 0 !important;
}
.datepicker-months .month.active,
.datepicker-years .year.active,
.datepicker-months .month.active:hover,
.datepicker-years .year.active:hover,
.datepicker-months .month.active:focus,
.datepicker-years .year.active:focus,
.datepicker-months .month.active:active,
.datepicker-years .year.active:active {
  background-image: none !important;
  background-color: #2283c5 !important;
}
.bootstrap-timepicker-widget table td input {
  width: 32px;
}
.bootstrap-timepicker-widget table td a:hover {
  border-radius: 0;
}
.well .datepicker table tr td.day:hover {
  background-color: #7d8893;
  color: #FFF;
}
/* ie8/9 specific */
.navbar .navbar-inner,
.navbar .btn-navbar {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.dropdown-menu li > a,
.dropdown-submenu > a {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.btn {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}
.progress,
.progress .bar {
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false) !important;
}

.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  float: left;
}
.col-xs-reset {
  width: auto;
  padding-left: 0;
  padding-right: 0;
  float: none;
}
@media (min-width: 768px) {
  .col-sm-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}
@media (min-width: 992px) {
  .col-md-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}
@media (min-width: 1200px) {
  .col-lg-reset {
    width: auto;
    padding-left: 0;
    padding-right: 0;
    float: none;
  }
}
.jqstooltip {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.legendLabel {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 20px;
  font-size: 10px;
}
@media only screen and (max-width: 480px) {
  .hidden-480 {
    display: none !important;
  }
}
@media only screen and (max-width: 320px) {
  .hidden-320 {
    display: none !important;
  }
}
@media only screen and (max-width: 480px) {
  [class*="vspace-xs"] {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  [class*="vspace-sm"] {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  [class*="vspace-md"] {
    display: block;
  }
}
@media only screen and (max-width: 1199px) {
  [class*="vspace-lg"] {
    display: block;
  }
}
@media only screen and (max-width: 991px) {
  .main-content {
    margin-left: 0 !important;
  }
}
@media only screen and (max-width: 767px) {
  .page-content {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media (max-width: 991px) {
  body.breadcrumbs-fixed {
    padding-top: 45px;
  }
  .navbar-fixed-top {
    margin-bottom: 0;
  }
  .sidebar.fixed,
  .sidebar.sidebar-fixed {
    position: absolute;
  }
}
@media (max-width: 460px) {
  body.navbar-fixed {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .error-container {
    margin: 12px;
  }
}
@media only screen and (max-width: 480px) {
  .error-container {
    margin: 6px;
  }
}
/* custom grid */
@media only screen and (max-width: 360px) {
  .grid2,
  .grid3,
  .grid4 {
    float: none;
    display: block;
    width: 96%;
    border-left: none;
    position: relative;
    margin-bottom: 11px;
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 4px;
  }
  .grid2 > [class*="pull-"],
  .grid3 > [class*="pull-"],
  .grid4 > [class*="pull-"] {
    float: none !important;
    display: inline-block;
    position: absolute;
    right: 11px;
    top: 0;
    margin-top: 0;
  }
  .grid2:last-child,
  .grid3:last-child,
  .grid4:last-child {
    border-bottom: none;
  }
}
@media only screen and (max-width: 480px) {
  .ace-settings-container,
  body.breadcrumbs-fixed .ace-settings-container {
    top: 65px;
  }
}
