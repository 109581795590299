$label-text-color: #CCC;
$border-color: #DDD;
$hover-color: #BBB;
$icon-color: #999;
$header-background-color: #F1F1F1;

$main-color: #0592BD;
$main-color-light: scale-lightness($main-color, 10%);
$main-color-extra-light: scale-lightness($main-color, 30%);
$main-color-extra-extra-light: scale-lightness($main-color, 45%);
$bg-color: #FFFFFF;
